
import en from "@/mixins/custom-locale-en";
import ja from "@/mixins/custom-locale-ja";
import { getLocale } from "./helper";

let locale = en;

let required = (property) => {
  locale = getLocale() === "en" ? en : ja;
  return (v) => !!v || `${property} ${locale.required_error_msg_txt}`;
};

let email = () => {
  locale = getLocale() === "en" ? en : ja;
  return (v) =>
    (v && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
    `${locale.email_validation_error_msg_txt}`;
};

let intent = () => {
  return (v) => (v && /^[A-Za-z_]+$/.test(v)) || "Must be a valid Intent Name";
};

let alphaSpace = () => {
  return (v) => (v && /^[A-Za-z ]+$/.test(v)) || "Must be a valid Intent Name";
};

let alphaSpaceHyphen = () => {
  return (v) => (v && /^[\-'a-zA-Z ]+$/.test(v)) || "Must be a valid Intent Name";
};

let minValue = (property, minValue) => {
  locale = getLocale() === "en" ? en : ja;
  return (v) =>
    (v && parseFloat(v) >= minValue) ||
    `${property} ${locale.min_val_error_msg_txt} ${minValue}`;
};

let maxValue = (property, maxValue) => {
  return (v) =>
    (v && parseFloat(v) <= maxValue) ||
    `${property} must be less than ${maxValue}`;
};

let minLength = (property, minLength) => {
  return (v) =>
    (v && v.length >= minLength) ||
    `${property} ${locale.min_val_error_msg_txt} ${minLength} ${locale.characters_validation_error_msg_txt}`;
};

let maxLength = (property, maxLength) => {
  return (v) =>
    (v && v.length <= maxLength) ||
    `${property} must be less than ${maxLength} ${locale.characters_validation_error_msg_txt}`;
};

let match = (property) => {
  locale = getLocale() === "en" ? en : ja;
  return (v) => 
    (v === property) ||
    locale.password_confirm_error_msg_txt;
};

let isNumeric = (property) => {
  locale = getLocale() === "en" ? en : ja;
  return (v) => 
    (v && isNaN(v)) ||
    `${property} ${locale.numeric_error_msg_txt}`;
};

let password = (property) => {
  locale = getLocale() === "en" ? en : ja;
  return (v) => v && passwordCriteria(v) || `${locale.password_valid_error_msg_txt_1} ${property} ${locale.password_valid_error_msg_txt_2}`;
};

let passwordWithEmail = (property, fullEmail) => {
  locale = getLocale() === "en" ? en : ja;
  return (v) => v && fullEmail && passwordEmailCheck(v, fullEmail) || `${property} ${locale.password_email_error_msg_txt}`;
};

let passwordWithName = (property, fullName, otherProperty) => {
  return (v) => v && fullName && passwordNameCheck(v, fullName) || `${property} ${locale.passwprd_error_msg_txt} ${otherProperty}`;
};
let lowercase = (property) => {
  return (v) => (v && /^[a-z]+$/.test(v)) || `${property} must be in lowercase.`;
};

// checking password length and password criteria
const passwordCriteria = pw => {
  let criteria = 0;
    if (pw.toUpperCase() != pw) {
        // has lower case letters
        criteria++;
    }
    if (pw.toLowerCase() != pw) {
        // has upper case letters
        criteria++;
    }
    if (/^[a-zA-Z0-9]*$/.test(pw) === false) {
        // has special characters
        criteria++;
    }
    if (/\d/.test(pw) === true) {
        // has numbers
        criteria++;
    }
    // returns true if 3 or more criteria was met and length is appropriate
    return (criteria >= 3 && pw.length >= 8);
}

// email username cheking with password -> is email username includes in user password
const passwordEmailCheck = (password, email) => {
  let emailUserName = email.split('@')[0];
  let emailOrganizationName = email.split('@')[1].split('.')[0];
  let fullEmail = emailUserName + emailOrganizationName;
  let checkEmail = fullEmail
    .match(/.{3}/g)
    .concat(fullEmail.substr(1).match(/.{3}/g), fullEmail.substr(2).match(/.{3}/g));
  let isCheckEmail = new RegExp(checkEmail.join("|"), "i").test(password);
  return !isCheckEmail;
};

// user Name and Display name is checking with password -> is user name and display name include in password?
const passwordNameCheck = (password, name) => {
  let newName = name;
  while(newName.length < 5){
  	newName = newName.repeat(2)
  }
  let checkName = newName
    .match(/.{3}/g)
    .concat(newName.substr(1).match(/.{3}/g), newName.substr(2).match(/.{3}/g));
  let isCheckName = new RegExp(checkName.join("|"), "i").test(password);
  return !isCheckName;
};

export default {
  required,
  email,
  minValue,
  maxValue,
  minLength,
  maxLength,
  intent,
  match,
  password,
  passwordWithEmail,
  passwordWithName,
  lowercase,
  isNumeric,
  alphaSpace,
  alphaSpaceHyphen,
};
