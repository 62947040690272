<template>
  <div>
    <v-container fill-height fluid>
      <!-- Project counts -->
      <v-row>
        <v-col class="pa-0" xs="12" md="12">
          <v-card class="mx-auto px-2" outlined>
            <v-row>
              <v-col xs="12" md="6">
                <div class="px-2 primary--text font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.dashboard.projects") }}
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <Loader v-if="isLoading" class="mr-2 mt-6" />
            <v-row v-else class="mt-2 mb-2 pl-2 pr-2">
              <v-col
                v-for="count in projectCounts"
                :key="count.segment"
                cols="12"
                xs="12"
                sm="6"
                md="6"  
                lg="4"
                xl="4"
              >
                <router-link
                  :to="'/solutions?type=' + count.segment"
                  class="text-decoration-none"
                >
                  <v-card rounded outlined class="mx-auto px-2">
                    <v-card-subtitle>
                      <span class="font-weight-bold">
                        {{ count.segment }}
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon small v-on="on" v-bind="attrs">
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <span>
                            {{ $vuetify.lang.t("$vuetify.dashboard.click_to_see_projects") }}
                          </span>
                        </v-tooltip>
                      </span>
                    </v-card-subtitle>
                    <v-card-text>
                      <span class="text-h5">
                        {{ $vuetify.lang.t("$vuetify.dashboard.active") }}: {{ count.active }}
                      </span>
                    </v-card-text>
                  </v-card>
                </router-link>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- Recommended articles -->
      <v-row class="mt-12">
        <v-col class="pa-0" xs="12" md="12">
          <v-card class="mx-auto px-2" outlined>
            <v-row>
              <v-col xs="12" md="6">
                <div class="px-2 primary--text font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.dashboard.recommended_articles") }}
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mt-2 mb-2 pl-2 pr-2">
              <v-col
                v-for="article in articles"
                :key="article.title"
                cols="12"
                xs="12"
                sm="6"
                md="6"
                lg="4"
                xl="4"
              >
                <v-card class="mx-auto rounded" outlined exact tile>
                  <v-img
                    class="white--text align-end"
                    :src="require(`../assets/images/${article.image}`)"
                    aspect-ratio="2"
                  ></v-img>
                  <v-card-subtitle class="pb-0">
                    {{ article.publishDate }}
                  </v-card-subtitle>
                  <v-card-title style="height: auto !important;">
                    {{ article.title }}
                  </v-card-title>
                  <v-card-text>
                    {{ article.summary }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn 
                      class="px-3 py-5 ml-auto mb-2 mr-2 outlined-default read-more-btn"
                      :href="article.link"
                      target="_blank"
                      outlined
                      color="primary"
                    >
                    {{ $vuetify.lang.t("$vuetify.common.read_more") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
  
<script>
import {
  mapActions,
  mapGetters,
  mapState
} from "vuex";

import {
  REFRESHTOKEN,
  LOAD_PROJECT_COUNTS,
} from "@/store/_actiontypes";

import validations from "@/helpers/validations";

import Loader from "@/components/common/loader/Loader";
import { timeToNextToken } from "@/helpers/helper";
import Sentry from "@/plugins/sentry";

export default {
  components: {
    Loader,
  },
  computed: {
    ...mapState({
      projectCounts: (state) => state.project.projectCounts,
      user: (state) => state.account.user,
      resourceMetadataByType: (state) => state.resource_metadata.resourceMetadataByType,
    }),
    ...mapGetters("loader", ["loading"]),
  },
  mounted() {
    this.getProjectCounts();
  },
  data() {
    return {
      timeToNextToken,
      isLoading: false,
      ...validations,
      articles: [
        {
          title: "Calling all AI enthusiasts!",
          publishDate: "February 20th, 2024",
          image: "article_personalizer.png",
          link: "https://medium.com/@gigalogy/calling-all-a-enthusiasts-ba52711fd814",
          summary: "Introducing Gigalogy Personalizer on Product Hunt: A groundbreaking AI-driven solution revolutionizing e-commerce. Enhance user engagement, boost revenue, and create personalized shopping experiences with real-time recommendations and immediate results.",
        },
        {
          title: "How Personalized Search Can Skyrocket Your E-Commerce Business?",
          publishDate: "Feb 28th, 2024",
          image: "skyrocket_business.png",
          link: "https://medium.com/@gigalogy/how-personalized-search-can-skyrocket-your-e-commerce-business-88a892a10605",
          summary: "Personalized search is crucial, as 76% of consumers depart from platforms lacking tailored experiences. Gigalogy Personalizer leverages AI to enhance e-commerce experiences, boosting product discovery, engagement, and revenue growth",
        },
        {
          title: "E-Commerce Personalization: The Evolution from Manual Curation to AI-Driven Automation",
          publishDate: "March 21st, 2024",
          image: "e-commerce_personalization.png",
          link: "https://medium.com/@gigalogy/e-commerce-personalization-the-evolution-from-manual-curation-to-ai-driven-automation-76af11f2d5ea",
          summary: "AI-driven personalization in e-commerce has evolved from basic data analysis to sophisticated, real-time solutions. Generative AI and LLMs enhance customer engagement through tailored recommendations and interactions, setting new standards in personalization.",
        },
      ],
    };
  },

  methods: {
    ...mapActions("project", [
      LOAD_PROJECT_COUNTS,
    ]),
    ...mapActions("account", [REFRESHTOKEN]),
    getProjectCounts() {
      this.isLoading = true;
      if (this.timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (_response) => {
            this.getProjectCountsFunction();
          },
          error => Sentry.captureException(error)
        );
      } else {
        this.getProjectCountsFunction();
      }
    },
    getProjectCountsFunction() {
      this.LOAD_PROJECT_COUNTS().then(
        (_response) => {
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          Sentry.captureException(error);
        }
      );
    },
  },
};
</script>

<style>
.read-more-btn {
  text-transform: none;
}
</style>
