import Api from "@/services/api";
import {
    GET_QUESTIONNAIRE_LOG,
} from "@/store/_actiontypes";
import {
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {};

const actions = {
    [GET_QUESTIONNAIRE_LOG]({ commit }, { project_id, params }) {
        updateLoaderTo(true);
       const params_string = new URLSearchParams(params).toString();
        return new Promise((resolve, reject) => {
            Api.get(`${project_id}/questionnaires/logs?${params_string}`).then(
                    (response) => {
                        updateLoaderTo(false);
                        resolve(response);
                    },
                    (error) => {
                        updateLoaderTo(false);
                        reject(error);
                    }
                );
        });
    },
};

const mutations = {};

export const questionnaire = {
    namespaced: true,
    state,
    actions,
    mutations,
};
