import Api from "@/services/api";
import {
    RETRIEVE_GPT_CONVERSATIONS,
} from '@/store/_actiontypes'
import {
    SET_GPT_CONVERSATIONS,
} from '@/store/_mutationtypes'
import {GptConversationMapper} from '@/helpers/dataMappers/gptConversations'
import { updateLoaderTo } from "./account";

const state = {
    gpt_conversations: {total: 0, conversations: []},
}

const actions = {
    [RETRIEVE_GPT_CONVERSATIONS]({ commit, state }, { project_id, start_date, end_date, start = 0, size = 100,  sameProject = false, query, userId, feedback, endpoint, sortName, sortType }) {
        updateLoaderTo(true);

        const queryBuilder = {}
        queryBuilder.searches = {}
        queryBuilder.filters = {request_body: {}}
        const sort = {}
        sort[sortName] = sortType
        if(query !== "" && query !== null) {
            queryBuilder.searches.query = query

        }
        if(endpoint != "" && endpoint !== null) {
            if (endpoint == "Ask") 
                queryBuilder.filters.request_url = `/v1/gpt/ask`
            if (endpoint == "Vision")
                queryBuilder.filters.request_url = `/v1/gpt/vision`
        }
        if(userId !== "" && userId !== null) {
                queryBuilder.filters.request_body.user_id = userId
        }
        if(feedback == "positive" || feedback == "negative")
            queryBuilder.filters.feedback = {
                type: feedback
            }
            
        
        return new Promise((resolve, reject) => {
            Api.post(`${project_id}/gpt/conversations`, {
                start_date, end_date, start, size, query: queryBuilder, sort
               
            })
                .then(response => {
                    const conversations = response.data.detail?.response?.conversations.map((conversation) => {
                        return GptConversationMapper.mapBackendToFrontend(conversation)
                    })
                    commit(SET_GPT_CONVERSATIONS, {total: response.data.detail?.response?.matched_conversation, conversations: conversations });
                    updateLoaderTo(false);
                    resolve(response);
                }, error => {
                    reject(error);
                    updateLoaderTo(false);
                })
        })
    },
}

const mutations = {
    [SET_GPT_CONVERSATIONS](state, data) {
        state.gpt_conversations = data;
    },
}

export const gpt = {
    namespaced: true,
    state,
    actions,
    mutations
};
