<template>
  <v-app :key="locale">
    <v-snackbar v-model="alert.show" :color="alert.color" top>
      <v-row class="py-0 my-0">
        <v-col cols="12" md="11" class="py-0 my-0">
          {{ alert.message }}
        </v-col>
        <v-col cols="12" md="1" class="py-0 my-0">
          <v-icon small dark @click="alert.show = false"> close </v-icon>
        </v-col>
      </v-row>
    </v-snackbar>

    <router-view :key="this.$route.path"> </router-view>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { getCurrentUser } from "@/helpers/helper";

export default {
  name: "app",
  components: {},
  mounted() {
    window.addEventListener('storage',(event) => this.checkUserLoggedOut(event));
  },
  destroyed() {
    window.removeEventListener('storage',(event) => this.checkUserLoggedOut(event));
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      alert: (state) => state.alert.alert,
      locale: (state) => state.alert.locale,
    }),
  },
  data() {
    return {};
  },
  methods: {
    checkUserLoggedOut(event) {
      if(event.key !== 'doozie_user') return;
      if(!getCurrentUser()){
        this.$router.push('/login');
      }
    },
  },
};
</script>
