import en from "vuetify/lib/locale/en";

export default {
  ...en,

  solutions_nav_txt: "Solutions",
  projects_nav_txt: "Projects",
  invoices_nav_txt: "Invoices",
  members_nav_txt: "Members",
  create_action_txt: "Create",
  profile_menu_txt: "Profile",
  signout_menu_txt: "Log Out",
  search_label_txt: "Search Solution",
  search_btn_txt: "Search",
  solutions_users_tab: "All Solutions",
  solutions_projects_tab: "Projects",
  solutions_developers_tab: "My Solutions",
  solutions_playground_tab: "Playground",
  profile_basic_tab: "Basic Information",
  profile_account_tab: "Account",
  profile_verify_tab: "ID Verification",
  id_upload_success_msg_txt: "ID Uploaded Successfully",
  profile_settings_tab: "Settings",
  projects_header_txt: "My Projects",
  id_header_txt: "ID",
  name_header_txt: "Name",
  content_lang_header_txt: "Content-Language",
  ratio_header_txt: "Ratio",
  solution_header_txt: "Solution Type",
  language_header_txt: "Language",
  status_header_txt: "Status",
  label_header_txt: "Security Levels",
  key_type_header_txt: "Key Type",
  industry_header_txt: "Industry",
  actions_header_txt: "Actions",
  filter_label_txt: "Filter By Status",
  no_result_msg_txt: "Sorry, No result found",
  no_data_available:  "No data available",
  invoices_header_txt: "My Invoices",
  daterange_placeholder_txt: "Select Date Range",
  invoice_created_state_txt: "Invoiced",
  pending_state_txt: "Pending",
  created_at_txt: "Created At",
  last_update_txt: "Last Update",
  role_label_txt: "Role",
  invoice_cleared_state_txt: "Cleared",
  overdue_state_txt: "Overdue",
  all_state_txt: "All",
  invoice_date_header_txt: "Invoice Date",
  due_date_header_txt: "Due Date",
  payment_date_header_txt: "Payment Date",
  amount_header_txt: "Amount",
  amount_due_header_txt: "Amount Due",
  login_msg_txt: "Log in to",
  email_label_txt: "E-mail",
  password_label_txt: "Password",
  current_password_label_txt: "Current Password",
  current_password_error_msg_txt: "Current Password is required",
  new_password_label_txt: "New Password",
  new_password_error_msg_txt: "New Password is required",
  new_password_error_msg_txt_2: "New password can not be same current password",
  re_password_label_txt: "Re-enter password",
  re_password_error_msg_txt: "Re-enter password is required",
  enter_password_label_txt: "Re-enter password",
  login_btn_txt: "Login",
  forgot_password_msg_txt: "Forgot your Password?",
  back_to_home_txt: "Back to Home",
  registration_msg_txt: "Don't have an account yet?",
  registration_btn_txt: "Create an Account",
  register_header_txt: "Start your AI journey with Gigalogy",
  account_name_label_txt: "Account Name",
  account_name__error_msg_txt: "Account Name is required",
  account_name_error_msg_txt_2: "Account Name Cannot contain only Numeric",
  privacy_policy_txt_1: "I have read and agreed to the",
  privacy_policy_txt_2: "Privacy Policy",
  privacy_policy_txt_3: "Terms & Conditions",
  continue_btn_txt: "Continue",
  prev_btn_txt: "Previous",
  cancel_btn_txt: "Cancel",
  close_btn_txt: "Close",
  save_btn_txt: "Save",
  ok_btn_txt: "Ok",
  OK_btn_txt: "OK",
  yes_btn_txt: "Yes",
  no_btn_txt: "No",
  send_btn_txt: "Send",
  upload_btn_txt: "Upload",
  pay_btn_txt: "Pay",
  pay_sent_btn_txt: "I have sent the payment",
  clear_btn_txt: "Clear",
  confirm_btn_txt: "Confirm",
  download_btn_txt: "Download",
  create_project_btn_txt: "Create Project",
  create_project_success_msg_txt: "Project registered successfully!",
  update_project_success_msg_txt: "Project updated successfully!",
  upgrade_subscription_success_msg_txt: "Project's Subscription upgraded successfully!",
  project_name_placeholder_text: "e.g., Similar Product Recommendation",
  project_key_label_txt: "Project Key",
  api_key_label_txt: "API Key",
  key_type_selector_txt: "Please Select your Key Type",
  whitelisted_domain_security_alert_txt: "Whitelisted domains are <strong>empty!</strong> For security purposes, please add your domains to the whitelists.",
  whitelisted_domain_validation_txt: 'Contains an invalid domain. Domains should be in the format: "https://gigalogy.com", "https://abc.gigalogy.com", "http://localhost"',
  credentials_risk_level_high_risk_description_txt: "Credentials are potentially exposed to the public. Immediate action required to secure them.",
  credentials_risk_level_medium_description_txt: "Credentials lack a whitelist. Please add the whitelist domain to enhance their security.",
  credentials_risk_level_no_risk_description_txt: "All credentials are currently safe. No action required.",
  whitelisted_domain_placeholder_txt: "Press Enter after entering a domain",
  project_api_key_tooltip_txt: "For server-to-server integration.",
  client_key_tooltip_txt: "For client-to-server integration.",
  create_trial_project_btn_txt: "Create a Trial Project",
  submit_btn_txt: "Submit",
  submit_review_btn_txt: "SUBMIT FOR REVIEW",
  draft_btn_txt: "DRAFT",
  account_exist_msg_txt: "Already have an account?",
  registration_success_msg_txt_1: "Registration Successful!",
  registration_success_msg_txt_2: "We sent you an email containing the remaining steps to set up your account. Please check your inbox.",
  forgot_password_msg_txt_1: "Forgot Your Password?",
  forgot_password_msg_txt_2: "Enter your email address below and we'll send you a link to reset your password.",
  reset_btn_txt: "Send reset link",
  login_link_txt: "Back to Login",
  verify_msg_txt: "Verifying...",
  resend_verification_email_btn_txt: "Resend Verification Email",
  account_already_verified: "Account is already verified.",
  resend_verification_email_msg_txt_1: "If you don't receive the email, please check your spam/junk folder first. If the email is not there, click on the following button to resend the email.",
  resend_verification_email_msg_txt_2: "Please check your Inbox to verify your email to get full control of your account.",
  reset_password_msg_txt_1: "Reset your password",
  reset_password_msg_txt_2: "To protect your account make sure your password is longer than 8 characters, doesn't contain part of your email or name, and must contain at least three of the four available character types: lowercase letters, uppercase letters, numbers and symbols.",
  change_password_btn_txt: "Change Password",
  details_step_txt: "Details",
  insights_step_txt: "Insights",
  setup_step_txt: "Setup",
  device_ratio_txt: "Device Ratio",
  browser_ratio_txt: "Browser Ratio",
  os_ratio_txt: "OS Ratio",
  show_device_ratio_txt: "Show the ratio of each Platform or Device of the end users.",
  show_browser_ratio_txt: "Show the ratio of each Browser of the end users.",
  show_os_ratio_txt: "Show the ratio of each Operating system of the end users.",
  request_path_header_txt: "Request Path",
  api_call_response_summary: "API Call and Response Summary",
  gpt_history_add_document_success: "Document added successfully",
  gpt_history_add_document_failed: "Failed to add document",
  gpt_history_filter_endpoints: "Filter Endpoints",
  gpt_history_filter_feedback: "Filter Feedback",
  gpt_history_search_query: "Search Query",
  gpt_history_search_user_id: "Search User ID",
  gpt_flow_history_title: "GPT-Flow History",
  gpt_conversation_history_title:"Conversation History",
  gpt_history_date: "Date",
  gpt_history_query: "Query",
  gpt_history_response: "Response",
  gpt_history_user_id: "User ID",
  gpt_history_conversation_id: "Conversation ID",
  gpt_history_feedback: "Feedback",
  gpt_history_feedback_message: "Feedback Message",
  gpt_history_consumed_tokens: "Tokens",
  gpt_history_processing_time: "Processing Time",
  gpt_history_model: "Model",
  gpt_history_endpoint: "Endpoint",
  gpt_history_temperature: "Temperature",
  gpt_history_frequency_penalty: "Frequency Penalty",
  gpt_history_presence_penalty: "Presence Penalty",
  gpt_history_search_max_token: "Search Max Token",
  gpt_history_result_includes: "Result Includes",
  gpt_history_profile_name: "Profile Name",
  gpt_history_system: "System",
  gpt_history_conversation_type: "Conversation Type",
  gpt_history_context_preference: "Context Preference",
  gpt_history_conversation_metadata: "Conversation Metadata",
  gpt_history_conversation_metadata_invalid: "Invalid JSON format for conversation metadata",
  gpt_history_completion_tokens: "Completion Tokens",
  gpt_history_prompt_tokens: "Prompt Tokens",
  gpt_history_query_tokens: "Query Tokens",
  gpt_history_total_tokens: "Total Tokens",
  gpt_history_vision_settings: "Vision Settings",
  gpt_history_image_context_enabled: "Image Context Enabled",
  gpt_history_resolution: "Resolution",
  gpt_history_references: "References",
  add_to_training_data: "Training Data",
  add_new_dataset: "Add New Dataset",
  add_gpt_response_to_training_data : "Add GPT Response to Training Data",
  fill_up_index_if_empty: "Fill Up Index if Empty",
  secondary_index_column: "Secondary Index Column",
  image_url_column: "Image URL Column",
  dataset_file: "Dataset File",
  select_dataset: "Select Dataset",
  dataset_name: "Dataset Name",
  index_column_name: "Index Column Name",
  training_in_progress: "Training in Progress",
  dataset_submission_success: "Dataset Creation request submitted successfully",
  dataset_update_success: "Dataset updating request submitted successfully",
  create_dataset: "Create Dataset",
  number_of_request_header_txt: "Number of Requests",
  avg_processing_time_header_txt: "Average Processing Time",
  pricing_step_txt: "Pricing",
  metadata_step_txt: "Metadata",
  review_step_txt: "Review",
  solution_name_label_txt: "Solution Name",
  solution_overview_label_txt: "Solution Overview",
  overview_label_txt: "Overview",
  solution_description_label_txt: "Solution Description (Optional)",
  solution_description_placeholder_txt: "Solution Description",
  industry_label_txt: "Industries",
  use_case_label_txt: "Use Cases",
  use_case_placeholder_txt: "Use Case",
  linked_solution_label_txt_1: "Solutions which can be used together (Optional)",
  linked_solution_label_txt_2: "Solutions Used Together",
  linked_solution_label_txt_3: "Other solutions which can be used together",
  language_label_txt: "Supported Languages",
  resource_header_txt: "Resource",
  cpu_label_txt: "Minimum CPU Core Count",
  memory_label_txt: "Minimum Memory in GB",
  gpu_label_txt: "Minimum GPU in GB",
  performance_header_txt: "Performance",
  benchmark_request_label_txt: "Benchmark Request/Month",
  benchmark_rps_label_txt: "Benchmark RPS",
  million_requests_month_txt: "Million Requests/month",
  price_header_txt: "Price",
  base_price_label_txt_1: "Solution Base Price (USD)",
  base_price_label_txt_2: "Base Price",
  base_infra_price_label_txt: "Base Infrastructure Price",
  additional_price_label_txt: "Additional Solution Price (USD)",
  version_label_txt: "Version Number",
  source_code_label_txt: "Source Code URL",
  sandbox_label_txt: "Sandbox URL",
  api_docs_label_txt: "API Docs URL",
  tutorial_label_txt: "Tutorial URL",
  tutorial_btn_txt: "Tutorial",
  documentation_btn_txt: "Documentation",
  sandbox_btn_txt: "Sandbox",
  select_img_label_txt: "Select Image",
  cover_img_label_txt: "Cover Image",
  create_industry_header_txt: "Create New Industry",
  industry_code_label_txt: "Industry Code",
  industry_name_label_txt: "Industry Name",
  description_label_txt: "Description",
  error_title_txt: "Error",
  warning_title_txt: "Warning",
  details_header_txt: "Details",
  content_lang_error_msg_txt: "The Content Language field is required",
  solution_name_error_msg_txt: "The Solution Name field is required",
  solution_name_error_msg_txt_2: "Solution Name Cannot contain only Numeric",
  solution_type_error_msg_txt: "The Solution Type field is required",
  solution_overview_error_msg_txt: "The Solution Overview field is required",
  solution_usecase_error_msg_txt: "The Use Cases field is required",
  solution_industry_error_msg_txt: "The Industries field is required",
  solution_language_error_msg_txt: "The Supported languages field is required",
  solution_cpu_error_msg_txt: "The Minimum CPU field is required",
  solution_memory_error_msg_txt: "The Min Memory field is required",
  solution_request_error_msg_txt: "The Benchmark request/month field is required",
  solution_base_price_error_msg_txt: "The Solution Base Price field is required",
  solution_additional_price_error_msg_txt: "The Additional Solution Price field is required",
  solution_version_error_msg_txt: "The Version Number field is required",
  solution_version_error_msg_txt_2: "Version Number is not valid.",
  solution_sourcecode_url_error_msg_txt: "The Source Code URL field is required",
  solution_sourcecode_url_error_msg_txt_2: "Please provide a valid URL like https://example.com",
  solution_update_msg_txt: "Solution updated successfully!",
  solution_register_msg_txt: "Solution registered successfully!",
  solution_submit_msg_txt: "Solution submitted successfully!",
  solution_draft_msg_txt: "Solution drafted successfully!",
  first_name_label_txt: "First Name",
  first_name_error_msg_txt: "The First Name field is required",
  first_name_error_msg_txt_2: "First Name Cannot contain only Numeric",
  middle_name_label_txt: "Middle Name",
  middle_name_error_msg_txt_2: "Middle Name Cannot contain only Numeric",
  last_name_label_txt: "Last Name",
  last_name_error_msg_txt: "The Last Name field is required",
  last_name_error_msg_txt_2: "Last Name Cannot contain only Numeric",
  full_name_label_txt: "Full Name",
  dob_label_txt: "Date Of Birth",
  dob_error_msg_txt: "Provide a valid Date Of Birth",
  phone_no_label_txt: "Phone Number",
  phone_no_error_msg_txt: "The Phone Number field is required",
  phone_no_error_msg_txt_2: "Provide a valid Phone Number",
  gender_label_txt: "Gender",
  male_label_txt: "Male",
  female_label_txt: "Female",
  other_label_txt: "Other",
  country_label_txt: "Country",
  country_error_msg_txt: "The Country field is required",
  state_label_txt: "State or Province",
  state_error_msg_txt: "The State field is required",
  city_label_txt: "City",
  city_error_msg_txt: "The City field is required",
  city_error_msg_txt_2: "City Cannot contain only Numeric",
  post_code_label_txt: "Postal Code",
  post_code_error_msg_txt: "The Postal Code field is required",
  post_code_error_msg_txt_2: "Provide a valid Postal Code",
  address_label_txt: "Address",
  company_address_line: "23F, 4-1-1 Toranomon",
  company_address_city: "Minato",
  company_address_state_country: "Tokyo 105-6923, Japan",
  gigalogy_ai_platform_txt: "Gigalogy AI Platform",
  address_error_msg_txt: "The Address field is required",
  address_error_msg_txt_2: "Address Cannot contain only Numeric",
  org_name_label_txt: "Organization Name",
  org_name_error_msg_txt: "The Organization Name field is required",
  org_name_error_msg_txt_2: "Organization Name Cannot contain only Numeric",
  vat_no_label_txt: "Organization VAT Number",
  reg_no_label_txt: "Organization Registration Number",
  ceo_label_txt: "Registered Managing Director/CEO",
  ceo_error_msg_txt: "The Registered Managing Director/CEO field is required",
  ceo_error_msg_txt_2: "Registered Managing Director/CEO Cannot contain only Numeric",
  org_email_label_txt: "Organization E-mail",
  org_email_error_msg_txt: "The Organization Email field must be a valid email",
  org_phone_label_txt: "Organization Phone",
  org_phone_error_msg_txt_2: "Provide a valid Organization Phone",
  org_website_label_txt: "Organization Website",
  org_website_error_msg_txt: "The Organization Website field is required",
  org_website_error_msg_txt_2: "Please provide a valid URL like https://example.com",
  org_size_label_txt: "Organization Size",
  org_size_error_msg_txt: "The Organization Size field is required",
  org_size_opt_1_txt: "fewer than 10 employees",
  org_size_opt_2_txt: "10 to 49 employees",
  org_size_opt_3_txt: "50 to 149 employees",
  org_size_opt_4_txt: "150 to 249 employees",
  org_size_opt_5_txt: "250 to 999 employees",
  org_size_opt_6_txt: "1000 or more people",
  org_industry_label_txt: "Organization Industry",
  id_verify_header_txt: "Please upload your government-issued ID (Passport / Driver's License / Residence Card etc).",
  upload_img_1_label_txt: "Upload front side",
  upload_img_2_label_txt: "Upload back side",
  upload_img_1_error_msg_txt: "The Front Side field is required",
  upload_img_2_error_msg_txt: "The Back Side field is required",
  id_verify_msg_txt_1: "Identity verification is required to get your project approved. Please",
  id_verify_msg_txt_2: "Verify",
  id_verify_msg_txt_3: "soon",
  verify_alert_msg_txt: "You need to verify your account to make a payment.",
  review_alert_msg_txt: "We are currently reviewing your identity and notify you as soon as the verification is completed. Thank you for your patience.",
  total_amount_step_txt: "Total Amount",
  payment_method_step_txt: "Payment Methods",
  no_data_msg_txt: "No data available",
  select_solutiontype_msg_txt: "Please select the solution type first",
  confirm_alert_msg_txt: "Are you sure?",
  confirm_alert_msg_txt_2: "Do you want to submit this solution for review?",
  confirm_alert_msg_txt_3: "Are you sure to stop this Project?",
  confirm_alert_msg_txt_4: "Are you sure to delete this Project?",
  confirm_alert_msg_txt_5: "Are you sure to start this Project?",
  confirm_alert_msg_txt_6: "Are you sure to delete this Profile?",
  confirm_alert_title_txt: "Confirm!",
  subtotal_label_txt: "Subtotal",
  total_label_txt: "Total",
  total_due_label_txt: "Total Due",
  select_method_label_txt: "Select Method",
  bank_transfer_label_txt: "Bank Transfer",
  doozie_wallet_label_txt: "Doozie Wallet",
  bank_account_header_txt: "Please transfer the invoice amount to any of the following bank accounts and press continue after a successful transaction!",
  bank_account_label_txt: "Select an account",
  payment_ref_header_txt: "Please use the following reference during payment",
  reference_label_txt: "Reference",
  bank_name_label_txt: "Bank Name",
  bank_code_label_txt: "Bank Code",
  branch_name_label_txt: "Branch Name",
  branch_code_label_txt: "Branch Code",
  account_type_label_txt: "Account Type",
  account_type_value_individual: "Individual",
  account_type_value_organization: "Organization",
  account_no_label_txt: "Account Number",
  total_amount_label_txt: "Total Amount",
  pay_method_label_txt: "Payment Method",
  copy_text_alert_msg_txt: "Copied the text: ",
  invoice_header_txt: "Invoice",
  bill_to_label_txt: "Bill To",
  invoice_id_label_txt: "Invoice ID",
  next_invoice_issue_label_txt: "Next Invoice Issue Date",
  next_invoice_due_label_txt: "Next Invoice Due Date",
  paid_by_label_txt: "Paid By",
  project_name_header_txt: "Project Name",
  bill_from_date_header_txt: "Bill From Date",
  bill_to_date_header_txt: "Bill To Date",
  infra_fee_header_txt: "Infrastructure Fee",
  subcrip_fee_header_txt: "Subscription Fee",
  unknown_err_msg_txt: "Unknown error occurred!",
  sys_name_label_txt: "System Name",
  settings_header_txt: "General Settings",
  currency_label_txt: "Display Currency",
  theme_label_txt: "Dark Theme",
  page_not_found_msg_txt_1: "Not Found",
  page_not_found_msg_txt_2: "Requested URL not exist",
  project_app_infra_step_txt: "Application & Infrastructure",
  project_pricing: "Project Pricing",
  required_error_msg_txt: "is required",
  password_confirm_error_msg_txt: "The password confirmation does not match.",
  password_valid_error_msg_txt_1: "Your",
  password_valid_error_msg_txt_2: "must be or longer than 8 characters and contain at least one uppercase, one lowercase, one special character or one digit",
  password_email_error_msg_txt: "can not contain email",
  choose_one_placeholder_txt: "Choose One",
  passwprd_error_msg_txt: "can not contain",
  numeric_error_msg_txt: "can not only numeric",
  enable_solution_label_txt: "Enable Solution",
  enable_solution_error_msg_txt: "Enabled Solution is required",
  request_count_label_txt: "Expected request count/month",
  request_count_error_msg_txt: "must be less than 2,147,483,647",
  min_val_error_msg_txt: "must be greater than",
  request_per_sec_label_txt: "Expected Request per Second (RPS)",
  unique_user_label_txt: "Expected unique visitor or user count/month",
  item_count_label_txt: "Expected item count",
  item_limit_label_txt: "Item Limit (Maximum Capacity of Item Catalog)",
  cloud_provider_label_txt: "Cloud Provider",
  coming_soon_content_text: "coming soon",
  region_label_txt: "Region",
  expect_region_label_txt: "Expected Region",
  auto_scaling_label_txt: "Auto Scaling",
  enabled_solution_label_txt: "Enabled Solution",
  monthly_request_label_txt: "Expected Monthly Requests",
  expected_request_label_txt: "Expected Requests/month",
  est_infra_cost_label_txt: "Estimated Infrastructure Cost/month",
  subs_cost_label_txt: "Subscription Cost/month",
  est_total_cost_label_txt: "Estimated Total Cost/month",
  req_field_error_msg_txt: "Required Field Missing!",
  billing_cycle_label_txt: "Current Billing Cycle",
  next_billing_date_label_txt: "Next Billing Date",
  subs_api_rate_limit: "API Rate Limit",
  subs_label_txt: "Subscription",
  subs_type_label_txt: "Subscription Status",
  subs_start_label_txt: "Subscription Started",
  project_status_label_txt: "Project Status",
  subs_upgrade_btn_txt: "Request to Unlock Full Features",
  subs_trial_description: "Currently on Free Trial, granting full access to all features along with allocated API call limits for : ",
  subs_paid_description: "Full Features Unlocked based on the plan selected. Allowed API calls : ",
  subs_upgrade_modal_title: "Request to Unlock Full Features!",
  subs_upgrade_modal_message: "Trial Period of this project will be over and these changes will be applied : ",
  subs_upgrade_modal_message_continued: "Please be aware that for the initial month, billing will be prorated based on the period from the activation date to the month's end.",
  subs_upgrade_modal_message_last: "We will review your request and approve the project in a few days.",
  subs_upgrade_modal_confirm_btn_text: "Send Request",
  subs_upgrade_modal_cancel_btn_text: "Cancel",
  upgrade_project_request_success_msg_txt: "Your request for upgrading project to Standard plan has been sent successfully..",
  trial_subs_expire_date_label: "Trial Period Ends ",
  customer_support_message: "To increase API Rate Limits, contact us at support@gigalogy.com.",
  month_txt: "month",
  calls_txt: "calls",
  seconds_txt: "seconds",
  ai_assistant_txt: "AI Assistant",
  search_txt: "Search",
  recommendation_txt: "Recommendation",
  credentials_label_txt: "Credentials",
  all_credentials_label_txt: "All Credentials",
  edit_credential_label_txt: "Edit Credential",
  add_credential_label_txt: "Add Credential",
  credentials_error_msg_txt: "Credential name is required",
  download_msg_txt: "Please download credentials before closing the modal. Once you close the modal you no longer can download the credentials.",
  create_restricted_msg_txt: "We are currently reviewing your project. You can not create credentials until your project is approved. Thank you for your patience.",
  project_locked_msg_txt:  "We have locked this project due to overdue of your last month's invoice. Please pay your bill to unlock your project. Feel free to contact with the support for any help.",
  download_json_msg_txt: "Download JSON",
  copy_msg_txt: "Copy",
  copied_msg_txt: "Copied",
  category_label_txt: "Category",
  get_started_btn_txt: "GET STARTED",
  email_validation_error_msg_txt: "Must be a valid email",
  numeric_validation_error_msg_txt: "Cannot contain only Numeric",
  characters_validation_error_msg_txt: "characters",
  validation_error_msg_txt: "Provide a valid",
  complete_register_header_txt: "Complete Registration",
  basic_info_header_txt: "Basic Information",
  acc_info_header_txt: "Account Information",
  registered_address_header_txt: "Registered Address",
  acc_update_msg_txt: "Account Updated Successfully",
  select_img_msg_txt: "Please select an Image!",
  already_logged_in_error_msg: "Already Logged In",
  contact_sales_btn_txt: "Contact Sales",
  contact_sales_url: "https://gigalogy.com/request-demo",
  insights: {
    search: "Search",
    recommend: "Recommend",
    orders: "Orders",
    revenue: "Revenue",
    average_cart_size: "Average Cart Size",
    average_order_value: "Average order value",
    average_sales_users: "Average Sales/Users",
    average_sales_items: "Average Sales/Items",
    top_users: "Top Users",
    item: "Item",
    user: "User",
    line_chart: "Line Chart",
    items_counts: "Items Counts",
    users_counts: "Users Counts",
    top_purchased_items: "Top Purchased Items",
    top_browsed_items: "Top Browsed Items",
    top_rated_items: "Top Rated Items",
    browse: "Browse",
    rated: "Rated",
    feedback_type_required: "Feedback type is required",
    positive: "Positive",
    negative: "Negative",
    positive_rated: "Positive Rated",
    negative_rated: "Negative Rated",
    purchase: "Purchase",
    search_txt: "Total searches for items and images.",
    recommend_txt: "Total number of requests for recommend endpoints.",
    orders_txt: "The total number of orders.",
    revenue_txt: "Total purchase amounts.",
    average_cart_size_txt: "Calculates average items per order.",
    average_sales_orders_txt: "The total sales revenue divided by the number of orders.",
    average_sales_users_txt: "The total sales revenue divided by the number of users.",
    average_sales_items_txt: "The total sales revenue divided by the number of items.",
    last_updated_at: "Last Updated At",
  },
  common: {
    required_field: "required field",
    field_required_error_txt: "This Field is required",
    duplicate_error_txt: "This Field already exists",
    add: "Add",
    cancel: "Cancel",
    confirm: "Confirm",
    continue: "Continue",
    details: "Details",
    done: "Done",
    next: "Next",
    previous: "Previous",
    proceed: "Proceed",
    review: "Review",
    save: "Save",
    close: "Close",
    input: "Input",
    actions: "Actions",
    load_more: "Load More",
    date: "Date",
    status: "Status",
    type: "Type",
    name: "Name",
    copied: "Copied",
    copy: "Copy",
    loading: "Loading",
    download: 'Download',
    query: "Query",
    response: "Response",
    feedback: "Feedback",
    description: "Description",
    try_again: "Try Again",
    read_more: "Read More",
  },
  gpt_profile: {
    introduction: "Introduction",
    system: "System",
    temperature: "Temperature",
    top_p : "top_p",
    frequency_penalty: "Frequency Penalty",
    presence_penalty: "Presence Penalty",
    stop: "Stop",
    search_max_token: "Search Max Token",
    completion_token: "Completion Token",
    preferred_content: "Preferred Content",
    vision_settings: "Vision Settings",
    resolution : "Resolution",
    image_context_enabled: "Enable Image Context",
    profile_creation_success: "GPT Profile Created Successfully",
    profile_creation_error: "An error occurred while creating GPT Profile",
    profile_update_success: "GPT Profile Updated Successfully",
    profile_update_error: "An error occurred while updating GPT Profile",
    profile_fetch_error: "There was an error while fetching GPT Profile",
    profiles_fetch_error: "There was an error while fetching GPT Profiles",
    confirm_alert_msg_gpt_setting: "Are you sure to delete this GPT Setting?",
    profile_information: "Profile Information",
    default_profile: "Default Profile",
    bot_mode_strict: "Bot Mode Strict",
    default_vision_profile: "Default Vision Profile",
    max_feedback_rating: "Max Feedback Rating",
    summary_settings: "Summary Settings",
    create_settings: "CREATE SETTINGS",
    update_settings : "UPDATE SETTINGS",
    maira_datasets: "Maira DATASETS",
    maira_settings: "Maira SETTINGS",
    view_details: "VIEW DETAILS",
    create_profile: "CREATE PROFILE",
    update_profile: "UPDATE PROFILE",
    profiles: "Profiles",
    settings: "Settings",
    profileNamePlaceholder: "Name your GPT",
    introductionPlaceholder: "Detailed instruction for the bot on how to respond to a query. Example: Respond concisely. Avoid providing information that the user did not explicitly request. Maintain a formal tone.",
    systemPlaceholder: "Define the persona of your bot. Such as 'You are Joseph and expert on company X and your role is to support customers'",
    includes: "Includes",
    excludes: "Excludes",
    dataset_tags : "Dataset Tags",
    filterable_fields: "Filterable Fields",
    available_gpt_models: "Available GPT Models",
    summary_settings_example: "Summary Settings Example",
    summary_settings_explanation: "The summary settings takes JSON format as input. Inside JSON requires some fiels below",
    required_fields: "Required fields",
    optional: "Optional",
    exclude_filters: "exclude filters: ",
    exclude_filters_text: "Key-value pairs to filter out conversations that are to be excluded from summary generation.",
    model: "model",
    model_text: "Name of available models.",
    prompt: "prompt",
    prompt_text: "GPT prompt to generate the summary.",
    interval: "interval",
    interval_text: "Summary generation intervals, supported interval is",
    daily: "daily.",
    List_str: "List[str]: ",
    units: "units",
    units_text: "Keys based on which conversations will be grouped to generate summary.",
  },
  invoice: {
    pay: "Pay",
  },
  job: {
    side_nav: "Jobs",
    header: "Jobs",
    re_run: "Re-run",
    started_at: "Started at",
    finished_at: "Finished at",
    exception: "Exception",
    re_run_job: "Re-run Job",
    run: "Run",
  },
  member: {
    invite_btn_txt: "Invite",
    modal_title: "Member Invitation",
    role: "Member Role",
    role_dialog_msg: "Are you sure to update role for __email__?",
    status_dialog_msg: "Are you sure to update status for __email__?",
  },
  navbar: {
    dashboard: "Dashboard",
    solutions: "Solutions",
    projects: "Projects",
    invoices: "Invoices",
    wallet: "Wallet",
    recommender: "Personalizer",
    cv: "CV",
    maira: "Maira",
    release_note: "Release Note"
  },
  project: {
    created_at: "Created",
    settings_initial_setup_info: "Please finish initial setup to start training.",
    training: "Training",
    dataset_update: "Dataset Update",
    upload_dataset: "Upload Dataset",
    dataset_create_success: "Dataset created successfully.",
    dataset_create_failed: "Failed to create a dataset",
    dataset: "Dataset",
    uploading: "Uploading..",
    message: "Message",
    generated_by: "Generated By",
    update: "Update",
    update_rank_settings: "Update Rank Settings",
    create_rank_settings: "Create Rank Settings",
    train_options: "Train Options",
    train_all_item: "Train all items",
    train_updated_item: "Train updated items",
    train_item_within_specified_range: "Train items within specified range",
    train_all_image: "Train all images",
    train_image_within_specified_range: "Train images within specified range",
    date_field_required:"Date field is required for specific range",
    start_date_validation: "Start date must be less than the end date",
    end_date_validation: "End date must be greater than the start date",
    train_history: "Train History",
    train: "Train",
    items_train: "Items train",
    images_train: "Images train",
    ranks_train: "Ranks train",
    gpt_train: "GPT train",
    insight_process: "Insight Process",
    setup: "Setup",
    finish: "Finish",
    setup_in_progress: "SETUP IN PROGRESS...",
    already_assigned_to_another_field: "Already assigned to another field",
    select_file: "Select File",
    map_data: "Map Data",
    rank_settings: "Rank Settings",
    progress: "Progress",
    key_type: "Key Type",
    value_type: "Value Type",
    add_more: "Add More",
    train_data: "Train Data",
    train_type: "Train Type",
    all: "All",
    update_mapper: "Update Mapper",
    dataset_update_info: "You can update your item catalogue from here",
    dataset_update_log: "Dataset Update Log",
    task_status: "Task Status",
    updating: "Updating..",
    field_required: "This Field is required",
    field_required_for_prioritize_category: "Prioritize category is required when values are set",
    field_required_for_prioritize_values: "Prioritize values are required when a category is selected",
    try_again: "Try Again",
    train_using_dataset: "Train the model using the dataset"
  },
  gpt_dataset: {
    dataset_header_txt: "My Datasets",
    dataset_document_header_txt: "Documents",
    description_label_txt: "Description",
    fill_up_index_if_empty: "Fill Up Index if Empty",
    secondary_index_column: "Secondary Index Column",
    image_url_column: "Image URL Column",
    dataset_file: "Dataset File",
    select_dataset: "Select Dataset",
    dataset_name: "Dataset Name",
    index_column_name: "Index Column Name",
    training_in_progress: "Training in Progress",
    dataset_submission_success: "Dataset Creation request submitted successfully",
    dataset_submission_error: "An error occurred while submitting gpt dataset creation request",
    dataset_update_success: "Dataset updating request submitted successfully",
    dataset_update_error: "An error occurred while updating dataset",
    training_type: "Training Type",
    dataset_fetch_error: "An error occurred while fetching dataset",
    batch_size: "Batch Size",
    document_count: "Document Count",
    text_trained: "Text Trained",
    image_trained: "Image Trained",
    dataset_uploading_progress: "Documents are uploading",
    document_upload_header_txt: "Documents Upload",
    dataset_process_progress: "Dataset is in processing. You can train once the processing is completed.",
    confirm_alert_msg_txt_1: "Are you sure to delete this Dataset?",
    confirm_alert_msg_txt_2: "Are you sure to delete all Documents?",
    confirm_alert_msg_txt_3: "Are you sure to delete all selected Documents?",
    delete_dataset_progress_txt: "Deleting dataset is in progress. Please wait for the task to complete.",
  },
  gpt_document: {
    create_document: "Documents",
    upload_csv: "CSV",
    update_document: "Update Document",
    document_key: "Key",
    document_value: "Value",
    delete_document_progress_header_txt: "Delete Document",
    delete_document_progress_txt: "Document deleting is in progress",
  },
  setup_messages: {
    creating_mapper: "Creating Mapper...",
    creating_indices: "Creating Indices...",
    creating_rank_settings: "Creating Rank Settings...",
    uploading_data: "Uploading data...",
    error_in_completing_the_step: "Error in completing the step!",
    mapper_created_successfully: "Mapper Created Successfully.",
    index_created_successfully: "Indices Created Successfully.",
    rank_settings_created_successfully: "Rank Settings Created Successfully.",
    data_uploaded_successfully: "Data Uploaded Successfully.",
    flag_value_should_be_boolean: "Flag value should be boolean.",
    mapper_updated_successfully: "Mapper Updated Successfully."
  },
  solution: {
    developed_by: "Developed by",
    preview: "Preview",
  },
  tooltip: {
    draft: "Draft",
    submit_for_review: "Submit for Review",
    edit: "Edit",
    copy: "Copy Text",
    stop: "Stop",
    start: "Start",
    delete: "Delete",
    open_window: "Opens in new window",
    document: "Document",
  },
  validation_msg: {
    url: "Please provide a valid URL like https://example.com",
    is_numeric: "{_field_} Cannot contain only Numeric",
    number_dash: "Provide a valid {_field_}",
    phone_num: "Provide a valid {_field_}",
    birth_date: "Provide a valid {_field_}",
    postal_code: "Provide a valid {_field_}",
    max_decimal: "{_field_} must not exceed 7 digits after the decimal point",
  },
  wallet: {
    passcode: "Passcode",
    create_passcode: "Create Passcode",
    create_passcode_success: "Passcode registered successfully",
    enter_passcode: "Enter Passcode",
    re_enter_passcode: "Re-enter Passcode",
    confirm_passcode: "Confirm Passcode",
    add_account_message: "Please add or create an account to access the wallet.",
    action_type: "Action Type",
    action_type_tooltip: "Action Type",
    import_account: "Import Account",
    import: "Import",
    create: "Create",
    secret_key: "Secret Key",
    enter_secret_key: "Enter Your Secret Key",
    account_name: "Account Name",
    account_name_tooltip: "Account Name helps you easily identify and remember your staller account.",
    secret_key_tooltip: "The secret key of the account that you want to import.",
    create_account: "Create Account",
    create_account_tooltip: "You can create an account Using a source account or without a source account.",
    create_account_source: "Using Source Account",
    create_account_without_source: "Without Source Account",
    source_account_secret: "Source Account Secret",
    source_account_secret_tooltip: "Secret key of your source account.",
    starting_balance: "Starting Balance",
    starting_balance_tooltip: "The amount you want to transfer to your new stellar account.",
    memo: "Memo",
    memo_tooltip: "The memo contains optional extra information.",
    credentials: "Credentials",
    credentials_download_btn: "Download JSON",
    add_account: "Add Account",
    activate_account_notice: "The selected account is not activated yet, please deposit some XLM to activate it.",
    buy: "Buy",
    send_swap: "Send/Swap",
    transactions: "Transactions",
    rewards: "Rewards",
    save_secret_key_notice: "We do not hold your funds. We simply help you encrypt your secret key to provide you with a seamless experience. If you lose your passcode or secret key there is no way to restore access to your funds. Please save your secret key in a secure location.",
    see_secret_key_btn: "see secret key",
    understood_btn: "Understood",
    passcode_message: "WARNING: The passcode is the only way of accessing your wallet and secret keys. Never share your passcode with anyone. If you lose your passcode, your balances and secret keys will be permanently lost and there's no way of recovering them again without your passcode, as Doozie Wallet is a non-custodial wallet and our server only stores your encrypted secret keys which are impossible to decrypt without your given passcode.",
    selected_account: "Selected Account",
    account_details: "Account Details",
    balance: "Balance",
    buying_liabilities: "Buying liabilities",
    selling_liabilities: "Selling liabilities",
    reserve: "Reserve",
    base_network_reserve: "Base network reserve",
    base_network_reserve_tooltip: "Base network reserve",
    doozie_reserve_tooltip: "Doozie reserve",
    per_entry_reserve: "Per entry reserve",
    per_entry_reserve_tooltip: "Per entry reserve",
    entry_count: "Entry count",
    entry_count_tooltip: "Entry count",
    calculation: "Calculation",
    total_reserve: "Total reserve",
    wallet_account: "Wallet Account",
    wallet_account_status: "Account Status",
    public_key: "Public Key",
    wallet_click_for_copy: "Click for copy to clipboard.",
    edit_account: "Edit Account",
    delete_account: "Delete Account",
    wallet_account_delete_confirmation_msg: "Are you sure that you want to remove this wallet account?",
    send_or_swap: "Send or Swap",
    send_swap_not_possible_msg: "You are not able to do send and swap action. Your Encrypted secret key is not found on your browser. Please enter your secret key again to do this action. Thank you!",
    send_swap_can_upto_msg: "You can send/swap up to",
    send_swap_action_type: "Action Type",
    send_swap_action_tooltip: "Choose an option",
    send_swap_send: "Send",
    send_swap_swap: "Swap",
    destination_public_key_label: "Destination Public Key",
    destination_public_key_msg: "Select your destination account public key.",
    destination_account: "Destination Account",
    destination_account_placeholder: "Example: GAGM6XQAUGFE5CMCOHQVW4TJCXJDRLSOM6TJUXCKBDO75DBHJMAEHF2G",
    destination_amount_label: "Amount",
    destination_amount_msg: "Amount that you want to send",
    available: "Available",
    asset: "Asset",
    amount: "Amount",
    swap: "Swap",
    swap_asset: "Swap Asset.",
    from_asset: "From Asset",
    from_amount: "From Amount",
    to_asset: "To Asset",
    to_amount_name: "To Amount",
    to_amount_label: "To Amount (Estimated)",
    next_update_will: "Next update will",
    exchange_rate: "Exchange Rate",
    allowed_slippage_label: "Allowed slippage:",
    allowed_slippage_message: "Your transaction will revert if the price changes unfavorably by more than this percentage.",
    minimum_received_label: "Minimum received:",
    minimum_received_message: "The minimum amount of tokens you will receive once the swap is completed.",
    path_label: "Path:",
    path_message: "Routing through these tokens based on order books and liquidity rates resulted in the best price for your swap.",
    send_swap_account: "Account",
    send_to: "Send to",
    transaction_success: "Transaction Success",
    created_at: "Created At",
    transaction_id: "Transaction ID",
    open_stellar_netword_txt: "Open on stellar network",
    buy_history: "Buy History",
    buy_xlm_btn: "Buy XLM",
    buy_lumens_txt: "Buy Lumens (XLM)",
    buy_lumens_by_credit_card: "Use your debit or credit card to buy Stellar Lumens (XLM)",
    currency: "Currency",
    gateway: "Gateway",
    buy_with_wyre: "Buy with Wyre",
    destination: "Destination",
    from: "From",
    account: "Account",
    address: "Address",
    dzt_rewards: "Rewards",
    rewards_pending: "Pending",
    rewards_claimed: "Claimed",
    rewards_claimed_not_possible_msg: "You are not able to claim your rewards. Your Encrypted secret key is not found on your browser. Please enter your secret key again to do this action. Thank you!",
    claim: "Claim",
    reward_claimed: "Reward Claimed",
    no_more_pending_reward: "There are no more pending rewards.",
    total_paid: "Total Paid",
    source_amount: "Source Amount",
    received_amount: "Received Amount",
    transaction_fee: "Transaction Fee",
    click_to_show_secret: "Click to show the secret key.",
    secret_save_msg: "Please save your secret key in a secure place.",
    failed_to_send: "Failed To Send",
    holding_balance: "Holding Balance (DZT) Over",
    reward_percentage: "Reward Percentage",
    reward_percentage_details: "Reward Percentage Details",
    dzt_holding_balance_text: "Your current DZT holding balance is",
    dzt_reward_percentage_text_1: "You will get ",
    dzt_reward_percentage_text_2: "of the transaction fee in DZT as a reward.",
    dzt_increase_text: "Please increase your DZT balance to get a reward on the transaction fee.",
    minimum_buy: "Minimum Buy",
    minimum_buy_amount_text: "Minimum buy amount for {_source_currency_} is",
    track_buy: "Click to track it on wyre.",
    track: "Track",
  },
  dashboard: {
    projects: "Projects",
    active: "Active",
    click_to_see_projects: "Click to see all projects",
    recommended_articles: "Recommended Articles"
  },
  questionnaire: {
    questionnaire_id: "Questionnaire ID",
    user_id: "User ID",
    request_id: "Request ID",
    created_at: "Created At",
    prompt: "Prompt",
    response: "Response",
    questionnaire_response_title: "Questionnaire Response",
    survey_values: "Survey Values",
    recommendation: "Recommendation",
  },
  logs: {
    questionnaire_logs_title: "Questionnaire Logs",
  },
  filter: {
    questionnaire_id: "Search Questionnaire ID",
    user_id: "Search User ID",
    search_query: "Search Query",
  }
}