<template>
  <div>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="pa-0" xs="12" md="12">
          <v-card outlined class="mx-auto px-2">
            <v-row>
              <v-col xs="12" md="6">
                <div class="px-2 primary--text font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.wallet.transactions") }}
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <Loader v-if="isLoading" class="mr-2 mt-6" />
            <v-data-table
              v-else
              :headers="headers"
              :items="stellarTransaction"
              hide-default-footer
              :items-per-page="-1"
              @click:row="handleClick"
              style="cursor: pointer"
            >
              <template v-slot:[`item.source_account`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="
                      d-block
                      font-weight-bold
                      text--primary text-truncate
                      my-2
                    "
                  >
                    {{
                      item.type === "send"
                      ? `${$vuetify.lang.t("$vuetify.wallet.destination")}: ${passChar(item.to)}`
                      : item.type === "recieved"
                      ? `${$vuetify.lang.t("$vuetify.wallet.from")}: ${passChar(item.from)}`
                      : item.type === "create_account" &&
                        item.funder === account_select
                      ? `${$vuetify.lang.t("$vuetify.wallet.destination")}: ${passChar(item.account)}`
                      : item.type === "create_account" &&
                        item.funder !== account_select
                      ? `${$vuetify.lang.t("$vuetify.wallet.from")}: ${passChar(item.funder)}`
                      : `${$vuetify.lang.t("$vuetify.wallet.account")}: ${passChar(item.source_account)}`
                    }}
                  </span>
                  <small>ID: {{ passChar(item.transaction_hash) }}</small>
                </div>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <v-chip class="text-capitalize" outlined small color="primary">
                  {{
                    item.type && item.type.replace("_", " ")
                  }}
                </v-chip>
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                <div
                  class="d-flex flex-column my-2"
                  v-if="item.type === 'swap'"
                >
                  <span
                    class="
                      d-block
                      font-weight-semibold
                      text--primary text-truncate
                      mb-2
                    "
                  >
                    <Price 
                      color="primary"
                      prefix="+"
                      :amount="item.amount"
                      :asset_code="item.asset_code"
                    />
                  </span>
                  <span>
                    <Price 
                      color="error"
                      prefix="-"
                      :amount="item.source_amount"
                      :asset_code="item.source_asset_code"
                    />
                  </span>
                </div>
                <div
                  v-else-if="
                    item.type === 'send'
                  "
                >
                  <Price 
                    color="error"
                    prefix="-"
                    :amount="item.amount"
                    :asset_code="item.asset_code"
                  />
                </div>
                <div v-else-if="item.type === 'recieved'">
                  <Price 
                    color="primary"
                    prefix="+"
                    :amount="item.amount"
                    :asset_code="item.asset_code"
                  />
                </div>
                <div
                  v-else-if="
                    item.type === 'create_account' &&
                    item.funder === account_select
                  "
                >
                  <Price 
                    color="error"
                    prefix="-"
                    :amount="item.amount"
                    :asset_code="item.asset_code"
                  />
                </div>
                <div
                  v-else-if="
                    item.type === 'create_account' &&
                    item.funder !== account_select
                  "
                >
                  <Price 
                    color="primary"
                    prefix="+"
                    :amount="item.amount"
                    :asset_code="item.asset_code"
                  />
                </div>
                <div v-else>
                  <Price 
                    color="primary"
                    prefix="+"
                    :amount="item.amount"
                    :asset_code="item.asset_code"
                  />
                </div>
              </template>
              <template v-slot:[`item.transaction_successful`]="{ item }">
                <v-chip
                  class="text-capitalize"
                  outlined
                  small
                  :color="`${
                    item.transaction_successful === true ? 'primary' : 'red'
                  }`"
                >
                  {{
                    item.transaction_successful === true
                      ? "Completed"
                      : "failed"
                  }}
                </v-chip>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{
                  new Date(item.created_at).toLocaleDateString("en-us", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}
              </template>
            </v-data-table>

            <v-row>
              <v-col class="py-0" cols="12" md="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3"></v-col>
              <v-col
                cols="12"
                xs="12"
                sm="6"
                md="6"
                class="d-flex justify-center"
              >
                <v-btn
                  @click="loadmoreClick()"
                  color="primary"
                  :loading="loadmore"
                  :disabled="disableLoadmore"
                >
                  {{ $vuetify.lang.t("$vuetify.common.load_more") }}</v-btn
                >
              </v-col>
              <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                <v-select
                  v-model="selectRow"
                  :items="rows"
                  hide-details="auto"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="txnDetailsDialog" max-width="600" persistent>
      <v-card>
        <v-row>
          <v-col>
            <div
              v-if="
                txnDetails && txnDetails.type === 'swap'
              "
              class="d-flex justify-center align-center"
            >
              <div class="d-flex flex-column justify-center align-center">
                <v-avatar
                  left
                  size="50"
                  :color="
                    allAsset.find((a) => a.asset_code === txnDetails.asset_code)
                      ? 'lighten-2'
                      : 'primary lighten-2'
                  "
                >
                  <span class="font-weight-bold">
                    <v-img
                      max-height="50px"
                      max-width="50px"
                      v-if="allAsset.find((a) => a.asset_code === txnDetails.asset_code)"
                      :src="allAsset.filter(a => a.asset_code === txnDetails.asset_code)[0].asset_icon"
                    ></v-img>
                    <span v-else class="fix-asset-font">
                      {{ txnDetails.asset_code }}
                    </span>
                  </span>
                </v-avatar>
                <Price 
                  color="primary"
                  prefix="+"
                  :amount="txnDetails.amount"
                  :asset_code="txnDetails.asset_code"
                />
                <div><v-chip color="primary" small outlined>To</v-chip></div>
              </div>
              <div class="mx-2 mx-md-4">
                <v-icon larze>mdi-swap-horizontal-bold</v-icon>
              </div>
              <div class="d-flex flex-column justify-center align-center">
                <v-avatar
                  left
                  size="50"
                  :color="
                    allAsset.find((a) => a.asset_code === txnDetails.source_asset_code)
                      ? 'lighten-2'
                      : 'primary lighten-2'
                  "
                >
                  <span class="font-weight-bold">
                    <v-img
                      max-height="50px"
                      max-width="50px"
                      v-if="allAsset.find((a) => a.asset_code === txnDetails.source_asset_code)"
                      :src="allAsset.filter(a => a.asset_code === txnDetails.source_asset_code)[0].asset_icon"
                    ></v-img>
                    <span v-else class="fix-asset-font">
                      {{ txnDetails.source_asset_code }}
                    </span>
                  </span>
                </v-avatar>
                <Price 
                  color="error"
                  prefix="-"
                  :amount="txnDetails.source_amount"
                  :asset_code="txnDetails.source_asset_code"
                />
                <div><v-chip small color="error" outlined>From</v-chip></div>
              </div>
            </div>
            <div
              v-else-if="txnDetails"
              class="d-flex flex-column justify-center align-center"
            >
              <div>
                <v-avatar
                  left
                  size="50"
                  :color="
                    allAsset.find((a) => a.asset_code === txnDetails.asset_code) ||
                    txnDetails.type === 'create_account'
                      ? 'lighten-2'
                      : 'primary lighten-2'
                  "
                >
                  <span class="font-weight-bold">
                    <v-img
                      max-height="50px"
                      max-width="50px"
                      v-if="
                        allAsset.find((a) => a.asset_code === txnDetails.asset_code) ||
                        txnDetails.type === 'create_account'
                      "
                      :src="allAsset.filter(a => a.asset_code === txnDetails.asset_code)[0].asset_icon"
                    ></v-img>
                    <span v-else class="fix-asset-font">
                      {{ txnDetails.asset_code }}
                    </span>
                  </span>
                </v-avatar>
              </div>
              <Price 
                v-if="txnDetails.type === 'send'"
                color="error"
                prefix="-"
                :amount="txnDetails.amount"
                :asset_code="txnDetails.asset_code"
              />
              <Price 
                v-if="txnDetails.type === 'recieved'"
                color="primary"
                prefix="+"
                :amount="txnDetails.amount"
                :asset_code="txnDetails.asset_code"
              />
              <Price 
                v-if="txnDetails.type === 'create_account' && txnDetails.funder === account_select"
                color="error"
                prefix="-"
                :amount="txnDetails.amount"
                :asset_code="txnDetails.asset_code"
              />
              <Price 
                v-if="txnDetails.type === 'create_account' && txnDetails.funder !== account_select"
                color="primary"
                prefix="+"
                :amount="txnDetails.amount"
                :asset_code="txnDetails.asset_code"
              />
              <div class="text-capitalize">
                <v-chip
                  :color="
                    txnDetails.type === 'send' || (txnDetails.type === 'create_account' && txnDetails.funder === account_select)
                      ? 'error'
                      : 'primary'
                  "
                  small
                  outlined
                  >{{ txnDetails.type.replaceAll("_", " ") }}</v-chip
                >
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-subtitle class="my-2">
          <v-row no-gutters v-if="txnDetails">
            <v-col cols="3">
              <div class="font-weight-bold">
                {{
                  txnDetails.type === "send"
                    ? `${$vuetify.lang.t("$vuetify.wallet.destination")}: `
                    : txnDetails.type === "recieved"
                    ? `${$vuetify.lang.t("$vuetify.wallet.from")}: `
                    : txnDetails.type === "create_account" &&
                      txnDetails.funder === account_select
                    ? `${$vuetify.lang.t("$vuetify.wallet.destination")}: `
                    : txnDetails.type === "create_account" &&
                      txnDetails.funder !== account_select
                    ? `${$vuetify.lang.t("$vuetify.wallet.from")}: `
                    : `${$vuetify.lang.t("$vuetify.wallet.account")}: `
                }}
              </div>
            </v-col>
            <v-col cols="9">
              {{
                txnDetails.type === "send"
                  ? `${txnDetails.to} `
                  : txnDetails.type === "recieved"
                  ? `${txnDetails.from}`
                  : txnDetails.type === "create_account" &&
                    txnDetails.funder === account_select
                  ? `${txnDetails.account}`
                  : txnDetails.type === "create_account" &&
                    txnDetails.funder !== account_select
                  ? `${txnDetails.funder}`
                  : `${txnDetails.source_account}`
              }}
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <v-row no-gutters class="my-2">
            <v-col cols="3">
              <div class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.wallet.created_at") }}:</div>
            </v-col>
            <v-col cols="9">
              {{
                txnDetails
                  ? new Date(txnDetails.created_at).toLocaleDateString(
                      "en-us",
                      {
                        hour: "numeric",
                        minute: "numeric",
                        second: "numeric",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )
                  : "N/A"
              }}
            </v-col>
          </v-row>
          <v-row no-gutters class="my-2">
            <v-col cols="3">
              <div class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.wallet.transaction_id") }}:</div>
            </v-col>
            <v-col cols="9">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    :href="txnDetails && txnDetails._links.transaction.href"
                    @click.stop
                    v-on="on"
                    class="text-decoration-none"
                  >
                    {{ txnDetails ? txnDetails.transaction_hash : "N/A" }}
                  </a>
                </template>
                {{ $vuetify.lang.t("$vuetify.wallet.open_stellar_netword_txt") }}
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-2">
            <v-col cols="3">
              <div class="font-weight-bold">{{ $vuetify.lang.t("$vuetify.common.status") }}:</div>
            </v-col>
            <v-col cols="9">
              {{
                txnDetails && txnDetails.transaction_successful === true
                  ? "Completed"
                  : "failed"
              }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-2" color="error" @click="closeSuccessDialog()"
            >{{ $vuetify.lang.t("$vuetify.common.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

import {
  LOAD_ALL_WALLET_TRANSACTION,
  REFRESHTOKEN,
  ADD_ALERT,
  GET_WALLET_TRANSACTION,
} from "@/store/_actiontypes";
import { SET_WALLET_STELLAR_TRANSACTION } from "@/store/_mutationtypes";
import validations from "@/helpers/validations";
import Loader from "@/components/common/loader/Loader";
import {
  formatNum,
  timeToNextToken,
  timeToNextWalletToken,
  capitalizeFirstLetter,
} from "@/helpers/helper";
import { CURRENCY } from "@/global/_constants";
import Price from "@/components/wallet/Price";

export default {
  props: {
    item: {
      type: Object,
    },
    allAsset: {
      type: Array,
    },
  },
  components: {
    Loader,
    Price,
  },
  computed: {
    ...mapState({
      allTransaction: (state) => state.wallet.allTransaction,
      allAccount: (state) => state.wallet.allAccount,
      user: (state) => state.account.user,
      stellarTransaction: (state) => state.wallet.stellarTransaction,
      dateRangeText: function () {
        return this.dates.join(" ~ ");
      },
    }),
    ...mapGetters("loader", ["loading"]),
  },
  mounted() {},
  data() {
    return {
      isLoading: false,
      timeToNextToken,
      timeToNextWalletToken,
      CURRENCY,
      formatNum,
      capitalizeFirstLetter,
      selectedType: null,
      selectRow: 10,
      rows: [5, 10, 20],
      ...validations,
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.common.date'),
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.account'),
          align: "start",
          sortable: true,
          value: "source_account",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.common.type'),
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.wallet.amount'),
          align: "start",
          sortable: true,
          value: "amount",
        },
        {
          text: this.$vuetify.lang.t('$vuetify.common.status'),
          align: "start",
          sortable: false,
          value: "transaction_successful",
        },
      ],
      allType: [
        {
          name: "All",
          key: null,
        },
        {
          name: "Buy",
          key: "buy",
        },
        {
          name: "Send",
          key: "send",
        },
        {
          name: "Swap",
          key: "swap",
        },
      ],
      account_select: null,
      txnDetailsDialog: false,
      txnDetails: null,
      xlmIcon:
        "https://img.icons8.com/external-black-fill-lafs/144/external-Stellar-cryptocurrency-black-fill-lafs.png",
      dztIcon: "https://doozie.io/img/favicon.png",
      nextLink: null,
      prevLink: null,
      loadmore: false,
      disableLoadmore: false,
      cursor: null,
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(val, oldVal) {
        this.account_select = val.public_key;
        val.status === "created" 
          ? this.SET_WALLET_STELLAR_TRANSACTION([])
          : this.loadAllTransaction()
      },
    },
    selectRow() {
      this.loadAllTransaction();
    },
  },
  methods: {
    ...mapActions("account", [REFRESHTOKEN]),
    ...mapActions("wallet", [
      LOAD_ALL_WALLET_TRANSACTION,
      GET_WALLET_TRANSACTION,
    ]),
    ...mapMutations("wallet", [SET_WALLET_STELLAR_TRANSACTION]),
    loadAllTransaction() {
      if (this.timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.loadStellarTransaction();
          },
          (error) => error
        );
      } else {
        this.loadStellarTransaction();
      }
    },
    loadStellarTransaction() {
      this.isLoading = true;
      this.GET_WALLET_TRANSACTION({
        public_key: this.item ? this.item.public_key : "",
        limit: this.selectRow,
        cursor: "",
      })
        .then((res) => {
          this.isLoading = false;
          let data = res?.data?.stellar_response;
          this.cursor = res.data?.next_cursor;
          this.disableLoadmore = data.length < this.selectRow ? true : false;
        })
        .catch((err) => (this.isLoading = false));
    },
    loadmoreClick() {
      if (this.timeToNextWalletToken() < 1) {
        this.$emit("openPasscode");
      } else {
        this.loadmore = true;
        this.GET_WALLET_TRANSACTION({
          public_key: this.item ? this.item.public_key : "",
          limit: this.selectRow,
          cursor: this.cursor ? this.cursor : "",
          commit_data: false,
        })
          .then((res) => {
            let prevData = this.stellarTransaction;
            let data = res?.data?.stellar_response;
            let combineData = [...prevData, ...data];
            this.SET_WALLET_STELLAR_TRANSACTION(combineData);
            this.cursor = res?.data?.next_cursor;
            this.loadmore = false;
            this.disableLoadmore = data.length < this.selectRow ? true : false;
          })
          .catch((err) => {
            this.loadmore = false;
          });
      }
    },
    handleClick(value) {
      this.txnDetailsDialog = true;
      this.txnDetails = value;
    },
    closeSuccessDialog() {
      this.txnDetailsDialog = false;
      this.txnDetails = null;
    },
    formatDateTime(datetime) {
      return new Date(datetime.toLocaleString());
    },
    passChar(str) {
      return `${str.substring(0, 5)}**********${str.substring(
        str.length - 6,
        str.length
      )}`;
    },
  },
};
</script>

<style>
.table-bar {
  margin: 5px;
}
.total-cost {
  margin-right: 100px !important;
}
.fix-asset-font {
  font-size: 10px; 
  color:white;
}
</style>