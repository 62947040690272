<template>
  <v-layout row>
    <Button
      v-if="detailsButton"
      :buttonType="ICON_BUTTON"
      iconName="mdi-eye"
      size="small"
      :buttonText="$vuetify.lang.t('$vuetify.details_header_txt')"
      :disabled="disabledDetailsButton"
      @onClick="onDetailsClick()"
    />
    <Button
      v-if="editButton"
      :buttonType="ICON_BUTTON"
      iconName="mdi-pencil"
      size="small"
      :buttonText="$vuetify.lang.t('$vuetify.tooltip.edit')"
      :disabled="disabledEditButton"
      @onClick="onEditClick()"
    />
    <Button
      v-if="addButton"
      :buttonType="ICON_BUTTON"
      iconName="mdi-plus"
      size="small"
      buttonText="Add to common example"
      :disabled="disabledAddButton"
      @onClick="onAddClick()"
    />
    <Button
      v-if="stopButton"
      :buttonType="ICON_BUTTON"
      iconName="mdi-stop"
      size="small"
      :buttonText="$vuetify.lang.t('$vuetify.tooltip.stop')"
      :disabled="disabledStopButton"
      @onClick="onStopClick()"
    />
    <Button
      v-if="startButton"
      :buttonType="ICON_BUTTON"
      iconName="mdi-reload"
      size="small"
      :buttonText="$vuetify.lang.t('$vuetify.tooltip.start')"
      :disabled="disabledStartButton"
      @onClick="onStartClick()"
    />
    <Button
      v-if="documentButton"
      :buttonType="ICON_BUTTON"
      iconName="mdi-file-document"
      size="small"
      :buttonText="$vuetify.lang.t('$vuetify.tooltip.document')"
      :disabled="disabledDocumentButton"
      @onClick="onDocumentClick()"
    />
    <Button
      v-if="deleteButton"
      :buttonType="ICON_BUTTON"
      iconName="mdi-delete"
      size="small"
      :buttonText="$vuetify.lang.t('$vuetify.tooltip.delete')"
      :disabled="disabledDeleteButton"
      @onClick="onDeleteClick()"
    />
    <Button
      v-if="downloadButton"
      :buttonType="ICON_BUTTON"
      iconName="get_app"
      size="small"
      buttonText="Download"
      :disabled="disabledDownloadButton"
      @onClick="onDownloadClick()"
    />
  </v-layout>
</template>

<script>
import { ICON_BUTTON } from "@/components/common/button/_buttontypes";

import Button from "@/components/common/button/Button";

export default {
  props: {
    detailsButton: {
      type: Boolean,
      default: true,
    },
    editButton: {
      type: Boolean,
      default: true,
    },
    documentButton: {
      type: Boolean,
      default: false,
    },
    deleteButton: {
      type: Boolean,
      default: true,
    },
    addButton: {
      type: Boolean,
      default: true,
    },
    stopButton: {
      type: Boolean,
      default: true,
    },
    startButton: {
      type: Boolean,
      default: true,
    },
    downloadButton: {
      type: Boolean,
      default: false,
    },
    disabledDetailsButton: {
      type: Boolean,
      default: false,
    },
    disabledEditButton: {
      type: Boolean,
      default: false,
    },
    disabledDocumentButton: {
      type: Boolean,
      default: false,
    },
    disabledDeleteButton: {
      type: Boolean,
      default: false,
    },
    disabledAddButton: {
      type: Boolean,
      default: false,
    },
    disabledStartButton: {
      type: Boolean,
      default: false,
    },
    disabledStopButton: {
      type: Boolean,
      default: false,
    },
    disabledDownloadButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Button,
  },
  computed: {},
  data() {
    return {
      ICON_BUTTON: ICON_BUTTON,
    };
  },
  methods: {
    onDetailsClick() {
      this.$emit("onDetailsClick");
    },
    onEditClick() {
      this.$emit("onEditClick");
    },
    onDocumentClick() {
      this.$emit("onDocumentClick");
    },
    onDeleteClick() {
      this.$emit("onDeleteClick");
    },
    onAddClick() {
      this.$emit("onAddClick");
    },
    onStopClick() {
      this.$emit("onStopClick");
    },
    onStartClick() {
      this.$emit("onStartClick");
    },
    onDownloadClick() {
      this.$emit("onDownloadClick");
    },
  },
};
</script>
