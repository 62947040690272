export const NUMBER_FORMAT_LOCALE = "en-US";
export const CURRENCY = "USD";
export const GPT_MODELS = [
  "gpt-3.5-turbo-0125",
  "gpt-3.5-turbo-0613",
  "gpt-3.5-turbo-instruct",
  "gpt-3.5-turbo-16k-0613",
  "gpt-3.5-turbo-1106",
  "gpt-4-0613",
  "gpt-4-1106-preview",
  "gpt-4-0125-preview",
  "gpt-4-turbo-2024-04-09",
  "gpt-4o-2024-05-13",
  "gpt-4-vision-preview",
];
export const MODEL_CONTEXT_WINDOW = {
  "gpt-3.5-turbo-0125": 16385,
  "gpt-3.5-turbo": 16385,
  "gpt-3.5-turbo-1106": 16385,
  "gpt-3.5-turbo-0613": 4096,
  "gpt-3.5-turbo-instruct": 4096,
  "gpt-3.5-turbo-16k-0613": 16385,
  "gpt-4-0613": 8192,
  "gpt-4-1106-preview": 128000,
  "gpt-4-0125-preview": 128000,
  "gpt-4-turbo-2024-04-09": 128000,
  "gpt-4o-2024-05-13": 128000,
  "gpt-4-vision-preview": 128000,
}
export const VISION_MODELS = {
  VISION_PREVIEW: "gpt-4-vision-preview",
  VISION_4O: "gpt-4o-2024-05-13"
}
export const SEGMENTS = {
  Maira: 'Maira',
  PERSONALIZER: 'Personalizer',
  CV: 'CV'
};

export const SEGMENTS_TAB_STORAGE_KEYS = {Maira : "savedMairaTab", PERSONALIZER : "savedPersonalizerTab",CV: "savedCVTab" }

export const GPT_SETUP_STEPS = ["gpt_profile", "dataset", "training"];

export const MAIRA_TABS = ["gptDatasets", "gptProfiles", "gptSetting"]
export const PERSONALIZER_TABS = ["dataUpdate"]
export const COMMON_TABS = ["settings", "insights", " details"]
export const PROMPT_TEMPLATE_SEPARATORS_TOKEN_COUNT = 100