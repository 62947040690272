<template>
  <!-- <div> -->
  <v-container>
    <v-row>
      <v-col class="pa-0" xs="12" md="12">
        <v-card class="mx-auto px-2" outlined>
          <v-row>
            <v-col xs="12" md="6">
              <div class="px-2 primary--text font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_profile.profiles") }}
              </div>
            </v-col>
            <v-col xs="12" md="6">
              <template>
                <v-btn
                  class="float-right"
                  color="primary"
                  dark
                  @click="openCreateProfileModal = true"
                >
                {{$vuetify.lang.t('$vuetify.gpt_profile.create_profile')}}
                  <v-icon right class="ml-2">add_circle_outline</v-icon>
                </v-btn>
              </template>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <!-- <v-col cols="12" xs="12" md="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$vuetify.lang.t('$vuetify.search_btn_txt')"
                outlined
                dense
                single-line
                hide-details
              ></v-text-field>
            </v-col> -->
          </v-row>
          <v-divider></v-divider>
          <Loader v-if="isLoading" class="mr-2 mt-6" />
          <v-data-table
            v-else
            :headers="headers"
            :items="gptProfiles.profiles"
            :search="search"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
            <template v-slot:[`item.id`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{
                    item.id.split("-")[0]
                  }}</span>
                </template>
                <span>{{ item.id }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <div>
                <v-btn
                  text
                  class="primary--text text--darken-3"
                  @click="onDetailsClick(item)"
                  >{{ item.name }}</v-btn
                >
              </div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{
                item.created_at
                  ? new Date(item.created_at).toLocaleDateString("en-us", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                  : "N/A"
              }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <ButtonGroup
                :groupType="ACTION_BUTTON_GROUP"
                :addButton="false"
                :stopButton="false"
                :startButton="false"
                @onDeleteClick="deleteItem(item)"
                @onEditClick="onEditClick(item)"
                @onDetailsClick="onDetailsClick(item)"
              />
            </template>
          </v-data-table>
          <v-row>
            <v-col class="py-0" cols="12" md="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" xs="12" sm="3" md="3"></v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <v-pagination
                class="text-center"
                v-model="page"
                :length="pageCount"
              ></v-pagination>
            </v-col>
            <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
              <v-select
                v-model="itemsPerPage"
                :items="rows"
                hide-details="auto"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <DialogModal
      v-model="deleteDialog"
      :title="$vuetify.lang.t('$vuetify.confirm_alert_title_txt')"
      :message="$vuetify.lang.t('$vuetify.confirm_alert_msg_txt_6')"
      :confirmButtonText="$vuetify.lang.t('$vuetify.confirm_btn_txt')"
      :cancelButtonText="$vuetify.lang.t('$vuetify.cancel_btn_txt')"
      @onConfirmation="deleteProfile()"
      @onCancel="deleteDialog = false"
    />
    <!-- create profile -->
    <v-dialog persistent v-model="openCreateProfileModal" max-width="100%">
      <v-card
        class="d-flex flex-column"
        style="overflow-y: scroll; overflow-x: hidden"
      >
        <Loader v-if="profileEdit && profileLoading" class="mr-2 mt-6" />
        <v-container v-else>
          <v-row>
            <v-card-title class="font-weight-bold">
              {{ profileEdit ? $vuetify.lang.t('$vuetify.gpt_profile.update_profile') : $vuetify.lang.t('$vuetify.gpt_profile.create_profile') }}
            </v-card-title>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <CreateGptProfile ref="profile" />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <v-card-actions class="justify-end">
                <v-btn
                  color="error"
                  outlined
                  text
                  @click="closeProfileModal()"
                  >{{$vuetify.lang.t('$vuetify.common.close')}}</v-btn
                >
                <v-btn
                  color="primary"
                  dark
                  @click="profileEdit ? updateProfile() : createProfile()"
                >
                  {{ profileEdit ? $vuetify.lang.t('$vuetify.project.update') : $vuetify.lang.t('$vuetify.create_action_txt') }}
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- view profile -->
    <v-dialog persistent v-model="openProfileViewModal" max-width="100%">
      <v-card
        outlined
        class="d-flex flex-column"
        style="overflow-y: scroll; overflow-x: hidden"
      >
        <Loader v-if="viewProfile && profileLoading" class="mr-2 mt-6" />
      
        <v-container v-else>
          <v-row>
            <v-card-title class="font-weight-bold">
              Profile Information
            </v-card-title>
          </v-row>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.name_header_txt") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.profileName }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_profile.introduction") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.intro }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history_system") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.system }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history_model") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.model }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history_temperature") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.temperature }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_profile.top_p") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.top_p }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_profile.search_max_token") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.searchMaxToken}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history_completion_tokens") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.completionToken }}
              </v-list-item-content>
            </v-list-item>      
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history_frequency_penalty") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.frequencyPenalty }}
              </v-list-item-content>
            </v-list-item>

            <p v-if="selectedGptProfile.model === 'gpt-4-vision-preview'">
              {{ $vuetify.lang.t("$vuetify.gpt_history_vision_settings") }}:
            </p>
            <v-list-item
              v-if="selectedGptProfile.model === 'gpt-4-vision-preview'"
            >
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history_resolution") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{
                  selectedGptProfile.visionSettings &&
                  selectedGptProfile.visionSettings.resolution
                }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="selectedGptProfile.model === 'gpt-4-vision-preview'"
            >
              <v-list-item-content class="font-weight-bold">
                {{
                  $vuetify.lang.t("$vuetify.gpt_history_image_context_enabled")
                }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{
                  selectedGptProfile.visionSettings &&
                  selectedGptProfile.visionSettings.imageContextEnabled
                }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <v-card-actions class="justify-end">
                <v-btn
                  color="error"
                  outlined
                  text
                  @click="closeProfileDetailModal()"
                  >{{$vuetify.lang.t('$vuetify.common.close')}}</v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
  <!-- </div> -->
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  SEGMENTS, GPT_MODELS
} from '@/global/_constants';
import {
  GET_GPT_PROFILES,
  DELETE_GPT_PROFILE,
  UPDATE_GPT_PROFILE_STATE,
  CREATE_GPT_PROFILE,
  ADD_ALERT,
  GET_GPT_PROFILE_BY_ID,
  UPDATE_GPT_PROFILE_API,
  GET_GPT_SETTINGS,
  CREATE_GPT_SETTINGS,
} from "@/store/_actiontypes";
import Loader from "@/components/common/loader/Loader";
import { ACTION_BUTTON_GROUP } from "@/components/common/buttongroup/_buttongrouptypes";
import ButtonGroup from "@/components/common/buttongroup/ButtonGroup";
import DialogModal from "@/components/common/DialogModal";
import CreateGptProfile from "@/components/Projects/CreateGptProfile/CreateGptProfile";
import { GptProfileMapper } from "@/helpers/dataMappers/gptProfile";

export default {
  mounted() {
    this.getGPTProfiles();
  },
  computed: {
    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
      gptProfiles: (state) => state.project.gptProfiles,
      selectedGptProfile: (state) => state.project.selectedGptProfile,
      pageCount(state) {
        if (state.project.gptProfiles.total_hits) {
          const count = Math.ceil(
            state.project.gptProfiles.total_hits / this.itemsPerPage
          );
          return count;
        } else return 1;
      },
    }),
  },
  components: { Loader, ButtonGroup, DialogModal, CreateGptProfile },
  props: {
    tabName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      PROJECT_SEGMENTS: SEGMENTS,
      profileEdit: false,
      isLoading: true,
      openProfileViewModal: false,
      viewProfile: false,
      search: "",
      profileLoading: false,
      itemsPerPage: 10,
      page: 1,
      deleteDialog: false,
      toBeDeletedProfileId: null,
      ACTION_BUTTON_GROUP: ACTION_BUTTON_GROUP,
      rows: [5, 10, 20],
      openCreateProfileModal: false,
      headers: [
        {
          text: "Id",
          align: "center",
          value: "id",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.name_header_txt"),
          align: "center",
          value: "name",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history_model"),
          value: "model",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.created_at_txt"),
          value: "created_at",
          align: "center",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.actions_header_txt"),
          value: "actions",
          sortable: false,
          align: "start",
        },
      ],
    };
  },
  watch: {
    page: {
      handler() {
        const start = (this.page - 1) * this.itemsPerPage;
        const size = this.itemsPerPage;
        this.getGPTProfiles(start, size);
      },
      immediate: false,
    },
    itemsPerPage: {
      handler(newVal) {
        if (this.page === 1) {
          const start = 0;
          const size = newVal;
          this.getGPTProfiles(start, size);
        } else {
          this.page = 1;
        }
      },
      immediate: false,
    },
    tabName: {
      immediate: true,
     
      handler(newVal) {
        if (newVal === "gptProfiles") {
          this.getGPTProfiles();
        }
      },
    },
  },
  methods: {
    ...mapActions("project", [
      GET_GPT_PROFILES,
      DELETE_GPT_PROFILE,
      UPDATE_GPT_PROFILE_STATE,
      CREATE_GPT_PROFILE,
      GET_GPT_PROFILE_BY_ID,
      UPDATE_GPT_PROFILE_API,
      GET_GPT_SETTINGS,
      CREATE_GPT_SETTINGS,
    ]),

    closeProfileModal() {
      // this.$refs.profile.resetValidation()
      this.openCreateProfileModal = false;
      this.profileEdit = false;
      this.UPDATE_GPT_PROFILE_STATE({
        profile: GptProfileMapper.mapBackendToFrontend({
          name: "",
          intro: "",
          system: "",
          model: "",
          modelList: GPT_MODELS,
          temperature: 0,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
          stop: [],
          search_max_token: 2500,
          completion_token: 2000,
          vision_settings: {
            resolution: "low",
            is_image_context_enabled: true,
          },
          id: undefined,
        }),
      });
    },
    async getGPTProfiles(start = 0, size = 10) {
      this.isLoading = true;
      this.profileLoading = true;

      return this.GET_GPT_PROFILES({
        project_id: this.$route.params.project_id,
        start,
        size,
      }).then(
        (res) => {
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          console.log("err", err.response);
        }
      );
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.toBeDeletedProfileId = item.id;
    },
    closeProfileDetailModal() {
      this.viewProfile = false;
      this.openProfileViewModal = false;
    },

    onEditClick(item) {
      this.profileEdit = true;
      this.profileLoading = true;
      this.openCreateProfileModal = true;
      this.GET_GPT_PROFILE_BY_ID({
        project_id: this.$route.params.project_id,
        profile_id: item.id,
      })
        .then((res) => {
          this.profileLoading = false;
        })
        .catch((e) => {
          this.profileLoading = false;
        });
    },
    onDetailsClick(item) {
      this.openProfileViewModal = true;
      this.profileLoading = true;
      this.viewProfile = true;
      this.GET_GPT_PROFILE_BY_ID({
        project_id: this.$route.params.project_id,
        profile_id: item.id,
      })
        .then((res) => {
          this.profileLoading = false;
          this.viewProfile = false;
        })
        .catch((e) => {
          this.viewProfile = false;
          this.profileLoading = false;
        });
    },

    deleteProfile() {
      this.deleteDialog = false;
      this.DELETE_GPT_PROFILE({
        project_id: this.selectedProject.id,
        profile_id: this.toBeDeletedProfileId,
      })
        .then((res) => {
          this.isLoading = true;
          setTimeout(async () => {
            await this.getGPTProfiles();
            this.isLoading = false;
          }, 2000);
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    createProfile() {
      const valid = this.$refs.profile.validateForm();
      if (!valid) return;
      this.CREATE_GPT_PROFILE({
        project_id: this.$route.params.project_id,
        profile: this.selectedGptProfile,
      }).then(
        (res) => {
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message: this.$vuetify.lang.t(
                "$vuetify.gpt_profile.profile_creation_success"
              ),
              color: "success",
            },
            { root: true }
          );
          // set default profile when creating 1st profile from Personalizer
          if(this.selectedProject.segment.name === this.PROJECT_SEGMENTS.PERSONALIZER){
            this.GET_GPT_SETTINGS({
            project_id: this.$route.params.project_id,
          }).then((res2) => {
            if (!res2.data?.detail?.settings) {
              this.CREATE_GPT_SETTINGS({
                project_id: this.$route.params.project_id,
                default_profile: res.data?.detail?.profile_id,
              })
                .then((_res) => { })
                .catch((_err) => {
                });
            }
          });
          }
          this.isLoading = true;
          this.closeProfileModal();
          setTimeout(async () => {
            await this.getGPTProfiles();
            this.isLoading = false;
          }, 2000);
        },
        (err) => {
          console.log("Err", err.response);
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message:
                err.response?.data?.detail?.response ||
                this.$vuetify.lang.t(
                  "$vuetify.gpt_profile.profile_creation_error"
                ),
              color: "error",
            },
            { root: true }
          );
        }
      );
    },
    updateProfile() {
      this.UPDATE_GPT_PROFILE_API({
        project_id: this.$route.params.project_id,
        profile: this.selectedGptProfile,
      }).then(
        (res) => {
          this.isLoading = true;
          this.closeProfileModal();
          setTimeout(async () => {
            await this.getGPTProfiles();
            this.isLoading = false;
          }, 2000);
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message: this.$vuetify.lang.t(
                "$vuetify.gpt_profile.profile_update_success"
              ),
              color: "success",
            },
            { root: true }
          );
        },
        (err) => {
          this.closeProfileModal();
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message: this.$vuetify.lang.t(
                "$vuetify.gpt_profile.profile_update_error"
              ),
              color: "error",
            },
            { root: true }
          );
        }
      );
    },
  },
};
</script>
