import Api from "@/services/api";
import {
    LOAD_ALL_PROJECT,
    LOAD_PROJECT_BY_ID,
    SAVE_PROJECT,
    UPDATE_PROJECT,
    UPGRADE_SUBSCRIPTION,
    START_PROJECT,
    STOP_PROJECT,
    TERMINATE_PROJECT,
    STATE_UPDATE_PROJECT,
    LOAD_PROJECT_COST,
    LOAD_ALL_CREDENTIAL,
    SAVE_CREDENTIAL,
    UPDATE_CREDENTIAL,
    SETUP_PROJECT,
    GET_RANK_SETTINGS,
    GET_MAPPER,
    CREATE_MAPPER,
    UPDATE_MAPPER,
    CREATE_INDICES,
    CREATE_RANK,
    UPDATE_RANK_SETTINGS,
    UPLOAD_RECOMMENDER_DATA,
    ITEMS_TRAIN,
    IMAGES_TRAIN,
    RANKS_TRAIN,
    GET_SETUP_STATE,
    UPDATE_SETUP_STATE,
    RECOMMENDER_TASK,
    GET_TASKS_STATUS,
    UPDATE_TASKS_STATUS,
    LOAD_TRAIN_TASKS,
    GET_PREVIOUSLY_SETUP_STATE,
    LOAD_PROJECT_COUNTS,
    GET_GPT_PROFILES,
    GET_GPT_PROFILE_BY_ID,
    CREATE_GPT_PROFILE,
    UPDATE_GPT_PROFILE_STATE,
    UPDATE_GPT_PROFILE_API,
    GET_GPT_DATASETS,
    GET_GPT_DATASETS_SUMMARY,
    GET_GPT_DATASET_BY_ID,
    CREATE_GPT_DATASET,
    TERMINATE_GPT_DATASET,
    UPDATE_GPT_DATASET_API,
    UPDATE_GPT_DATASET_STATE,
    TRAIN_GPT_DATASET,
    CREATE_GPT_SETTINGS,
    UPDATE_GPT_SETUP_STATE,
    GET_ALL_TASKS,
    GET_TASK_STATUS,
    GET_GPT_SETTINGS,
    DELETE_GPT_PROFILE,
    DELETE_GPT_SETTINGS,
    UPDATE_GPT_SETTINGS

} from "@/store/_actiontypes";
import {
    SET_ALL_PROJECT,
    SET_PROJECT_BY_ID,
    SET_PROJECT_COST,
    SET_ALL_CREDENTIAL,
    SET_PROJECT_COUNTS,
    SET_TRAIN_TASKS,
    SET_PREVIOUSLY_SETUP_STATE,
    SET_GPT_PROFILES,
    SET_GPT_PROFILE,
    SET_GPT_DATASETS,
    SET_GPT_DATASET,
    SET_GPT_DATASET_SUMMARY,
    SET_GPT_SETUP_STATE,
} from "@/store/_mutationtypes";
import { GptProfileMapper } from "@/helpers/dataMappers/gptProfile";
import { GptDatasetMapper } from "@/helpers/dataMappers/gptDataset";
import { updateLoaderTo } from "./account";
import { GPT_MODELS } from "@/global/_constants";
export const gptProfileInitialData = GptProfileMapper.mapBackendToFrontend({
  name: "",
  intro: "",
  system: "",
  model: "",
  modelList: GPT_MODELS,
  temperature: 0,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
  stop: [],
  search_max_token: 2500,
  completion_token: 2000,
  vision_settings: {
    resolution: "low",
    is_image_context_enabled: true,
  },
  id: undefined,
});

export const gptDatasetInitialData = GptDatasetMapper.mapBackendToFrontend({
  dataset_id: "",
  description: "",
  idx_column_name: "",
  image_url_column: "",
  is_idx_fillup_if_empty: true,
  name: "",
  secondary_idx_column: "",
});

const state = {
  allProject: [],
  selectedProject: {},
  projectCost: {},
  allCredential: [],
  trainTasks: [],
  previouslySetupState: [],
  projectCounts: [],
  gptProfiles: {
    profiles: [],
    returned_hits: 0,
    total_hits: 0
  },
  selectedGptProfile: gptProfileInitialData,
  gptDatasets: {
    datasets: [],
    returned_hits: 0,
    total_hits: 0
  },
  selectedGptDataset: gptDatasetInitialData,
  initialGptDatasetTrainingState: {},
  gptSetupState: {
    completed: false,
    gpt_profile: { completed: false, profile_id: null },
    default_profile: { completed: false },
    dataset: {
      task_id: null,
      status: null,
      dataset_id: null,
      completed: false,
    },
    training: { task_id: null, status: null, completed: false },
  },
  gptDatasetSummary: {},
};

const actions = {
  [LOAD_ALL_PROJECT]({ commit }, { solution_type }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const apiUrl = solution_type
        ? `projects?solution_type=${solution_type}`
        : "projects";

      Api.get(apiUrl).then(
        (response) => {
          let allProject = response.data.projects;
          // allProject.forEach((element) => {
          // element.projects_solutions = element.solutions.filter(
          //   (obj) => {
          //     return obj.enable === true;
          //   }
          // );
          // });
          commit(SET_ALL_PROJECT, allProject);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [TRAIN_GPT_DATASET](
    { commit },
    { project_id, datasetId, trainType, batchSize }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const trainingDataset = {
        batch_size: batchSize,
        train_type: trainType,
      };
      Api.post(`${project_id}/gpt/datasets/${datasetId}/train`, {
        ...trainingDataset,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_GPT_PROFILE_BY_ID]({ commit }, { project_id, profile_id }) {
    updateLoaderTo(true);
    commit(SET_GPT_PROFILE, gptProfileInitialData);
    return new Promise((resolve, reject) => {
      Api.get(`${project_id}/gpt/profiles/${profile_id}`).then(
        (response) => {
          commit(
            SET_GPT_PROFILE,
            response.data?.detail?.response
              ? GptProfileMapper.mapBackendToFrontend(
                  response.data?.detail?.response
                )
              : gptProfileInitialData
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [CREATE_GPT_PROFILE]({ commit, state }, { project_id, profile }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const mappedGPTProfileData =
        GptProfileMapper.mapFrontendToBackend(profile);
      Api.post(`${project_id}/gpt/profiles`, { ...mappedGPTProfileData }).then(
        (response) => {
          commit(SET_GPT_PROFILE, {
            ...profile,
            id: response.data?.detail?.profile_id,
          });
          commit(SET_GPT_PROFILES, {
            profiles: [
              { ...profile, id: response.data?.detail?.profile_id },
              ...state.gptProfiles.profiles,
            ],
            returned_hits: state.gptProfiles.returned_hits + 1,
            total_hits: state.gptProfiles.total_hits + 1,
          });
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  //creates default profile
  [CREATE_GPT_SETTINGS](
    { commit },
    { project_id, default_profile, default_vision_profile, bot_mode_strict }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`${project_id}/gpt/settings`, {
        default_profile,
        default_vision_profile,
        bot_mode_strict,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_GPT_SETTINGS]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`${project_id}/gpt/settings`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_GPT_SETTINGS]({ commit }, { project_id, gptSetting }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
        Api.put(`${project_id}/gpt/settings`, {...gptSetting}).then(
            (response) => {
                updateLoaderTo(false);
                resolve(response);
            },
            (error) => {
                updateLoaderTo(false);
                reject(error);
            }
        );
    });
},
[CREATE_GPT_SETTINGS]({ commit }, { project_id, default_profile, default_vision_profile, bot_mode_strict }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
        Api.post(`${project_id}/gpt/settings`, {default_profile, default_vision_profile, bot_mode_strict}).then(
            (response) => {
                updateLoaderTo(false);
                resolve(response);
            },
            (error) => {
                updateLoaderTo(false);
                reject(error);
            }
        );
    });
},
[GET_GPT_SETTINGS]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
        Api.get(`${project_id}/gpt/settings`).then(
            (response) => {

                updateLoaderTo(false);
                resolve(response);
            },
            (error) => {
                updateLoaderTo(false);
                reject(error);
            }
        );
    });
},
[DELETE_GPT_SETTINGS]({ commit }, {project_id, fields = [] }) {
  updateLoaderTo(true);
  return new Promise((resolve, reject) => {
    Api.delete(`${project_id}/gpt/settings`,{
      headers: {
        'Content-Type': 'application/json'
      },
      data: {fields}
    }).then(
      (response) => {
        updateLoaderTo(false);
        resolve(response);
      },
      (error) => {
        updateLoaderTo(false);
        reject(error);
      }
    );
  });
},

  [DELETE_GPT_PROFILE]({ commit, state }, { project_id, profile_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
        Api.delete(`${project_id}/gpt/profiles/${profile_id}`).then(
        (response) => {
            const updatedGptProfiles = state.gptProfiles.profiles.filter(item => item.id != profile_id)
            commit(SET_GPT_PROFILES, {...state.gptProfiles, profiles: updatedGptProfiles})
            updateLoaderTo(false);
            resolve(response);
        },
        (error) => {
            updateLoaderTo(false);
            reject(error);
        }
      );
    });
  },
  [UPDATE_GPT_PROFILE_API]({ commit }, { project_id, profile }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const mappedGPTProfileData =
        GptProfileMapper.mapFrontendToBackend(profile);
      delete mappedGPTProfileData.id;
      Api.put(`${project_id}/gpt/profiles/${profile.id}`, {
        ...mappedGPTProfileData,
      }).then(
        (response) => {
          // commit(SET_GPT_PROFILE, profile_data);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_GPT_PROFILES]({ commit }, { project_id, start, size }) {
    updateLoaderTo(true);
    let uri = `${project_id}/gpt/profiles`;
    if (size) {
      uri = `${uri}?start=${start}&size=${size}`;
    }
    
    commit(SET_GPT_PROFILES, []);
    return new Promise((resolve, reject) => {
      Api.get(uri).then(
        (response) => {
          commit(SET_GPT_PROFILES, response.data?.detail?.response);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_TASK_STATUS]({ commit }, { project_id, task_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`${project_id}/gpt/task/${task_id}`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_GPT_PROFILE_STATE]({ commit }, { profile }) {
    commit(SET_GPT_PROFILE, profile);
  },
  [UPDATE_GPT_DATASET_STATE]({ commit }, { dataset }) {
    commit(SET_GPT_DATASET, dataset);
  },
  [UPDATE_GPT_SETUP_STATE]({ commit }, value) {
    commit(SET_GPT_SETUP_STATE, value);
  },
  [GET_GPT_DATASETS]({ commit }, { project_id,  start, size }) {
    updateLoaderTo(true);
    commit(SET_GPT_DATASETS, []);
    if(!start) start = 0;
    if(!size) size = 10;
    return new Promise((resolve, reject) => {
      Api.get(`${project_id}/gpt/datasets?start=${start}&size=${size}`).then(
        (response) => {
          commit(SET_GPT_DATASETS, response.data);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_GPT_DATASETS_SUMMARY]({ commit }, { project_id, dataset_id }) {
    updateLoaderTo(true);
    commit(SET_GPT_DATASET_SUMMARY, {});
    return new Promise((resolve, reject) => {
      Api.get(`${project_id}/gpt/datasets/${dataset_id}`).then(
        (response) => {
          commit(SET_GPT_DATASET_SUMMARY, response.data?.response.dataset);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_ALL_TASKS](
    { commit },
    { project_id, task_type, task_status, start = 0, size = 10 }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(
        `${project_id}/gpt/task?task_type=${task_type}&task_status=${task_status}&start=${start}&size=${size}`
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_GPT_DATASET_BY_ID]({ commit }, { project_id, dataset_id }) {
    updateLoaderTo(true);
    commit(SET_GPT_DATASET, gptDatasetInitialData);
    return new Promise((resolve, reject) => {
      Api.get(`${project_id}/gpt/datasets/${dataset_id}`).then(
        (response) => {
          commit(
            SET_GPT_DATASET,
            GptDatasetMapper.mapBackendToFrontend(
              response.data?.response.dataset
            )
          );
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [CREATE_GPT_DATASET]({ commit }, { project_id, dataset }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const clonedDataset = { ...dataset };
      delete clonedDataset.datasetId;
      delete clonedDataset.datasetFile;
      const mappedGPTDatasetDataFormData =
        GptDatasetMapper.mapFrontendToBackend(clonedDataset);
      Api.post(`${project_id}/gpt/datasets`, mappedGPTDatasetDataFormData).then(
        (response) => {
          commit(SET_GPT_DATASETS, [
            { ...dataset, dataset_id: response.data?.detail?.dataset_id },
          ]);
          commit(SET_GPT_DATASET, {
            ...dataset,
            datasetId: response.data?.detail?.dataset_id,
          });
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [TERMINATE_GPT_DATASET]({ commit }, { project_id, dataset_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.delete(`${project_id}/gpt/datasets/${dataset_id}`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_GPT_DATASET_API]({ commit }, { project_id, dataset }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      const clonedDataset = { ...dataset };
      delete clonedDataset.datasetId;
      const mappedGPTDatasetDataFormData =
        GptDatasetMapper.mapFrontendToBackend(clonedDataset, true);
      Api.put(
        `${project_id}/gpt/datasets/${dataset.datasetId}`,
        mappedGPTDatasetDataFormData
      ).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },

  [LOAD_PROJECT_BY_ID]({ commit }, { project_id }) {
    updateLoaderTo(true);
    commit(SET_PROJECT_BY_ID, {});
    return new Promise((resolve, reject) => {
      Api.get(`projects/${project_id}`).then(
        (response) => {
          commit(SET_PROJECT_BY_ID, response.data);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_PROJECT_COST](
    { commit },
    { solutionIDList, monthlyPV, monthlyUU, itemCount, subscriptionType }
  ) {
    updateLoaderTo(true);
    commit(SET_PROJECT_COST, {});
    return new Promise((resolve, reject) => {
      Api.post(`projects/cost`, {
        solution_ids: solutionIDList,
        monthly_pv: monthlyPV,
        monthly_uu: monthlyUU,
        item_count: itemCount,
        subscription_type: subscriptionType,
      }).then(
        (response) => {
          let projectCost = response.data;
          commit(SET_PROJECT_COST, projectCost);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [SAVE_PROJECT](
    { commit },
    {
      name,
      solutionIDList,
      language,
      segmentID,
      industryID,
      solutionTemplateID,
      monthlyPV,
      expectedRPS,
      monthlyUU,
      itemCount,
      autoScaling,
      regionID,
      gpt_purpose,
      gpt_randomness,
      // subscriptionType
    }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`projects`, {
        name: name,
        solution_ids: solutionIDList,
        language: language,
        segment_id: segmentID,
        industry_id: industryID,
        solution_template_id: solutionTemplateID,
        monthly_pv: monthlyPV,
        expected_rps: expectedRPS,
        monthly_uu: monthlyUU,
        item_count: itemCount,
        auto_scaling: autoScaling,
        region_id: regionID,
        // subscription_type: subscriptionType
        gpt_purpose: gpt_purpose,
        gpt_randomness: gpt_randomness,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_PROJECT](
    { commit },
    {
      project_id,
      name,
      solutionIDList,
      monthlyPV,
      expectedRPS,
      monthlyUU,
      itemCount,
      autoScaling,
      regionID,
      subscriptionType,
      gpt_purpose,
      gpt_randomness,
    }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`projects/${project_id}`, {
        name: name,
        solution_ids: solutionIDList,
        monthly_pv: monthlyPV,
        expected_rps: expectedRPS,
        monthly_uu: monthlyUU,
        item_count: itemCount,
        auto_scaling: autoScaling,
        region_id: regionID,
        subscription_type: subscriptionType,
        gpt_purpose: gpt_purpose,
        gpt_randomness: gpt_randomness,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [START_PROJECT]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`projects/${project_id}/start`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPGRADE_SUBSCRIPTION]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`projects/${project_id}/upgrade/subscription`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [STOP_PROJECT]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`projects/${project_id}/stop`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [TERMINATE_PROJECT]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`projects/${project_id}/terminate`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [STATE_UPDATE_PROJECT]({ commit }, { project_id, state }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`projects/${project_id}`, {
        state: state,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_ALL_CREDENTIAL]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      commit(SET_ALL_CREDENTIAL, []);
      Api.get(`projects/${project_id}/credentials`).then(
        (response) => {
          let allCredential = response.data.credentials;

          // Add the "securityLevels" key to each object
          allCredential.forEach(credential => {
            const { key_type, key_metadata } = credential;
            if(key_type === 'client'){
              if(credential.key_metadata.whitelists.length === 0 || key_metadata.whitelists.includes('http://localhost')){
                credential["securityLevels"] = "High Risk";
              }
              else {
                credential["securityLevels"] = 'No Risk';
              }
            }else{ //key_type === 'project'
              if(key_metadata.whitelists.length === 0){
                credential["securityLevels"] = "Medium";
              }
              else if(key_metadata.whitelists.includes('http://localhost')){
                credential["securityLevels"] = "High Risk";
              }
              else {
                credential["securityLevels"] = 'No Risk';
              }
            }
          });
          commit(SET_ALL_CREDENTIAL, allCredential);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [SAVE_CREDENTIAL]({ commit }, { project_id, name, type, whitelisted }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`projects/${project_id}/credentials`, {
        name: name,
        key_type: type,
        key_metadata: whitelisted,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_CREDENTIAL](
    { commit },
    { project_id, credential_id, name, type, whitelisted, status }
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`projects/${project_id}/credentials/${credential_id}`, {
        name: name,
        key_type: type,
        key_metadata: whitelisted,
        status: status,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [SETUP_PROJECT](
    { commit },
    { project_id, mapper_body, rank_settings, file }
  ) {
    let key_map = mapper_body.key_map;

    updateLoaderTo(true);

    let formData = new FormData();
    formData.append("mapper_body", JSON.stringify(mapper_body));
    formData.append("rank_settings", JSON.stringify(rank_settings));
    formData.append("file", file);

    updateLoaderTo(true);

    return new Promise((resolve, reject) => {
      Api.post(`recommender/${project_id}/start-setup`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_RANK_SETTINGS]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`recommender/${project_id}/ranks/settings`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_MAPPER]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`recommender/${project_id}/mappers`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [CREATE_MAPPER]({ commit }, { project_id, mapper_body }) {
    let key_map = mapper_body.key_map;
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`recommender/${project_id}/mappers`, {
        key_map,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_MAPPER]({ commit }, { project_id, mapper_body }) {
    let key_map = mapper_body.key_map;
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`recommender/${project_id}/mappers`, {
        key_map,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [CREATE_INDICES]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`recommender/${project_id}/indices`).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [CREATE_RANK]({ commit }, { project_id, settings }) {
    let rank_settings = settings.rank_settings;
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(`recommender/${project_id}/ranks/settings`, {
        rank_settings,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPDATE_RANK_SETTINGS]({ commit }, { project_id, settings }) {
    let rank_settings = settings.rank_settings;
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.put(`recommender/${project_id}/ranks/settings`, {
        rank_settings,
      }).then(
        (response) => {
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [UPLOAD_RECOMMENDER_DATA]({ commit }, { project_id, file }) {
    updateLoaderTo(true);

        return new Promise((resolve, reject) => {
            Api.post(`recommender/${project_id}/items/save`, file, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [ITEMS_TRAIN]({ commit }, { project_id, train_all, train_from, train_to }) {
        updateLoaderTo(true);
        let uri = `recommender/${project_id}/items/train`;
        if (train_from && train_to) {
            uri = uri + `?train_from=${train_from}T00:00:00&train_to=${train_to}T00:00:00`
        }
        else {
            uri = uri + `?train_all=${train_all}`
        }
        
        return new Promise((resolve, reject) => {
            Api.get(uri).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [IMAGES_TRAIN]({ commit }, { project_id, date_range }) {
        updateLoaderTo(true);
        let dateRange = date_range;
        return new Promise((resolve, reject) => {
            Api.post(`recommender/${project_id}/images/train`, {
                dateRange,
            }).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [RANKS_TRAIN]({ commit }, { project_id }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            Api.post(`recommender/${project_id}/ranks/train`).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [GET_SETUP_STATE]({ commit }, { project_id }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            Api.get(`projects/${project_id}/setup-state`).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [UPDATE_SETUP_STATE]({ commit }, { project_id, setup_state }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            Api.put(`projects/${project_id}/setup-state`, {
                setup_state,
            }).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [GET_TASKS_STATUS]({ commit }, { project_id }) {
        return new Promise((resolve, reject) => {
            Api.get(`projects/${project_id}/tasks-status`).then(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    [UPDATE_TASKS_STATUS]({ commit }, { project_id, tasks_status }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            Api.put(`projects/${project_id}/tasks-status`, {
                tasks_status,
            }).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [RECOMMENDER_TASK]({ commit }, { project_id, setup_state }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            Api.put(`projects/${project_id}/setup-state`, {
                setup_state,
            }).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
    [LOAD_TRAIN_TASKS]({ commit }, { project_id, train_type, status, start, size }) {
        if (!train_type) {
            train_type = "all_tasks";
        }

    if (!status) {
      status = "all_status";
    }
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      commit(SET_TRAIN_TASKS, []);
      Api.get(`recommender/${project_id}/train-tasks`, {
        params: {
          train_type,
          status,
          start,
          size,
        },
      }).then(
        (response) => {
          let trainTasks = response.data.detail.response;
          commit(SET_TRAIN_TASKS, trainTasks);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [GET_PREVIOUSLY_SETUP_STATE]({ commit }, { project_id }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      commit(SET_TRAIN_TASKS, []);
      Api.get(`recommender/${project_id}/setup-steps`).then(
        (response) => {
          let previouslySetupState = response.data;
          commit(SET_PREVIOUSLY_SETUP_STATE, previouslySetupState);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
  [LOAD_PROJECT_COUNTS]({ commit }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      commit(SET_PROJECT_COUNTS, []);
      Api.get(`projects/count`).then(
        (response) => {
          let projectCounts = response.data.counts;
          commit(SET_PROJECT_COUNTS, projectCounts);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
};

const mutations = {
  [SET_ALL_PROJECT](state, allProject) {
    state.allProject = allProject;
  },
  [SET_PROJECT_BY_ID](state, selectedProject) {
    state.selectedProject = selectedProject;
  },
  [SET_GPT_PROFILES](state, gptProfiles) {
    state.gptProfiles = gptProfiles;
  },
  [SET_GPT_DATASETS](state, gptDatasets) {
    state.gptDatasets = gptDatasets;
  },
  [SET_GPT_DATASET](state, selectedGptDataset) {
    state.selectedGptDataset = selectedGptDataset;
  },
  [SET_GPT_DATASET_SUMMARY](state, gptDatasetSummary) {
    state.gptDatasetSummary = gptDatasetSummary
  },
  [SET_GPT_PROFILE](state, selectedGptProfile) {
    state.selectedGptProfile = selectedGptProfile;
  },
  [SET_GPT_SETUP_STATE](state, value) {
    state.gptSetupState = value;
  },
  // [UPDATE_GPT_PROFILE](state, {profile}) {
  //     state.selectedGptProfile = profile;
  // },
  [SET_PROJECT_COST](state, projectCost) {
    state.projectCost = projectCost;
  },
  [SET_ALL_CREDENTIAL](state, allCredential) {
    state.allCredential = allCredential;
  },
  [SET_TRAIN_TASKS](state, trainTasks) {
    state.trainTasks = trainTasks;
  },
  [SET_PREVIOUSLY_SETUP_STATE](state, previouslySetupState) {
    state.previouslySetupState = previouslySetupState;
  },
  [SET_PROJECT_COUNTS](state, projectCounts) {
    state.projectCounts = projectCounts;
  },
};

export const project = {
  namespaced: true,
  state,
  actions,
  mutations,
};
