<template>
  <v-main>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col align-self="center" class="mx-5" sm="8" md="6" lg="4" xl="4">
          <v-alert type="info" v-if="!validLink">
            <span class="text-h6">{{ $vuetify.lang.t('$vuetify.verify_msg_txt') }}</span>
          </v-alert>
          <v-card v-if="validLink" outlined class="elevation-2 mx-auto pa-10">
            <Logo />
            <v-card-title class="font-weight-bold">
              {{ $vuetify.lang.t('$vuetify.reset_password_msg_txt_1') }}
            </v-card-title>
            <v-card-subtitle>
              {{ $vuetify.lang.t('$vuetify.reset_password_msg_txt_2') }}
            </v-card-subtitle>
            <v-card-text>
              <v-form ref="passwordForm">
                <v-text-field
                  v-model="passwordForm.password"
                  prepend-inner-icon="lock"
                  :placeholder="$vuetify.lang.t('$vuetify.password_label_txt')"
                  outlined
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[
                    required($vuetify.lang.t('$vuetify.password_label_txt')),
                    password($vuetify.lang.t('$vuetify.password_label_txt')),
                    passwordWithEmail($vuetify.lang.t('$vuetify.password_label_txt'), userInfo.email),
                    validatePasswordWithName(),
                  ]"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
                <v-text-field
                  v-model="passwordForm.repassword"
                  prepend-inner-icon="lock"
                  :placeholder="$vuetify.lang.t('$vuetify.enter_password_label_txt')"
                  outlined
                  :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showRePassword ? 'text' : 'password'"
                  :rules="[
                    required($vuetify.lang.t('$vuetify.enter_password_label_txt')),
                    match(passwordForm.password),
                  ]"
                  @click:append="showRePassword = !showRePassword"
                ></v-text-field>
                <v-btn
                  color="primary"
                  large
                  block
                  @click="handleSubmitClick()"
                  :loading="loading"
                >{{ $vuetify.lang.t('$vuetify.change_password_btn_txt') }}</v-btn>
              </v-form>
              <v-divider class="my-5"></v-divider>
              <router-link to="/login" class="text-decoration-none">
                {{ $vuetify.lang.t('$vuetify.login_link_txt') }}
              </router-link>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import getEnv from "@/config/env";
import { VERIFY_RESET_PASSWORD_LINK, RESET_PASSWORD } from "@/store/_actiontypes";
import validations from "@/helpers/validations";
import router from "@/router/index";
import Logo from "@/components/common/logo";

export default {
  components: {
    Logo,
  },
  data() {
    return {
      showPassword: false,
      showRePassword: false,
      passwordForm: {
        password: "",
        repassword: "",
      },
      validLink: false,
      ...validations,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
      userInfo: (state) => state.account.resetPasswordUserDetails
    }),
  },
  mounted(){
    if(getEnv("VUE_APP_CAPTCHA_ENABLED")){
      const plugin = document.createElement("script");
      plugin.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?render=${getEnv("VUE_APP_CAPTCHA_SITE_KEY")}`
      );
      plugin.async = true;
      document.head.appendChild(plugin);
    }
    this.verifyResetPasswordLink();
  },
  methods: {
    ...mapActions("account", [VERIFY_RESET_PASSWORD_LINK, RESET_PASSWORD]),
    verifyResetPasswordLink() {
      this.VERIFY_RESET_PASSWORD_LINK({
        token: this.$route.params.token
      }).then((res)=>{
        if(res.status===200){
          this.validLink = true;
        }
      },(error) => {
        router.push("/404");
      }).catch(err=>{
        router.push("/404");
      });
    },
    handleSubmitClick() {
      if (!this.$refs.passwordForm.validate()) return;
      if(getEnv("VUE_APP_CAPTCHA_ENABLED")){
        grecaptcha.execute(getEnv("VUE_APP_CAPTCHA_SITE_KEY"), {action: 'reset_password'}).then((recaptcha_token) => {
          this.handleSubmit(recaptcha_token);
        });
      }
      else{
        this.handleSubmit(null);
      }
    },
    handleSubmit(recaptcha_token) {
      const { password } = this.passwordForm;
      let token = this.$route.params.token;
      this.RESET_PASSWORD({ token, password, recaptcha_token });
    },
    validatePasswordWithName() {
      if (this.userInfo.name === null) {
        return true
      } else {
        return validations.passwordWithName(this.$vuetify.lang.t("$vuetify.password_label_txt"), this.userInfo.name, this.$vuetify.lang.t("$vuetify.common.name"))
      }
    }
  },
};
</script>

<style></style>
