import API_SERVICE from '@/services/recommender_api'
import {
    RECOMMENDER_ITEM_INDEX,
    RECOMMENDER_ITEM_SAVE,
    RECOMMENDER_ITEM_CREATE,
    RECOMMENDER_ITEM_PROCESS,
    RECOMMENDER_CREATE_GRAPH,
    RECOMMENDER_GET_COLUMNS,
    RECOMMENDER_UPLOAD_COLUMNS,
    RECOMMENDER_MAP_COLUMNS,
    RECOMMENDER_GET_MAP_COLUMNS,
    RECOMMENDER_UPLOAD_CSV_FILE,
    RECOMMENDER_GET_TASK_BY_ID,
    RECOMMENDER_GET_ALL_TASK,
} from '@/store/_actiontypes'
import {
    RECOMMENDER_SET_ALL_TASK,
    RECOMMENDER_SET_FILE_NAME,
    RECOMMENDER_SET_COLUMNS,
    RECOMMENDER_SET_ALL_COLUMN_KEY,
    RECOMMENDER_SET_SET_MAP_COLUMNS,
    RECOMMENDER_SET_ALL_RULE,
    RECOMMENDER_SET_ALL_RULE_CATEGORY
} from '@/store/_mutationtypes'


const state = {
    allColumnKey: [],
    mapColumn: {},
    getColumnName: [],
    allRule: {},
    allRuleCategory: [],
    fileName: "",
    allTask: [],
}

const actions = {
    [RECOMMENDER_UPLOAD_CSV_FILE]({ commit }, { formData }) {
        commit(RECOMMENDER_SET_FILE_NAME, {});
        return new Promise((resolve, reject) => {
            API_SERVICE.post(`/v1/api/item/upload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                  }
            })
            .then(response => {
                if (response.data.file_name){
                    let fileName = response.data.file_name;
                    commit(RECOMMENDER_SET_FILE_NAME, fileName);
                }
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    [RECOMMENDER_GET_ALL_TASK]({ commit }) {
        commit(RECOMMENDER_SET_ALL_TASK, {});
        return new Promise((resolve, reject) => {
            API_SERVICE.get(`/v1/api/tasks?task_type=graph_create`)
            .then(response => {
                if(response.data.task){
                    let allTask = response.data.task;
                    commit(RECOMMENDER_SET_ALL_TASK, allTask);
                }
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    [RECOMMENDER_GET_TASK_BY_ID]({ commit }, { task_id }) {
        return new Promise((resolve, reject) => {
            API_SERVICE.get(`/v1/api/task/${ task_id }`)
            .then(response => {
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    [RECOMMENDER_UPLOAD_COLUMNS]({ commit }, { columnsJSON }) {
        return new Promise((resolve, reject) => {
            API_SERVICE.post(`/v1/api/columns`, columnsJSON)
            .then(response => {
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    [RECOMMENDER_MAP_COLUMNS]({ commit }, { mapColumnJSON }) {
        return new Promise((resolve, reject) => {
            API_SERVICE.post(`/v1/api/mapper`, mapColumnJSON)
            .then(response => {
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    [RECOMMENDER_GET_MAP_COLUMNS]({ commit }) {
        commit(RECOMMENDER_SET_SET_MAP_COLUMNS, {});
        return new Promise((resolve, reject) => {
            API_SERVICE.get(`/v1/api/mapper`)
            .then(response => {
                if(response.data.map){
                    let mapColumn = response.data.map;
                    commit(RECOMMENDER_SET_SET_MAP_COLUMNS, mapColumn);
                }
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    [RECOMMENDER_GET_COLUMNS]({ commit }) {
       commit(RECOMMENDER_SET_COLUMNS, {});
        return new Promise((resolve, reject) => {
            API_SERVICE.get(`/v1/api/columns`)
            .then(response => {
                if(response.data.columns){
                    let getColumnName = response.data.columns;
                    commit(RECOMMENDER_SET_COLUMNS, getColumnName);
                }
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },

    [RECOMMENDER_ITEM_INDEX]({ commit }) {
        return new Promise((resolve, reject) => {
            API_SERVICE.post(`/v1/api/item/index`)
            .then(response => {
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    [RECOMMENDER_ITEM_SAVE]({ commit }, { itemSave }) {
        return new Promise((resolve, reject) => {
            API_SERVICE.post(`/v1/api/item/save`, itemSave )
            .then(response => {
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    [RECOMMENDER_ITEM_CREATE]({ commit }) {
        return new Promise((resolve, reject) => {
            API_SERVICE.post(`/v1/api/item/create`)
            .then(response => {
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    [RECOMMENDER_ITEM_PROCESS]({ commit }) {
        return new Promise((resolve, reject) => {
            API_SERVICE.post(`/v1/api/item/process`)
            .then(response => {
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
    [RECOMMENDER_CREATE_GRAPH]({ commit }) {
        return new Promise((resolve, reject) => {
            API_SERVICE.post(`/v1/api/graph/create`)
            .then(response => {
                resolve(response);
            }, error => {
                reject(error);
            })
        })
    },
}

const mutations = {
    [RECOMMENDER_SET_ALL_COLUMN_KEY](state, allColumnKey) {
        state.allColumnKey = allColumnKey;
    },
    [RECOMMENDER_SET_SET_MAP_COLUMNS](state, mapColumn) {
        state.mapColumn = mapColumn;
    },
    [RECOMMENDER_SET_FILE_NAME](state, fileName) {
        state.fileName = fileName;
    },
    [RECOMMENDER_SET_COLUMNS](state, getColumnName) {
        state.getColumnName = getColumnName;
    },
    [RECOMMENDER_SET_ALL_TASK](state, allTask) {
        state.allTask = allTask;
    },
    [RECOMMENDER_SET_ALL_RULE](state, allRule) {
        state.allRule = allRule;
    },
    [RECOMMENDER_SET_ALL_RULE_CATEGORY](state, allRuleCategory) {
        state.allRuleCategory = allRuleCategory;
    }
}

export const recommender = {
    namespaced: true,
    state,
    actions,
    mutations
};
