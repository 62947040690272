import { render, staticRenderFns } from "./RanksTrain.vue?vue&type=template&id=06d5f866&scoped=true"
import script from "./RanksTrain.vue?vue&type=script&lang=js"
export * from "./RanksTrain.vue?vue&type=script&lang=js"
import style0 from "./RanksTrain.vue?vue&type=style&index=0&id=06d5f866&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d5f866",
  null
  
)

export default component.exports