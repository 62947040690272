<template>
    <div>
        <v-row>
            <v-col cols="12" md="2" class="pl-12 align-self-center">
                <v-card class="pa-2" flat>
                    <strong>{{ `${$vuetify.lang.t("$vuetify.project.items_train")}` }}</strong>
                </v-card>
            </v-col>

            <v-col cols="12" md="8"> </v-col>

            <v-col cols="12" md="2">
                <v-btn v-if="!this.inProgress" color="primary" @click="startTraining">
                    <v-icon>mdi-play</v-icon>
                    {{ `${$vuetify.lang.t("$vuetify.tooltip.start")}` }}
                </v-btn>
                <v-btn v-else color="primary" class="mr-4 secondary-disabled-button">
                    {{ `${$vuetify.lang.t("$vuetify.project.training")}` }}
                    <v-progress-circular indeterminate :size="15" :width="2" color="white"></v-progress-circular>
                </v-btn>
            </v-col>
        </v-row>

        <v-container class="pl-12">
            <v-row>
                <v-col cols="12" xs="12" md="3">
                    <v-select v-model="filterTrainItemType" :items="filterTrainItemOptions"
                        :label="$vuetify.lang.t('$vuetify.project.train_options')" item-text="name" item-value="value"
                        outlined dense></v-select>
                </v-col>
            </v-row>
            <v-form v-if="filterTrainItemType === 'train_item_within_specified_range'" ref="itemTrainForm">
                <v-row cols="12" md="6" class="mb-0 pb-0">
                    <v-col cols="2">
                        <span>Train From</span>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-menu ref="trainFromItemMenu" v-model="trainFromManue" :close-on-content-click="false"
                            :return-value.sync="trainFromDate" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field ref="startDateField" v-model="trainFromDate"
                                    placeholder="input start date..." clearable
                                    :rules="[validationRules.required, validationRules.startDateLessThanEndDate]"
                                    prepend-inner-icon="mdi-calendar" dense outlined v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="trainFromDate" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="trainFromManue = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.trainFromItemMenu.save(trainFromDate)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row cols="12" md="6" class="mb-0 pb-0">
                    <v-col cols="2">
                        <span>Train To</span>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-menu ref="trainToMenu" v-model="trainToManue" :close-on-content-click="false"
                            :return-value.sync="trainToDate" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field ref="endDateField" v-model="trainToDate" placeholder="input end date..."
                                    clearable prepend-inner-icon="mdi-calendar" dense outlined v-bind="attrs" v-on="on"
                                    :rules="[validationRules.required, validationRules.endDateGreaterThanStartDate]"></v-text-field>
                            </template>
                            <v-date-picker v-model="trainToDate" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="trainToManue = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.trainToMenu.save(trainToDate)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
        <v-row v-if="updateResult !== ''" class="pl-11">
            <v-col cols="auto" class="">
                <v-icon v-if="updateStatus === 'success'" color="#C8E6C9">mdi-check-circle</v-icon>
                <v-icon v-else-if="updateStatus === 'failed'" color="error">mdi-alert-circle</v-icon>
            </v-col>
            <v-col>
                <p>
                    <span :class="{ 'error--text': updateStatus === 'failed' }">
                        {{ updateResult }}
                    </span>
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import {
    REFRESHTOKEN,
    ITEMS_TRAIN,
    GET_TASKS_STATUS,
    UPDATE_TASKS_STATUS,
} from "@/store/_actiontypes";

import { timeToNextToken, formatNum, capitalizeFirstLetter, formatDateTime } from "@/helpers/helper";
import getEnv from "@/config/env";

export default {
    props: {},
    components: {},
    data() {
        return {
            filterTrainItemType: "train_all_item",
            filterTrainItemOptions: [
                { name: this.$vuetify.lang.t("$vuetify.project.train_all_item"), value: "train_all_item" },
                { name: this.$vuetify.lang.t("$vuetify.project.train_updated_item"), value: "train_updated_item" },
                { name: this.$vuetify.lang.t("$vuetify.project.train_item_within_specified_range"), value: "train_item_within_specified_range" },
            ],
            validationRules: {
                required: value => !!value || this.$vuetify.lang.t("$vuetify.project.date_field_required"),
                startDateLessThanEndDate: value => !this.trainToDate || new Date(value) < new Date(this.trainToDate) || this.$vuetify.lang.t("$vuetify.project.start_date_validation"),
                endDateGreaterThanStartDate: value => !this.trainFromDate || new Date(value) > new Date(this.trainFromDate) || this.$vuetify.lang.t("$vuetify.project.start_date_validation")
            },
            trainFromDate: '',
            trainToDate: '',
            trainFromManue: false,
            trainToManue: false,
            isTrainAll: true,
            timeToNextToken,
            selectedFile: null,
            defaultTasks: [
                {
                    name: "csv_upload",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "item_train",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "rank_train",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "image_train",
                    message: "",
                    status: "not_started",
                },
            ],
            polling: null,
            inProgress: false,
            updateResult: "",
            updateStatus: "",
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.account.user,
            selectedProject: (state) => state.project.selectedProject,
        }),
    },
    watch: {
        filterTrainItemType: {
            handler(newValue, oldValue) {
                if (newValue !== "train_item_within_specified_range") {
                    this.trainFromDate = "";
                    this.trainToDate = "";
                }
                this.isTrainAll = (newValue === "train_all_item");
            },
            immediate: true,
        },
        trainFromDate() {
            this.$refs.endDateField.validate();
        },
        trainToDate() {
            this.$refs.startDateField.validate();
        }
    },
    mounted() {
        if (timeToNextToken() < 300) {
            this.REFRESHTOKEN({
                refresh_token: this.user.refresh_token,
            }).then(
                (response) => {
                    this.get_tasks_status();
                },
                (error) => error
            );
        } else {
            this.get_tasks_status();
        }
    },
    beforeCreate() {},
    created() {},
    beforeDestroy() {
        clearInterval(this.polling);
    },
    methods: {
        ...mapActions("account", [REFRESHTOKEN]),
        ...mapActions("project", [
            ITEMS_TRAIN,
            GET_TASKS_STATUS,
            UPDATE_TASKS_STATUS,
        ]),
        async startTraining() {
            if (this.$refs.itemTrainForm && !this.$refs.itemTrainForm.validate()) {
                return;
            }

            let project_id = this.selectedProject.id;
            let is_trainAll = this.isTrainAll
            let start_date = this.trainFromDate
            let end_date = this.trainToDate
            const currentTaskStatus = await this.getTaskStatusFunction();
            let tasksStatus = currentTaskStatus;
            this.updateResult = "";

            if (tasksStatus) {
                for (let i = 0; i < tasksStatus.length; i++) {
                    if (tasksStatus[i].name === "item_train") {
                        tasksStatus[i].status = "pending";
                        break;
                    }
                }

                await this.update_tasks_status(tasksStatus);
            }

            this.inProgress = true;
            this.ITEMS_TRAIN({
                project_id: project_id,
                train_all: is_trainAll,
                train_from: start_date,
                train_to: end_date
            }).then(
                async (response) => {
                    if (response.status === 202) {
                        const { status, message } = await this.tasks_status_check("item_train");
                        this.updateResult = message;
                        this.updateStatus = status;
                    } else {
                        this.trainFailed(tasksStatus);
                    }
                    this.inProgress = false;
                    this.isLoading = false;
                    this.$emit('updateComplete');
                },
                async (error) => {
                    this.inProgress = false;
                    this.$emit('updateComplete');
                    console.log(error.response);
                    this.trainFailed(tasksStatus);
                }
            );
        },
        async tasks_status_check(name) {
            return new Promise((resolve, reject) => {
                let project_id = this.selectedProject.id;
                this.polling = setInterval(() => {
                    this.GET_TASKS_STATUS({
                        project_id: project_id,
                    })
                        .then(async (response) => {
                            let tasksStatus = response?.data?.tasks_status;

                            if (tasksStatus) {
                                let entry = tasksStatus.find((item) => item.name === name);
                                if (entry && entry.status !== "pending") {
                                    clearInterval(this.polling);
                                    resolve({
                                        status: entry.status,
                                        message: entry.message,
                                    });
                                }
                            } else {
                                clearInterval(this.polling);
                            }
                        })
                        .catch((err) => {
                            clearInterval(this.polling);
                            reject(err);
                        });
                }, getEnv("VUE_APP_POLLING_INTERVAL"));
            });
        },
        update_tasks_status(tasks_status) {
            let project_id = this.selectedProject.id;
            this.UPDATE_TASKS_STATUS({
                project_id: project_id,
                tasks_status: tasks_status,
            }).then(
                (response) => {},
                (error) => {
                    console.log(error.response);
                }
            );
        },
        async get_tasks_status() {
            let tasksStatus = this.selectedProject.tasks_status;

            if (!tasksStatus) {
                await this.update_tasks_status(this.defaultTasks);
            } else {
                for (let i = 0; i < tasksStatus.length; i++) {
                    if (tasksStatus[i].name === "item_train" && tasksStatus[i].status === "pending") {
                        this.inProgress = true;
                        const { status, message } = await this.tasks_status_check("item_train");
                        this.$emit("updateComplete");
                        this.updateResult = message;
                        this.updateStatus = status;
                    } else if (tasksStatus[i].name === "item_train") {
                        this.updateResult = tasksStatus[i].message;
                        this.updateStatus = tasksStatus[i].status;
                    }
                }
                this.inProgress = false;
            }
        },
        getTaskStatusFunction() {
            return new Promise((resolve, reject) => {
                let project_id = this.selectedProject.id;
                this.GET_TASKS_STATUS({
                    project_id: project_id,
                })
                    .then(async (response) => {
                        let tasksStatus = response?.data?.tasks_status;
                        resolve(tasksStatus);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async trainFailed(tasksStatus) {
            if (tasksStatus) {
                for (let i = 0; i < tasksStatus.length; i++) {
                    if (tasksStatus[i].name === "item_train") {
                        tasksStatus[i].status = "failed";
                        tasksStatus[i].message = "Training has been failed!";
                        break;
                    }
                }

                await this.update_tasks_status(tasksStatus);
            }
        },
    },
};
</script>

<style scoped>
.secondary-disabled-button {
    cursor: not-allowed;
    pointer-events: none;
}
</style>
