import Vue from "vue";
import Vuex from "vuex";
import { alert } from "@/store/modules/alert";
import { loader } from "@/store/modules/loader";
import { account } from "@/store/modules/account";
import { solution } from "@/store/modules/solution";
import { project } from "@/store/modules/project";
import { recommender } from "@/store/modules/recommender";
import { gpt } from "@/store/modules/gpt";
import { cv } from "@/store/modules/cv";
import { resource_metadata } from "@/store/modules/resource_metadata";
import { category } from "@/store/modules/category";
import { subscription } from "@/store/modules/subscription";
import { invoice } from "@/store/modules/invoice";
import { segment } from "@/store/modules/segment";
import { wallet } from "@/store/modules/wallet";
import { upload } from "@/store/modules/upload";
import { bank_accounts } from "@/store/modules/bank_accounts";
import { region } from "@/store/modules/region";
import { job } from "@/store/modules/job";
import { member } from "@/store/modules/member";
import { insight } from "@/store/modules/insight";
import { createStore } from 'vuex-extensions';
import {dataset} from "@/store/modules/dataset";
import { document } from "@/store/modules/document";
import {task} from "@/store/modules/task";
import { items } from "@/store/modules/items";
import { questionnaire } from "./modules/questionnaire";
Vue.use(Vuex);

const store = createStore(Vuex.Store, {
  modules: {
    alert,
    loader,
    account,
    solution,
    project,
    recommender,
    cv,
    resource_metadata,
    category,
    dataset,
    subscription,
    invoice,
    segment,
    wallet,
    upload,
    bank_accounts,
    region,
    job,
    member,
    insight,
    gpt,
    document,
    task,
    items,
    questionnaire,
  },
});

export default store;
