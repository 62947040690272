<template>

  <v-card outlined height="100%" flat>
    <v-card-subtitle class="font-weight-bold d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        {{ this.$vuetify.lang.t("$vuetify.insights.top_purchased_items") }}
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon small v-on="on" v-bind="attrs" class="ml-1">
              mdi-help-circle-outline
            </v-icon>
          </template>
          <span>{{ this.$vuetify.lang.t("$vuetify.insights.top_purchased_items") }}</span>
        </v-tooltip>
      </div>
      <v-btn
        small
        color="primary"
        @click="downloadAllItemDetails"
        :loading="isDownloading"
        :disabled="isDownloading || itemList.length === 0"
      >
        <v-icon left small>mdi-download</v-icon>
        CSV
      </v-btn>
    </v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text style="max-height: 450px; overflow-y: auto">
      <div v-if="itemList.length">
        <p class="my-2 top_item_btn primary--text"
           v-for="(item, index) in itemList"
           :key="index"
           @click="itemDetailsDialogHandler(item)"
        >
          <span class="d-inline-block text-truncate" style="max-width: 700px;">
            {{ item.title || item.item_id}}
          </span>
        </p>
      </div>
      <v-card v-else flat height="250">
        <v-row class="fill-height" align="center" justify="center">
          <span class="grey--text">
            {{ $vuetify.lang.t("$vuetify.no_data_available") }}
          </span>
        </v-row>
      </v-card>
    </v-card-text>
    <template v-if="itemDetailsDialog">
      <v-dialog v-model="itemDetailsDialog" max-width="800px" persistent scrollable hide-overlay
                transition="dialog-bottom-transition" content-class="rounded-lg" elevation="24" rounded>
        <Loader v-if="itemLoading" />
        <v-card v-else class="rounded-lg" elevation="24" rounded >
          <v-card-title>
            <span class="headline">
              <span class="d-inline-block text-truncate" style="max-width: 720px;">
                {{selectedItemTitle}}
              </span>
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div
              v-for="(value, key) in selectedItem"
              :key="key"
            >
              <v-row>
                <v-col cols="4" class="font-weight-bold">
                  {{ key }}
                </v-col>
                <v-col cols="8">
                  {{
                    Array.isArray(value) ?
                      value.join(", ") :
                      (typeof value === "object" && value !== null) ?
                        Object.values(value).join(", ") :
                        value
                  }}
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn text color="error" @click="itemDetailsDialog = false">
              {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { REFRESHTOKEN, GET_ITEMS_DETAILS_BY_IDS } from "@/store/_actiontypes";
import Loader from "@/components/common/loader/Loader";
import { timeToNextToken } from "@/helpers/helper";

export default {
  props: {
    itemList: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },

  components: {
    Loader,
  },

  computed: {
    ...mapState({
      user: (state) => state.account.user,
      selectedProject: (state) => state.project.selectedProject,
    }),
    ...mapGetters("loader", ["loading"]),
  },

  watch: {
  },

  mounted() {},

  data() {
    return {
      timeToNextToken,
      itemDetailsDialog: false,
      selectedItem: {},
      selectedItemTitle: "",
      itemLoading: false,
      isDownloading: false,
    };
  },

  methods: {
    ...mapActions("items", [GET_ITEMS_DETAILS_BY_IDS]),
    ...mapActions("account", [REFRESHTOKEN]),
    getItemDetails(item_id) {
      this.itemLoading = true;
      if (this.timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.getItemDetailsByID(item_id);
          },
          (error) => error
        );
      } else {
        this.getItemDetailsByID(item_id);
      }
    },
    getItemDetailsByID(item_id) {
      this.GET_ITEMS_DETAILS_BY_IDS({
        project_id: this.selectedProject.id,
        ids: [item_id],
      }).then(
        (response) => {
          this.selectedItem = response.data.detail.response[0];
          this.itemLoading = false;
        },
        (_error) => {
          this.selectedItem = {};
          this.itemLoading = false;
        }
      );
    },
    itemDetailsDialogHandler(item) {
      this.itemDetailsDialog = true;
      this.getItemDetails(item.item_id);
      this.selectedItemTitle = item.title || item.item_id;
    },
    async downloadAllItemDetails() {
      if (this.isDownloading) return;
      this.isDownloading = true;
      try {
        if (this.timeToNextToken() < 300) {
          await this.REFRESHTOKEN({
            refresh_token: this.user.refresh_token,
          });
        }
        const response = await this.GET_ITEMS_DETAILS_BY_IDS({
          project_id: this.selectedProject.id,
          ids: this.itemList.map(item => item.item_id),
        });
        const itemDetails = response.data?.detail?.response || [];
        if (itemDetails.length === 0) {
          console.error("No item details received");
          // You might want to show an error message to the user here
          return;
        }
        this.downloadCSV('top_purchase_items', itemDetails);
      } catch (error) {
        console.error("Error downloading item details:", error);
        // You might want to show an error message to the user here
      } finally {
        this.isDownloading = false;
      }
    },
    convertToCSV(data) {
      if (!data || data.length === 0) return '';
      // const headers = ['id', ...Object.keys(data[0]).filter(key => key !== 'id')];
      const headers = Object.keys(data[0]);
      const headerRow = headers.map(header => `"${header}"`).join(',');
      const dataRows = data.map(item =>
        headers.map(header => {
          const value = item[header];
          if (value === null || value === undefined) return '""';
          if (typeof value === 'object') return `"${JSON.stringify(value).replace(/"/g, '""')}"`;
          return `"${String(value).replace(/"/g, '""')}"`;
        }).join(',')
      );
      return [headerRow, ...dataRows].join('\n');
    },
    generateFilename(reportType) {
      const startDate = this.startDate;
      const endDate = this.endDate;
      return `${this.selectedProject.id}_${reportType}_${startDate}_${endDate}.csv`;
    },
    downloadCSV(reportType, data) {
      const filename = this.generateFilename(reportType);
      const csv = this.convertToCSV(data);
      if (!csv) {
        console.error("No data to download");
        return;
      }
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
};
</script>
<style scoped>
.top_item_btn {
  cursor: pointer;
  font-weight: bold;
  color: #1F5592;
}
</style>