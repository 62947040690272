<template>
    <div>
        <v-row>
            <v-col cols="12" md="2" class="pl-12">
                <v-card class="pa-2" flat>
                    <strong>{{ `${$vuetify.lang.t("$vuetify.project.ranks_train")}` }}</strong>
                </v-card>
            </v-col>

            <v-col cols="12" md="8"> </v-col>

            <v-col cols="12" md="2">
                <v-btn v-if="!this.inProgress" color="primary" @click="startTraining" :disabled="!isPreviouslyRankSettingsCreated()">
                    <v-icon>mdi-play</v-icon>
                    {{ `${$vuetify.lang.t("$vuetify.tooltip.start")}` }}
                </v-btn>
                <v-btn v-else color="primary" class="mr-4 secondary-disabled-button">
                    {{ `${$vuetify.lang.t("$vuetify.project.training")}` }}
                    <v-progress-circular indeterminate :size="15" :width="2" color="white"></v-progress-circular>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-if="updateResult !== ''" class="pl-11">
            <v-col cols="auto" class="">
                <v-icon v-if="updateStatus === 'success'" color="#C8E6C9">mdi-check-circle</v-icon>
                <v-icon v-else-if="updateStatus === 'failed'" color="error">mdi-alert-circle</v-icon>
            </v-col>
            <v-col>
                <p>
                    <span :class="{ 'error--text': updateStatus === 'failed' }">
                        {{ updateResult }}
                    </span>
                </p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import { REFRESHTOKEN, RANKS_TRAIN, GET_TASKS_STATUS, UPDATE_TASKS_STATUS, ADD_ALERT } from "@/store/_actiontypes";

import { timeToNextToken, formatNum, capitalizeFirstLetter, formatDateTime } from "@/helpers/helper";
import getEnv from "@/config/env";

export default {
    props: {},
    components: {},
    data() {
        return {
            timeToNextToken,
            selectedFile: null,
            defaultTasks: [
                {
                    name: "csv_upload",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "item_train",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "rank_train",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "image_train",
                    message: "",
                    status: "not_started",
                },
            ],
            polling: null,
            inProgress: false,
            updateResult: "",
            updateStatus: "",
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.account.user,
            selectedProject: (state) => state.project.selectedProject,
            previouslySetupState: (state) => state.project.previouslySetupState,
        }),
    },
    watch: {},
    mounted() {
        if (timeToNextToken() < 300) {
            this.REFRESHTOKEN({
                refresh_token: this.user.refresh_token,
            }).then(
                (response) => {
                    this.get_tasks_status();
                },
                (error) => error
            );
        } else {
            this.get_tasks_status();
        }
    },
    beforeCreate() {},
    created() {},
    beforeDestroy() {
        clearInterval(this.polling);
    },
    methods: {
        ...mapActions("account", [REFRESHTOKEN]),
        ...mapActions("project", [RANKS_TRAIN, GET_TASKS_STATUS, UPDATE_TASKS_STATUS]),
        ...mapActions("alert", [ADD_ALERT]),
        async startTraining() {
            let project_id = this.selectedProject.id;

            const currentTaskStatus = await this.getTaskStatusFunction();
            let tasksStatus = currentTaskStatus;
            this.updateResult = "";

            if (tasksStatus) {
                for (let i = 0; i < tasksStatus.length; i++) {
                    if (tasksStatus[i].name === "rank_train") {
                        tasksStatus[i].status = "pending";
                        break;
                    }
                }

                await this.update_tasks_status(tasksStatus);
            }

            this.inProgress = true;
            this.RANKS_TRAIN({
                project_id: project_id,
            }).then(
                async (response) => {
                    if (response.status === 202) {
                        const { status, message } = await this.tasks_status_check("rank_train");
                        this.updateResult = message;
                        this.updateStatus = status;
                    } else {
                        this.trainFailed(tasksStatus);
                    }
                    this.$emit("updateComplete");
                    this.inProgress = false;
                    this.isLoading = false;
                },
                (error) => {
                    if (error.response.status === 404) {
                        this.$store.dispatch(
                            `alert/${ADD_ALERT}`,
                            {
                                message: error.response.data.detail.response,
                                color: "error",
                            },
                            { root: true }
                        );
                        this.trainFailed(tasksStatus);
                        this.updateResult = error.response.data.detail.response;
                        this.updateStatus = "failed";
                    }
                    this.$emit("updateComplete");
                    this.inProgress = false;
                    console.log(error.response);
                }
            );
        },
        async tasks_status_check(name) {
            return new Promise((resolve, reject) => {
                let project_id = this.selectedProject.id;
                this.polling = setInterval(() => {
                    this.GET_TASKS_STATUS({
                        project_id: project_id,
                    })
                        .then(async (response) => {
                            let tasksStatus = response?.data?.tasks_status;

                            if (tasksStatus) {
                                let entry = tasksStatus.find((item) => item.name === name);
                                if (entry && entry.status !== "pending") {
                                    clearInterval(this.polling);
                                    resolve({
                                        status: entry.status,
                                        message: entry.message,
                                    });
                                }
                            } else {
                                clearInterval(this.polling);
                            }
                        })
                        .catch((err) => {
                            clearInterval(this.polling);
                            reject(err);
                        });
                }, getEnv("VUE_APP_POLLING_INTERVAL"));
            });
        },
        update_tasks_status(tasks_status) {
            let project_id = this.selectedProject.id;
            this.UPDATE_TASKS_STATUS({
                project_id: project_id,
                tasks_status: tasks_status,
            }).then(
                (response) => {
                },
                (error) => {
                    console.log(error.response);
                }
            );
        },
        async get_tasks_status() {
            let tasksStatus = this.selectedProject.tasks_status;

            if (!tasksStatus) {
                await this.update_tasks_status(this.defaultTasks);
            } else {
                for (let i = 0; i < tasksStatus.length; i++) {
                    if (tasksStatus[i].name === "rank_train" && tasksStatus[i].status === "pending") {
                        this.inProgress = true;
                        const { status, message } = await this.tasks_status_check("rank_train");
                        this.$emit("updateComplete");
                        this.updateResult = message;
                        this.updateStatus = status;
                    } else if (tasksStatus[i].name === "rank_train") {
                        this.updateResult = tasksStatus[i].message;
                        this.updateStatus = tasksStatus[i].status;
                    }
                }
                this.inProgress = false;
            }
        },
        getTaskStatusFunction() {
            return new Promise((resolve, reject) => {
                let project_id = this.selectedProject.id;
                this.GET_TASKS_STATUS({
                    project_id: project_id,
                })
                    .then(async (response) => {
                        let tasksStatus = response?.data?.tasks_status;
                        resolve(tasksStatus);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        async trainFailed(tasksStatus) {
            if (tasksStatus) {
                for (let i = 0; i < tasksStatus.length; i++) {
                    if (tasksStatus[i].name === "rank_train") {
                        tasksStatus[i].status = "failed";
                        tasksStatus[i].message = "Training has been failed!";
                        break;
                    }
                }
                console.log(tasksStatus);
                await this.update_tasks_status(tasksStatus);
            }
        },
        isPreviouslyRankSettingsCreated() {
            return this.previouslySetupState.steps.some((step) => step.name === "rank_settings" && step.status === "success");
        },
    },
};
</script>

<style scoped>
.secondary-disabled-button {
    cursor: not-allowed;
    pointer-events: none;
}
</style>
