import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import getEnv from "@/config/env";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import VueCardFormat from 'vue-credit-card-validation';
import sentry from "./plugins/sentry";
import './assets/css/custom.css';
import VueGtag from "vue-gtag";

// add vuetify phone input
Vue.use(VueTelInputVuetify, {
  vuetify,
});

// add vue credit card validator
Vue.use(VueCardFormat);

Vue.use(VueGtag, {
  config: { id: "G-1D4B3FSF7Z" },
  enabled: getEnv("NODE_ENV") === 'production',
});

/**
 * Converts a string to camel case.
 * Example: create_request => createRequest
 *
 * @param  {String} str the string to convert
 * @return {String}
 */
// Vue.filter('camelCase', function (str) {
//   return str
//       .replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })
//       .replace(/\s/g, '')
//       .replace(/^(.)/, function($1) { return $1.toLowerCase(); });
// });

/**
 * Converts a string to capitalize.
 * Example: runninG => Running
 *
 * @param  {String} str the string to convert
 * @return {String}
 */
// Vue.filter('capitalize', function (str) {
//   return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
// })

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  sentry,
  render: (h) => h(App),
}).$mount("#app");
