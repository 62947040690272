import Api from "@/services/cv_api";

import {
  LOAD_VIDEO_BY_MODEL_ID
} from "@/store/_actiontypes";

import {
  SET_VIDEO_BY_MODEL_ID
} from "@/store/_mutationtypes";

import { updateLoaderTo } from "./account";

const state = {
  allVideoData: []
}

const actions = {
  [LOAD_VIDEO_BY_MODEL_ID]({ commit }, { }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.get(`/v1/api/video/demographic`).then(
        (response) => {
          let allVideoData = response.data.demographic;
          commit(SET_VIDEO_BY_MODEL_ID, allVideoData);
          updateLoaderTo(false);
          resolve(response);
        },
        (error) => {
          updateLoaderTo(false);
          reject(error);
        }
      );
    });
  },
};

const mutations = {
  [SET_VIDEO_BY_MODEL_ID](state, allVideoData) {
    state.allVideoData = allVideoData;
  },
};

export const cv = {
  namespaced: true,
  state,
  actions,
  mutations,
};
