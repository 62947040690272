<template>
  <div>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="pa-0" xs="12" md="12">
          <v-card class="mx-auto px-2" outlined>
            <v-row>
              <v-col md="6">
                <div class="px-2 primary--text font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.projects_header_txt") }}
                </div>
              </v-col>
              <v-col md="6" v-if="authorize([Role.ADMIN]) ">
                <v-btn
                    class="float-right text-capitalize"
                    @click="createProject()"
                    color="primary"
                    :disabled=" Object.keys(organization).length && !organization.email_verified"
                >
                  <span class="hidden-sm-and-down">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    {{ $vuetify.lang.t("$vuetify.create_project_btn_txt") }}
                  </span>
                  <span class="hidden-md-and-up">
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" xs="12" md="4">
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$vuetify.lang.t('$vuetify.search_btn_txt')"
                    outlined
                    dense
                    single-line
                    hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" md="3">
                <v-select
                    v-model="project_status"
                    :items="states"
                    item-text="state"
                    item-value="value"
                    persistent-hint
                    hide-details="auto"
                    outlined
                    dense
                ></v-select>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <Loader v-if="isLoading" class="mr-2 mt-6" />
            <v-data-table
                v-else
                :headers="headers"
                :items="allProject.filter(
                    (project) => project_status === ''
                      ? project.status !== ''
                      : project.status === project_status
                  )
                "
                :search="search"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                @page-count="pageCount = $event"
            >
              <template v-slot:[`item.id`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.id.split("-")[0] }}</span>
                  </template>
                  <span>{{ item.id }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div>
                  <v-btn
                      text
                      class="primary--text text--darken-3"
                      @click="redirectDetails(item)"
                  >{{ item.name }}</v-btn>
                </div>
              </template>
              <template v-slot:[`item.segment`]="{ item }">
                <v-chip
                    class="ma-2 text-capitalize"
                    v-bind:color="item.segment.color"
                    label
                    outlined
                    pill
                >
                  {{ item.segment.name }}
                </v-chip>
              </template>
              <template v-slot:[`item.language`]="{ item }">
                <span class="text-capitalize">
                  {{ item.language }}
                </span>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <span class="text-capitalize">
                  {{ item.status }}
                </span>
              </template>
              <template v-slot:[`item.industry`]="{ item }">
                {{ item.industry.name }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <ButtonGroup
                    :groupType="ACTION_BUTTON_GROUP"
                    :addButton="false"
                    :disabledDeleteButton="
                      disable_crud_operation || (item.status !== 'stopped' && item.status !== 'running')
                    "
                    :disabledStartButton="disable_crud_operation || item.status !== 'stopped'"
                    :disabledStopButton="disable_crud_operation || item.status !== 'running'"
                    :disabledEditButton="
                      disable_crud_operation || (item.status !== 'pending' && item.status !== 'running')
                    "
                    @onDetailsClick="redirectDetails(item)"
                    @onEditClick="editItem(item)"
                    @onStopClick="stopItem(item)"
                    @onDeleteClick="deleteItem(item)"
                    @onStartClick="startItem(item)"
                />
              </template>
            </v-data-table>
            <v-row>
              <v-col class="py-0" cols="12" md="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3"></v-col>
              <v-col cols="12" xs="12" sm="6" md="6">
                <v-pagination
                    class="text-center"
                    v-model="page"
                    :length="pageCount"
                ></v-pagination>
              </v-col>
              <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                <v-select
                    v-model="itemsPerPage"
                    :items="rows"
                    hide-details="auto"
                    outlined
                    dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <DialogModal
        v-model="stopDialog"
        :title="$vuetify.lang.t('$vuetify.confirm_alert_title_txt')"
        :message="$vuetify.lang.t('$vuetify.confirm_alert_msg_txt_3')"
        :confirmButtonText="$vuetify.lang.t('$vuetify.confirm_btn_txt')"
        :cancelButtonText="$vuetify.lang.t('$vuetify.cancel_btn_txt')"
        @onConfirmation="stopProject()"
        @onCancel="stopDialog = false"
    />
    <DialogModal
        v-model="deleteDialog"
        :title="$vuetify.lang.t('$vuetify.confirm_alert_title_txt')"
        :message="$vuetify.lang.t('$vuetify.confirm_alert_msg_txt_4')"
        :confirmButtonText="$vuetify.lang.t('$vuetify.confirm_btn_txt')"
        :cancelButtonText="$vuetify.lang.t('$vuetify.cancel_btn_txt')"
        @onConfirmation="terminateProject()"
        @onCancel="deleteDialog = false"
    />
    <DialogModal
        v-model="startDialog"
        :title="$vuetify.lang.t('$vuetify.confirm_alert_title_txt')"
        :message="$vuetify.lang.t('$vuetify.confirm_alert_msg_txt_5')"
        :confirmButtonText="$vuetify.lang.t('$vuetify.confirm_btn_txt')"
        :cancelButtonText="$vuetify.lang.t('$vuetify.cancel_btn_txt')"
        @onConfirmation="startProject()"
        @onCancel="startDialog = false"
    />
    <DialogModal
        v-model="alertDialog"
        :title="$vuetify.lang.t('$vuetify.error_title_txt')"
        :message="alertMessage"
        :confirmButtonText="$vuetify.lang.t('$vuetify.ok_btn_txt')"
        :cancelButton="false"
        @onConfirmation="alertDialog = false"
    />
  </div>
</template>
  
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { ACTION_BUTTON_GROUP } from "@/components/common/buttongroup/_buttongrouptypes";
import {
  LOAD_ALL_PROJECT,
  REFRESHTOKEN,
  SAVE_PROJECT,
  START_PROJECT,
  STOP_PROJECT,
  TERMINATE_PROJECT,
  STATE_UPDATE_PROJECT,
  UPDATE_PROJECT,
} from "@/store/_actiontypes";

import validations from "@/helpers/validations";
import { authorize, timeToNextToken } from "@/helpers/helper";
import { Role } from "@/helpers/role";
  
import ButtonGroup from "@/components/common/buttongroup/ButtonGroup";
import DialogModal from "@/components/common/DialogModal";
import Loader from "@/components/common/loader/Loader";
  
export default {
  props: {
    solution_type: {
      type: String
    },
    props: {
      role: String,
    },
  },
  components: {
    ButtonGroup,
    DialogModal,
    Loader,
  },
  computed: {
    ...mapState({
      allProject: (state) => state.project.allProject,
      user: (state) => state.account.user,
      organization: (state) => state.account.organization,
      resourceMetadataByType: (state) => state.resource_metadata.resourceMetadataByType,
    }),
    ...mapGetters("loader", ["loading"]),
  },
  watch: {
    "solution_type": {
      handler: function() {
        this.getAllProject();
      }
    }
  },
  mounted() {
    this.getAllProject();
    this.disableCrudOperation();
  },
  data() {
    return {
      authorize,
      timeToNextToken,
      disable_crud_operation: false,
      isLoading: false,
      showPassword: false,
      ACTION_BUTTON_GROUP: ACTION_BUTTON_GROUP,
      dialog: false,
      detailsDialog: false,
      valid: true,
      projectName: "",
      type: "",
      Role,
      language: "",
      deleteDialog: false,
      stopDialog: false,
      startDialog: false,
      alertDialog: false,
      alertMessage: "Unknown error occurs",
      edit: false,
      ...validations,
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.id_header_txt"),
          align: "center",
          sortable: true,
          value: "id",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.name_header_txt"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.solution_header_txt"),
          align: "center",
          sortable: false,
          value: "segment",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.language_header_txt"),
          align: "center",
          sortable: false,
          value: "language",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status_header_txt"),
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.industry_header_txt"),
          align: "center",
          sortable: false,
          value: "industry",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.actions_header_txt"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      toBeDeletedProjectId: null,
      toBeUpdatedProjectId: null,
      toBeStopedProjectId: null,
      toBeStartedProjectId: null,
      projectDetails: {},
      project_status: "",
      states: [
        { state: "All", value: "" },
        { state: "Pending", value: "pending" },
        { state: "Running", value: "running" },
        { state: "Rejected", value: "rejected" },
        { state: "Stopped", value: "stopped" },
      ],
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      rows: [5, 10, 20],
    };
  },
  created() {
    this.type = this.$route.query.type;
    if ("dev" === this.role){
      this.tab = "mysolutions";
    }
  },
  methods: {
    ...mapActions("project", [
      LOAD_ALL_PROJECT,
      SAVE_PROJECT,
      UPDATE_PROJECT,
      START_PROJECT,
      STOP_PROJECT,
      TERMINATE_PROJECT,
      STATE_UPDATE_PROJECT,
    ]),
    ...mapActions("account", [REFRESHTOKEN]),
    getAllProject() {
      this.isLoading = true;
      if (this.timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.getAllProjectFunction();
          },
          (error) => error
        );
      } else {
        this.getAllProjectFunction();
      }
    },
    getAllProjectFunction() {
      this.LOAD_ALL_PROJECT({ solution_type: this.solution_type }).then(
        (response) => {
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    terminateProject() {
      this.TERMINATE_PROJECT({
        project_id: this.toBeDeletedProjectId,
      }).then(
        (response) => {
          this.deleteDialog = false;
          this.getAllProject();
        },
        (error) => {
          this.alertMessage = error.message;
          this.deleteDialog = false;
          this.alertDialog = true;
        }
      );
    },
    stopProject() {
      this.STOP_PROJECT({
        project_id: this.toBeStopedProjectId,
      }).then(
        (response) => {
          this.stopDialog = false;
          this.getAllProject();
        },
        (error) => {
          this.alertMessage = error.message;
          this.stopDialog = false;
          this.alertDialog = true;
        }
      );
    },
    startProject() {
      this.START_PROJECT({
        project_id: this.toBeStartedProjectId,
      }).then(
        (response) => {
          this.startDialog = false;
          this.getAllProject();
        },
        (error) => {
          this.alertMessage = error.message;
          this.startDialog = false;
          this.alertDialog = true;
        }
      );
    },
    formatDateTime(datetime) {
      return new Date(datetime.toLocaleString());
    },
    redirectDetails(item) {
      this.$router.push("/projects/" + item.id);
    },
    editItem(item) {
      // redirect to edit page
      this.$router.push("/update-projects/" + item.id);
    },
    createNewClick() {
      // redirect to solution page
      this.$router.push("/solutions");
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.toBeDeletedProjectId = item.id;
    },
    stopItem(item) {
      this.stopDialog = true;
      this.toBeStopedProjectId = item.id;
    },
    startItem(item) {
      this.startDialog = true;
      this.toBeStartedProjectId = item.id;
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    resetForm() {
      this.$refs.form.reset();
    },
    disableCrudOperation(){
      if(authorize([Role.USER])){
        this.disable_crud_operation = true;
      }
    },
    createProject() {
      this.$router.push(`/create-projects?type=${this.type}`);
    },
  },
};
</script>
