<template>
    <div>
        <v-container>
            <v-file-input v-model="selectedFile" :label="$vuetify.lang.t('$vuetify.project.select_file')" accept=".csv" outlined clearable></v-file-input>
            <div class="ml-8">
                <v-alert type="info" text border="left"> {{ `${$vuetify.lang.t("$vuetify.project.dataset_update_info")}` }} </v-alert>
            </div>
            <v-row>
                <v-col cols="12" md="8"></v-col>
                <v-col cols="12" md="4" class="text-right">
                    <v-btn v-if="!this.inProgress" color="primary" @click="handleFileParsing()"> {{ `${$vuetify.lang.t("$vuetify.upload_btn_txt")}` }} </v-btn>
                    <v-btn v-else color="primary" class="mr-4 secondary-disabled-button">
                        {{ `${$vuetify.lang.t("$vuetify.project.uploading")}` }}
                        <v-progress-circular indeterminate :size="15" :width="2" color="white"></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
            <template v-if="updateResult !== '' || taskHistoryData !== null">
                <v-divider class="mt-8"></v-divider>
                <!-- <v-row>
                    <v-col cols="12" md="2" class="pl-12"> </v-col>
                </v-row> -->
                <v-card class="elevation-0">
                    <v-card-title class="text-h6 font-weight-bold">
                        {{ `${$vuetify.lang.t("$vuetify.project.dataset_update_log")}` }}
                    </v-card-title>
                    <v-divider></v-divider>
                    <!-- <v-row class="mx-2 mb-3">
                        <v-col cols="12" md="6">
                        </v-col>
                    </v-row> -->
                    <div>

                        <Loader v-if="isTrainHistoryLoading" class="mr-2 mt-6" />
                        <template v-else>
                            <v-data-table
                                :headers="trainHistoryHeaders"
                                :items="taskHistoryData"
                                :sort-desc="[true]"
                                :server-items-length="totalNumberOfTrains"
                                :footer-props="{ itemsPerPageOptions: itemsPerPageOptions }"
                                :items-per-page.sync="defaultPagination.itemsPerPage"
                                :page.sync="defaultPagination.page" 
                                @update:items-per-page="handleItemsPerPageChange" 
                                @update:page="handlePageChange" 
                            >
                                <template v-slot:item.name="{ item }">
                                    {{ item.name ? (item.name) : "N/A" }}
                                </template>
                                <template v-slot:item.started_at="{ item }">
                                    {{ item.started_at ? formatDateTime(item.started_at) : "N/A" }}
                                </template>
                                <template v-slot:item.last_updated_at="{ item }">
                                    {{ item.last_updated_at ? formatDateTime(item.last_updated_at) : "N/A" }}
                                </template>
                                <template v-slot:item.message="{ item }">
                                    {{ item.message ? item.message : "N/A" }}
                                </template>
                                <template v-slot:item.scheduled_by="{ item }">
                                    {{ item.scheduled_by ? item.scheduled_by : "N/A" }}
                                </template>
                                <template v-slot:item.status="{ item }">
                                    {{ item.status ? item.status : "N/A" }}
                                </template>
                            </v-data-table>
                        </template>
                    </div>
                </v-card>                
            </template>
        </v-container>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import {
    REFRESHTOKEN,
    SETUP_PROJECT,
    CREATE_MAPPER,
    UPDATE_MAPPER,
    CREATE_INDICES,
    CREATE_RANK,
    UPLOAD_RECOMMENDER_DATA,
    ITEMS_TRAIN,
    IMAGES_TRAIN,
    RANKS_TRAIN,
    GET_SETUP_STATE,
    UPDATE_SETUP_STATE,
    RECOMMENDER_TASK,
    GET_TASKS_STATUS,
    UPDATE_TASKS_STATUS,
    LOAD_TRAIN_TASKS,
} from "@/store/_actiontypes";

import Papa from "papaparse";

import { timeToNextToken, formatDateTime } from "@/helpers/helper";
import getEnv from "@/config/env";

export default {
    props: {},
    components: {},
    data() {
        return {
            selectedFile: null,
            formatDateTime,
            taskHistoryData: [],
            trainLoader: false,
            updateLoader: false,
            isTrainHistoryLoading: false,
            totalNumberOfTrains: 0,
            trainHistoryHeaders: [
                {
                text: this.$vuetify.lang.t("$vuetify.name_header_txt"),
                align: "center",
                sortable: false,
                value: "name",
                },
                {
                text: this.$vuetify.lang.t("$vuetify.job.started_at"),
                align: "center",
                sortable: false,
                value: "started_at",
                },
                {
                text: this.$vuetify.lang.t("$vuetify.job.finished_at"),
                align: "center",
                sortable: false,
                value: "last_updated_at",
                },
                {
                text: this.$vuetify.lang.t("$vuetify.status_header_txt"),
                align: "center",
                sortable: false,
                value: "status",
                },
                {
                text: this.$vuetify.lang.t("$vuetify.project.message"),
                align: "center",
                sortable: false,
                value: "message",
                },
                {
                text: this.$vuetify.lang.t("$vuetify.project.generated_by"),
                align: "center",
                sortable: false,
                value: "scheduled_by",
                },
            ],
            defaultTasks: [
                {
                    name: "csv_upload",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "item_train",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "rank_train",
                    message: "",
                    status: "not_started",
                },
                {
                    name: "image_train",
                    message: "",
                    status: "not_started",
                },
            ],
            polling: null,
            inProgress: false,
            updateResult: "",
            updateStatus: "",
            updateStartedAt: "",
            defaultPagination: { 
                page: 1, 
                itemsPerPage: 5, 
                sortDesc: [true],
            },
            itemsPerPageOptions: [5, 10, 15, 50, 100],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.account.user,
            selectedProject: (state) => state.project.selectedProject,
        }),
    },
    watch: {},
    mounted() {
        this.getTrainTasksFunction();
        if (timeToNextToken() < 300) {
            this.REFRESHTOKEN({
                refresh_token: this.user.refresh_token,
            }).then(
                (response) => {
                    this.get_tasks_status();
                },
                (error) => error
            );
        } else {
            this.get_tasks_status();
        }
    },
    beforeCreate() {},
    created() {},
    beforeDestroy() {
        clearInterval(this.polling);
    },
    methods: {
        ...mapActions("account", [REFRESHTOKEN]),
        ...mapActions("project", [
            SETUP_PROJECT,
            CREATE_MAPPER,
            UPDATE_MAPPER,
            CREATE_INDICES,
            CREATE_RANK,
            UPLOAD_RECOMMENDER_DATA,
            ITEMS_TRAIN,
            IMAGES_TRAIN,
            RANKS_TRAIN,
            GET_SETUP_STATE,
            UPDATE_SETUP_STATE,
            RECOMMENDER_TASK,
            GET_TASKS_STATUS,
            UPDATE_TASKS_STATUS,
            LOAD_TRAIN_TASKS,
        ]),
        async handleFileParsing() {
            if (!this.selectedFile) {
                return;
            }
            let project_id = this.selectedProject.id;
            const formData = new FormData();
            formData.append("file", this.selectedFile);

            const currentTaskStatus = await this.getTaskStatusFunction();
            let tasksStatus = currentTaskStatus;
            this.updateResult = "";

            if (tasksStatus) {
                for (let i = 0; i < tasksStatus.length; i++) {
                    if (tasksStatus[i].name === "csv_upload") {
                        tasksStatus[i].status = "pending";
                        break;
                    }
                }

                await this.update_tasks_status(tasksStatus);
            }

            this.inProgress = true;
            this.UPLOAD_RECOMMENDER_DATA({
                project_id: project_id,
                file: formData,
            }).then(
                async (response) => {
                    if (response.status === 202) {
                        const { started_at, status, message } = await this.tasks_status_check("csv_upload");
                        this.updateResult = message;
                        this.updateStatus = status;
                        if (started_at){
                            this.updateStartedAt = this.formatDateTime(started_at);
                        }
                        this.selectedFile = null;
                    } else {
                        this.update_tasks_status(this.defaultTasks);
                    }
                    this.inProgress = false;
                    this.isLoading = false;
                    this.getTrainTasksFunction();
                },
                (error) => {
                    this.inProgress = false;
                }
            );
        },
        async tasks_status_check(name) {
            return new Promise((resolve, reject) => {
                let project_id = this.selectedProject.id;
                this.polling = setInterval(() => {
                    this.GET_TASKS_STATUS({
                        project_id: project_id,
                    })
                        .then(async (response) => {
                            let tasksStatus = response?.data?.tasks_status;

                            if (tasksStatus) {
                                let entry = tasksStatus.find((item) => item.name === name);
                                if (entry && entry.status !== "pending") {
                                    clearInterval(this.polling);
                                    resolve({
                                        started_at: entry.started_at,
                                        status: entry.status,
                                        message: entry.message,
                                    });
                                }
                            } else {
                                clearInterval(this.polling);
                            }
                        })
                        .catch((err) => {
                            clearInterval(this.polling);
                            reject(err);
                        });
                }, getEnv("VUE_APP_POLLING_INTERVAL"));
            });
        },
        update_tasks_status(tasks_status) {
            let project_id = this.selectedProject.id;
            this.UPDATE_TASKS_STATUS({
                project_id: project_id,
                tasks_status: tasks_status,
            }).then(
                (response) => {},
                (error) => {
                    console.log(error.response);
                }
            );
        },
        async get_tasks_status() {
            let tasksStatus = this.selectedProject.tasks_status;

            if (!tasksStatus) {
                await this.update_tasks_status(this.defaultTasks);
            } else {
                for (let i = 0; i < tasksStatus.length; i++) {
                    if (tasksStatus[i].name === "csv_upload" && tasksStatus[i].status === "pending") {
                        this.inProgress = true;
                        const { started_at, status, message } = await this.tasks_status_check("csv_upload");
                        this.updateResult = message;
                        this.updateStatus = status;
                        if (started_at){
                            this.updateStartedAt = this.formatDateTime(started_at);
                        }
                    } else if (tasksStatus[i].name === "csv_upload") {
                        this.updateResult = tasksStatus[i].message;
                        this.updateStatus = tasksStatus[i].status;
                        if (tasksStatus[i].started_at){
                            this.updateStartedAt = this.formatDateTime(tasksStatus[i].started_at);
                        }
                    }
                }
                this.inProgress = false;
            }
        },
        getTaskStatusFunction() {
            return new Promise((resolve, reject) => {
                let project_id = this.selectedProject.id;
                this.GET_TASKS_STATUS({
                    project_id: project_id,
                })
                    .then(async (response) => {
                        let tasksStatus = response?.data?.tasks_status;
                        resolve(tasksStatus);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getTrainTasksFunction() {
            const start = (this.defaultPagination.page - 1) * this.defaultPagination.itemsPerPage;
            const size = this.defaultPagination.itemsPerPage;
            this.LOAD_TRAIN_TASKS({
                project_id: this.selectedProject.id,
                train_type: "item_save",
                status: "all_status",
                start,
                size,
            }).then(
                (response) => {
                    this.taskHistoryData = response.data.detail.response;
                    this.totalNumberOfTrains = response.data.detail.total_train;
                },
                (error) => {
                    console.log("Task Error: ", error)
                }
            );
        },

        deepEqual(obj1, obj2) {
            if (obj1 === obj2) return true;

            if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;

            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);

            if (keys1.length !== keys2.length) return false;

            for (const key of keys1) {
                if (!keys2.includes(key) || !this.deepEqual(obj1[key], obj2[key])) return false;
            }

            return true;
        },
        handlePageChange(page) {
            this.defaultPagination.page = page;

            this.getTrainTasksFunction();
        },
        handleItemsPerPageChange(newItemsPerPage) {
            this.defaultPagination.itemsPerPage = newItemsPerPage;
            this.getTrainTasksFunction();
        },
    },
};
</script>

<style scoped>
.secondary-disabled-button {
    cursor: not-allowed;
    pointer-events: none;
}
</style>
