import { render, staticRenderFns } from "./CreateGptProfile.vue?vue&type=template&id=a271dda0&scoped=true"
import script from "./CreateGptProfile.vue?vue&type=script&lang=js"
export * from "./CreateGptProfile.vue?vue&type=script&lang=js"
import style0 from "./CreateGptProfile.vue?vue&type=style&index=0&id=a271dda0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a271dda0",
  null
  
)

export default component.exports