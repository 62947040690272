import axios from "axios";
import store from "@/store";
import { ADD_ALERT, TOGGLE_LOADING, REFRESHTOKEN } from "@/store/_actiontypes";
import router from "@/router/index";
import getEnv from "@/config/env";

let isRefreshingToken = false;
let callbacks = [];

let api = axios.create({});

api.interceptors.request.use((request) => {
  let pathArray = window.location.pathname.split('/');
  let cv_project_id = pathArray[pathArray.length - 1]
  request.baseURL = getEnv("VUE_APP_CV_API") + cv_project_id + "/";

  let api_key = sessionStorage.getItem("cv_api_key");
  if (api_key !== "") {
    request.headers["Authorization"] = `${api_key}`;
  }
  updateLoaderTo(true);
  return request;
});

api.interceptors.response.use(
  (response) => {
    updateLoaderTo(false);
    return response;
  },
  (error) => {
    updateLoaderTo(false);
    var errormessage =
      error.response &&
      error.response.data.errors &&
      error.response.data.errors.Error
        ? error.response.data.errors.Error
        : error.message;
    if (error.response && error.response.status === 422) {
      errormessage = "";
      error.response.data.errors.forEach((value) => {
        errormessage += value.toString() + " ";
      });
    } else if (error.response && error.response.status === 403) {
      router.push("/project");
    } else if (error.response && error.response.status === 404) {
      router.push("/404");
    }

    store.dispatch(
      `alert/${ADD_ALERT}`,
      { message: errormessage, color: "error" },
      { root: true }
    );
    return Promise.reject(error);
  }
);

let updateLoaderTo = (loading) => {
  store.dispatch(
    `loader/${TOGGLE_LOADING}`,
    { loading: loading },
    { root: true }
  );
};

let tokenRefreshed = () => {
  callbacks = callbacks.filter((callback) => callback());
};

let addCallback = (callback) => {
  callbacks.push(callback);
};

export default api;
