<template>
    <v-tooltip right color="transparent">
        <span class="chathead-shape">{{ toolTipText }}</span>
        <template v-slot:activator="{ on, attrs }">
            <div class="text-center d-inline" v-bind="attrs">
                <v-chip v-on="on" class="ma-2" :color="chipColor" :pill="true"
                    x-small>
                    <span class="text-capitalize">{{ chipText }}</span>
                </v-chip>
            </div>
        </template>
    </v-tooltip>
</template>

<script>
export default {
    props: {
    toolTipText: {
      type: String,
      default: "",
    },
    chipText: {
      type: String,
      default: "",
    },
    chipColor: {
      type: String,
      default: "transparent",
    },
  },
};
</script>

<style scoped>
.chathead-shape {
  position: relative;
  display: inline-block;
  border: 1px solid #D1D5DB;
  border-radius: 6px;
  padding: 1.5rem;
  background-color: #FFFFFF;
  color: #000000;
  max-width: 260px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  box-shadow: 0 6px 30px #0000002a;
}

.chathead-shape:after {
  content: "";
  position: absolute;
  top: calc(45%); /* Center vertically and adjust */
  left: calc(0% - 20px); /* Adjust the position */
  border-width: 10px;
  border-style: solid;
  border-color: transparent #FFFFFF transparent transparent;
}

.chathead-shape:before {
  content: "";
  position: absolute;
  top: calc(45% - 2px); /* Center vertically and adjust */
  left: calc(0% - 22px); /* Adjust the position */
  border-width: 12px;
  border-style: solid;
  border-color: transparent #D1D5DB transparent transparent;
}
</style>