<template>
  <div>
    <v-card class="elevation-0">
      <v-card-title class="font-weight-bold"> {{ $vuetify.lang.t("$vuetify.details_step_txt") }} </v-card-title>
      <v-divider></v-divider>

      <Loader v-if="isLoading" class="mr-2 mt-6" />
      <v-list v-else>
        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.email_label_txt") }}:
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <div class="body-1">
              {{ selectedMember.email }}
              <v-icon v-if="selectedMember.email_verified" small color="green">
                mdi-check-decagram
              </v-icon>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.first_name_label_txt") }}:
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedMember.first_name ? selectedMember.first_name : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.last_name_label_txt") }}:
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedMember.last_name ? selectedMember.last_name : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.member.role") }}:
          </v-list-item-content>
          <v-list-item-content class="align-end text-uppercase">
            <v-row>
              <v-col class="py-0">
                <v-chip color="primary" outlined>
                  {{ selectedMember.role }}
                </v-chip>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.status_header_txt") }}:
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <v-row>
              <v-col class="py-0">
                <v-chip
                  outlined
                  class="text-capitalize"
                  :color="getColorOfStatus(selectedMember.status)"
                >
                  {{ selectedMember.status=='created' ? "Invited" : selectedMember.status }}
                </v-chip>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.profile_verify_tab") }}:
          </v-list-item-content>
          <v-list-item-content class="align-end">
            <v-row>
              <v-col class="py-0">
                <v-chip
                  outlined
                  class="text-capitalize"
                  :color="getColorOfStatus(selectedMember.id_status)"
                >
                  {{selectedMember.id_status}}
                </v-chip>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.gender_label_txt") }}:
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedMember.gender ? selectedMember.gender : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.phone_no_label_txt") }}:
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedMember.phone ? selectedMember.phone : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.address_label_txt") }}:
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedMember.address ? selectedMember.address : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.created_at_txt") }}:
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedMember.created_at ? formatDateTime(selectedMember.created_at) : "N/A" }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content class="font-weight-bold">
            {{ $vuetify.lang.t("$vuetify.last_update_txt") }}:
          </v-list-item-content>
          <v-list-item-content class="align-end">
            {{ selectedMember.updated_at ? formatDateTime(selectedMember.updated_at) : "N/A" }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { LOAD_MEMBER_BY_ID, REFRESHTOKEN } from "@/store/_actiontypes";
import Loader from "@/components/common/loader/Loader";

import {
  capitalizeFirstLetter,
  formatDateTime,
  timeToNextToken,
} from "@/helpers/helper";

export default {
  props: {},
  components: {
    Loader
  },
  data: () => ({
    timeToNextToken,
    capitalizeFirstLetter,
    formatDateTime,
    isLoading: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      selectedMember: (state) => state.member.selectedMember,
    }),
    ...mapGetters("loader", ["loading"]),
  },
  mounted() {
    this.getSelectedMember();
  },
  created() {},
  methods: {
    ...mapActions("member", [LOAD_MEMBER_BY_ID]),
    ...mapActions("account", [REFRESHTOKEN]),

    getSelectedMember(){
      this.isLoading = true;
      if (this.timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.getSelectedMemberFunction();
          },
          (error) => error
        );
      } else {
        this.getSelectedMemberFunction();
      }
    },
    getSelectedMemberFunction() {
      this.LOAD_MEMBER_BY_ID({member_id: this.$route.params.member_id}).then(
        (response) => {
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    getColorOfStatus(status) {
      let color = "red";
      if(status==='verified' || status==='active'){
        color = "green";
      } else if(status==='pending' || status==='created'){
        color = "orange";
      }
      return color;
    },
  },
};
</script>
