import Api from "@/services/api";
import {GET_ITEMS_DETAILS_BY_IDS} from "@/store/_actiontypes";
import { updateLoaderTo } from "./account";

const state = {};

const actions = {
    [GET_ITEMS_DETAILS_BY_IDS]({ commit }, {project_id, ids }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            Api.post(`recommender/${project_id}/items`, { ids }).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    }
};

const mutations = {};

export const items = {
    namespaced: true,
    state,
    actions,
    mutations,
};
