<template>
        <v-skeleton-loader
            v-if="!profile"
            class="mr-2 mt-6"
            type="list-item-three-line"
        ></v-skeleton-loader>

        <v-form v-else ref="profile_form">

            <v-row>
                <v-col
                cols="12"
                md="12"
                xs="12"
                >
                <v-text-field
                    type="text"
                    v-model="profile.profileName"
                    :rules="[required($vuetify.lang.t('$vuetify.name_header_txt'))]"
                    :label='$vuetify.lang.t("$vuetify.name_header_txt")'
                    :placeholder='$vuetify.lang.t("$vuetify.gpt_profile.profileNamePlaceholder")'
                    outlined
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                xs="12"
                >
                <v-textarea
                    v-model="profile.intro"
                    :rules="[required($vuetify.lang.t('$vuetify.gpt_profile.introduction'))]"
                    :label='$vuetify.lang.t("$vuetify.gpt_profile.introduction")'
                    outlined
                    :placeholder='$vuetify.lang.t("$vuetify.gpt_profile.introductionPlaceholder")'
                    type="text"
                ></v-textarea>
                </v-col>
                <v-col
                cols="12"
                xs="12"
                >
                <v-textarea
                    v-model="profile.system"
                    :rules="[required($vuetify.lang.t('$vuetify.gpt_history_system'))]"
                    :label='$vuetify.lang.t("$vuetify.gpt_history_system")'
                    :placeholder='$vuetify.lang.t("$vuetify.gpt_profile.systemPlaceholder")'
                    required
                    outlined
                ></v-textarea>
                </v-col>
                <v-col
                cols="12"
                md="6"
                xs="12"
                >
                <v-select
                    v-model="profile.model" 
                    :items="profile.modelList" 
                    :label='$vuetify.lang.t("$vuetify.gpt_history_model")'
                    outlined 
                    required
                    :rules="[required($vuetify.lang.t('$vuetify.gpt_history_model'))]"
                    >
                </v-select>
                </v-col>
                <v-col
                cols="12"
                md="6"
                xs="12"
                >
                <v-text-field
                    v-model="profile.temperature"
                    :rules="temperatureRules"
                    :label='$vuetify.lang.t("$vuetify.gpt_history_temperature")'
                    outlined
                    type="number"
                ></v-text-field>
                </v-col>
                <v-col
            cols="12"
            md="6"
            >

            <v-text-field
                v-model="profile.top_p"
                :rules="topPRules"
                :label='$vuetify.lang.t("$vuetify.gpt_profile.top_p")'
                outlined
                type="number"
            ></v-text-field>
            </v-col>
            <v-col
            cols="12"
            md="6"
            >
            <v-text-field
                v-model="profile.frequencyPenalty"
                :rules="temperatureRules"
                :label='$vuetify.lang.t("$vuetify.gpt_history_frequency_penalty")'
                outlined
                type="number"
            ></v-text-field>

            </v-col>
            <v-col
            cols="12"
            md="6"
            >
            <v-text-field
                v-model="profile.presencePenalty"
                :rules="temperatureRules"
                :label='$vuetify.lang.t("$vuetify.gpt_history_presence_penalty")'
                outlined
                type="number"
            ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="6"
                >
                <v-select
                    clearable
                    small-chips
                    :label='$vuetify.lang.t("$vuetify.tooltip.stop")'
                    :items="['AI', 'Human']"
                    multiple
                    v-model="profile.stop"
                    outlined
                ></v-select>

                </v-col>
                <v-col
                cols="12"
                md="6"
                >

                <v-text-field
                    v-model="profile.searchMaxToken"
                    :rules="searchTokenRules"
                    :label='$vuetify.lang.t("$vuetify.gpt_history_search_max_token")'
                    outlined
                    type="number"
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="6"
                >
                <v-text-field
                    v-model="profile.completionToken"
                    :rules="completionTokenRules"
                    :label='$vuetify.lang.t("$vuetify.gpt_history_completion_tokens")'
                    outlined
                    type="number"
                ></v-text-field>
                </v-col>
                <p style="padding-left: 12px; margin-bottom: 0px !important;">{{ $vuetify.lang.t("$vuetify.gpt_profile.dataset_tags") }}:</p>
                <v-col cols="12" md="12">
                  <v-combobox
                    v-model="profile.datasetTags.includes"
                    :items="[]"
                    :label='$vuetify.lang.t("$vuetify.gpt_profile.includes")'
                    variant="solo"
                    chips
                    clearable
                    outlined
                    multiple
                    class="no-dropdown-icon"
                   >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                        v-bind="attrs"
                        :model-value="selected"
                        closable
                        @click="select"
                        @click:close="remove(item)"
                        >
                        <span>{{ item }}</span>&nbsp;
                        </v-chip>
                  </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="12">
              <v-combobox
                v-model="profile.datasetTags.excludes"
                :items="[]"
                :label='$vuetify.lang.t("$vuetify.gpt_profile.excludes")'
                variant="solo"
                chips
                clearable
                outlined
                multiple
                class="no-dropdown-icon"
                >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                        <v-chip
                        v-bind="attrs"
                        :model-value="selected"
                        closable
                        @click="select"
                        @click:close="remove(item)"
                        >
                        <span>{{ item }}</span>&nbsp;
                        </v-chip>
                  </template>
              </v-combobox>
            </v-col>


            </v-row>
            
            <p v-if="profile.model === VISION_MODELS.VISION_PREVIEW || profile.model === VISION_MODELS.VISION_4O">{{$vuetify.lang.t("$vuetify.gpt_history_vision_settings")}}:</p>
            
            <v-select
                v-if="profile.model === VISION_MODELS.VISION_PREVIEW || profile.model === VISION_MODELS.VISION_4O"
                v-model="profile.visionSettings.resolution" 
                :items="['low', 'high']" 
                :label='$vuetify.lang.t("$vuetify.gpt_history_resolution")'
                outlined 
                required
                :rules="[required($vuetify.lang.t('$vuetify.gpt_history_resolution'))]"
                >
            </v-select>

            <v-checkbox v-if="profile.model === VISION_MODELS.VISION_4O || profile.model === VISION_MODELS.VISION_PREVIEW" v-model="profile.visionSettings.imageContextEnabled" :label='$vuetify.lang.t("$vuetify.gpt_history_image_context_enabled")'></v-checkbox>
        </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {gptProfileInitialData} from "@/store/modules/project"
import { UPDATE_GPT_PROFILE_STATE} from "@/store/_actiontypes";
import { encoding_for_model } from 'tiktoken'
import {
    MODEL_CONTEXT_WINDOW,
    PROMPT_TEMPLATE_SEPARATORS_TOKEN_COUNT,
    VISION_MODELS
} from '@/global/_constants';
    export default {
    props: [ 'update'],
    computed: {
        ...mapState({
            profile: (state) => state.project.selectedGptProfile,
        }),
    },
    data() {
        return {
            temperatureRules: [
                value => value <= 2  || 'Max value is 2',
                value => value >= 0  || 'Min value 0',
            ],
            topPRules: [
                value => value <= 1  || 'Max value is 1',
                value => value >= 0  || 'Min value 0',
            ],
            completionTokenRules: [
                value => value <= 2500  || 'Max value is 2500',
                value => value >= 0  || 'Min value 0',
            ], 
            profileLoader : true,
            maxToken: 32000,
            localProfile: this.profile,
            searchTokenRules: [
                value => value <= this.maxToken  || `Max value is ${this.maxToken}`,
                value => value >= 0  || 'Min value 0',
            ], 
            VISION_MODELS: VISION_MODELS,
        }
    },

    watch: {
        profile: {
            deep: true,
            async handler(newVal, oldVal){
                setTimeout( async () => {
                    if(this.profile.model !== ""){
                        const searchMaxToken = await this.calculateMaxToken()
                        this.maxToken = searchMaxToken

                    }
                }, 1500);
                const localProfile = this.profile
                this.UPDATE_GPT_PROFILE_STATE({profile: localProfile})
            }
        },
            
    },
    mounted() {
    },


    methods: {
        ...mapActions("project", [UPDATE_GPT_PROFILE_STATE ]),

        required: (fieldName) => {
            return value => !!value || `${fieldName} is required`
        },
        validateForm() {
            return this.$refs.profile_form.validate();
        },

        async calculateMaxToken() {
            const tokenizer = await encoding_for_model("gpt-3.5-turbo");
            const introTokens = tokenizer.encode(this.profile.intro)
            const systemTokens = tokenizer.encode(this.profile.system)
            const completionToken = parseInt(this.profile.completionToken) == NaN ? 0 : parseInt(this.profile.completionToken)
            tokenizer.free()
            const searchMaxToken = Math.ceil(MODEL_CONTEXT_WINDOW[this.profile.model] - (introTokens.length + systemTokens.length + completionToken + PROMPT_TEMPLATE_SEPARATORS_TOKEN_COUNT +  ((MODEL_CONTEXT_WINDOW[this.profile.model] * 10) / 100 )))
            return searchMaxToken
        },
    }
    }
</script>


<style scoped>
    .datasets-tag-text {
        padding-bottom: "0px !important"
    }
    .no-dropdown-icon >>> .v-input__append-inner .v-icon {
        display: none !important;
    }
</style>