import Api from "@/services/api";
import {
    GET_TASK_STATUS
} from "@/store/_actiontypes";
import {

} from "@/store/_mutationtypes";
import { updateLoaderTo } from "./account";


const state = {

};

const actions = {
    [GET_TASK_STATUS]({ commit }, { project_id, task_id }) {
        updateLoaderTo(true);
        return new Promise((resolve, reject) => {
            Api.get(`${project_id}/gpt/task/${task_id}`).then(
                (response) => {
                    updateLoaderTo(false);
                    resolve(response);
                },
                (error) => {
                    updateLoaderTo(false);
                    reject(error);
                }
            );
        });
    },
};

const mutations = {

};

export const task = {
    namespaced: true,
    state,
    actions,
    mutations,
};
