<template>
    <v-stepper v-model="step">
        <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
                {{ `${$vuetify.lang.t("$vuetify.project.dataset_update")}` }}
            </v-stepper-step>

            <v-divider></v-divider>
            <v-stepper-step step="2">
                {{ `${$vuetify.lang.t("$vuetify.project.train")}` }}
            </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>

            <v-stepper-content step="1">
                <UploadDataset ref="dataset" propName="Test props" />
                <div class="d-flex justify-space-between">
                    <div>
                    <v-btn outlined color="error" class="mr-4" :loading="false" @click="cancelBtnClick">
                        {{$vuetify.lang.t("$vuetify.cancel_btn_txt")}}
                    </v-btn>
                </div>
                    <div>
                        <v-btn outlined color="primary" v-if="gptSetupState.dataset.completed" class="mr-4"
                            @click="skip(step)">
                            Skip Update >>
                        </v-btn>
                        <v-btn color="primary" class="mr-4" :loading="false" @click="nextStep(step)">
                            {{ $vuetify.lang.t("$vuetify.continue_btn_txt") }}
                        </v-btn>
                    </div>
                </div>
            </v-stepper-content>

            <v-stepper-content step="2">
                <div v-if="gptSetupState.dataset.error" style="min-height: 200px;"
                    class="d-flex justify-center align-center flex-column">
                    <div class="d-flex">
                        <v-icon :style="{ color: 'red' }">mdi-close-circle-outline</v-icon>
                        <v-list-item-title style="margin-left: 10px;">
                            {{ gptSetupState.dataset.message }}
                        </v-list-item-title>
                    </div>
                    <v-btn style="margin-top: 10px;" color="primary" class="mr-4" outlined :loading="false"
                        @click="datasetErrorTryAgain">
                            {{$vuetify.lang.t('$vuetify.common.try_again')}}
                    </v-btn>
                </div>
                <!-- training error -->
                <div v-else-if="gptSetupState.training.error" style="min-height: 200px;"
                    class="d-flex justify-center align-center flex-column">
                    <div class="d-flex">
                        <v-icon :style="{ color: 'red' }">mdi-close-circle-outline</v-icon>
                        <v-list-item-title style="margin-left: 10px;">
                            {{ gptSetupState.training.message }}
                        </v-list-item-title>
                    </div>
                    <v-btn style="margin-top: 10px;" color="primary" class="mr-4" outlined :loading="false"
                        @click="trainingErrorTryAgain">
                        {{$vuetify.lang.t('$vuetify.common.try_again')}}
                    </v-btn>
                </div>

                <div v-else-if="dataSetProcessCompleted">

                    <div v-if="trainingInProgress" style="min-height: 200px;"
                        class="d-flex justify-center align-center">

                        <v-col cols="6">
                            <v-list-item>

                                <v-list-item-icon>
                                    <v-progress-circular indeterminate color="primary">
                                    </v-progress-circular>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $vuetify.lang.t("$vuetify.training_in_progress") }}
                                    </v-list-item-title>
                                </v-list-item-content>

                            </v-list-item>
                        </v-col>
                    </div>
                    <v-form ref="train_form" v-else>
                        <div style="min-height: 200px;" class="d-flex justify-center align-center">
                            <v-col cols="12" md="6">
                                <v-select :label='$vuetify.lang.t("$vuetify.gpt_dataset.training_type")'
                                    :items="['text', 'image']" v-model="trainType" outlined
                                    :rules="[required($vuetify.lang.t('$vuetify.gpt_dataset.training_type'))]"></v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field v-model="batchSize"
                                    :label='$vuetify.lang.t("$vuetify.gpt_dataset.batch_size")' outlined type="number"
                                    :rules="batchSizeRules"></v-text-field>
                            </v-col>
                        </div>
                    </v-form>



                    <div v-if="!trainingInProgress" class="d-flex justify-space-between">
                        <v-btn color="primary" class="mr-4" outlined :loading="false" @click="previousStep(step)">
                            {{ $vuetify.lang.t("$vuetify.prev_btn_txt") }}
                        </v-btn>
                        <v-btn @click="handleTraining" color="primary" class="mr-4 pr-8 pl-8" :loading="false">
                            {{ $vuetify.lang.t("$vuetify.project.train") }}
                        </v-btn>
                    </div>
                </div>
                <div v-else>

                    <div style="min-height: 200px;" class="d-flex justify-center align-center">
                        <v-col cols="6">
                            <v-list-item>

                                <v-list-item-icon>
                                    <v-progress-circular indeterminate color="primary">
                                    </v-progress-circular>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <!-- <v-list-item-title> -->
                                    {{ $vuetify.lang.t("$vuetify.gpt_dataset.dataset_process_progress") }}
                                    <!-- </v-list-item-title> -->
                                </v-list-item-content>

                            </v-list-item>
                        </v-col>

                    </div>
                    <v-btn color="primary" class="mr-4" outlined :loading="false" @click="previousStep(step)">
                        {{ $vuetify.lang.t("$vuetify.prev_btn_txt") }}
                    </v-btn>
                </div>

            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import { mapState, mapActions } from "vuex";
import UploadDataset from "@/components/Projects/UploadDataset/UploadDataset";
import { ADD_ALERT, CREATE_GPT_DATASET, UPDATE_GPT_DATASET_API, TRAIN_GPT_DATASET, UPDATE_GPT_SETUP_STATE, UPDATE_TASKS_STATUS, GET_TASK_STATUS, UPDATE_GPT_DATASET_STATE, GET_GPT_DATASET_BY_ID } from "@/store/_actiontypes";
import { GptDatasetMapper } from "@/helpers/dataMappers/gptDataset"
import getEnv from "@/config/env";

export default {
    components: {
        UploadDataset,
    },
    data() {
        return {
            step: 1,
            trainingInProgress: false,
            trainType: "",
            batchSize: 1,
            dataSetProcessCompleted: false,
            intervalId: null,
            batchSizeRules: [
                value => value >= 1 || 'Min value is 1',
                value => value <= 10 || 'Max value is 10'
            ]

        }
    },
    computed: {
        ...mapState({
            gptDatasets: (state) => state.project.gptDatasets,
            selectedGptDataset: (state) => state.project.selectedGptDataset,
            datasetExist: (state) => state.project.gptSetupState.dataset.completed,
            gptSetupState: (state) => state.project.gptSetupState,
            selectedProject: (state) => state.project.selectedProject,
        }),

    },
    watch: {
        gptSetupState: {
            handler(setupState) {
                //for dataset task
                if (setupState.dataset?.task_id && !this.intervalId && !setupState.dataset?.error) {
                    this.startInterval(setupState.dataset?.task_id, "dataset")
                }
                //for training task
                if (setupState.training?.task_id && !this.intervalId && !setupState.training?.error) {
                    this.startInterval(setupState.training?.task_id, "train")
                    this.trainingInProgress = true
                }

                if (setupState.dataset?.completed) {
                    this.dataSetProcessCompleted = true
                }
                if (setupState.training?.completed) {
                    this.trainingInProgress = false
                    this.$router.push(`/projects/${this.$route.params.project_id}`)
                }
            },
            immediate: true

        }
    },
    mounted() {
        this.loadProfileAndDataset()
    },
    beforeDestroy() {
        this.stopInterval();
        this.resetGptDataset()
    },

    methods: {
        ...mapActions("project", [CREATE_GPT_DATASET, UPDATE_GPT_DATASET_API, TRAIN_GPT_DATASET, UPDATE_GPT_SETUP_STATE, UPDATE_TASKS_STATUS, GET_TASK_STATUS, UPDATE_GPT_DATASET_STATE, GET_GPT_DATASET_BY_ID]),
        stopInterval() {
            clearInterval(this.intervalId);
            this.intervalId = null
        },
        loadProfileAndDataset() {
            if (this.gptSetupState.dataset.error) {
                this.step = 2
            }
            this.GET_GPT_DATASET_BY_ID({
                    project_id: this.$route.params.project_id,
                    dataset_id: this.$route.params.dataset_id,
                })
                    .then((res) => {
                        console.log(res, "res")
                        if (res.data.response?.dataset?.documents_count?.total > 0) {
                            this.UPDATE_GPT_SETUP_STATE({ ...this.gptSetupState, dataset: { ...this.gptSetupState.dataset, completed: true, dataset_id:  this.$route.params.dataset_id} })
                            this.step = 1;
                        }
                    }, err => {
                        this.$store.dispatch(
                            `alert/${ADD_ALERT}`,
                            {
                                message: this.$vuetify.lang.t("$vuetify.gpt_dataset.dataset_fetch_error"),
                                color: "error",
                            },
                            { root: true }
                        );
                    })

        },
        resetGptDataset() {
            this.UPDATE_GPT_DATASET_STATE({
                dataset: GptDatasetMapper.mapBackendToFrontend(
                    {
                        dataset_id: "",
                        description: "",
                        idx_column_name: "",
                        image_url_column: "",
                        is_idx_fillup_if_empty: true,
                        name: "",
                        secondary_idx_column: "",
                    }
                )

            })
        },
        startInterval(task_id, type) {
            this.intervalId = setInterval(() => {
                if (type === "dataset") {
                    this.GET_TASK_STATUS({ project_id: this.selectedProject.id, task_id: task_id }).
                        then((res) => {
                            if (res.data.detail?.response?.status === "success") {
                                this.$store.dispatch(
                                    `alert/${ADD_ALERT}`,
                                    {
                                        message: "Setup Completed",
                                        color: "success",
                                    },
                                    { root: true }
                                );
                                this.stopInterval()
                                this.dataSetProcessCompleted = true
                                this.UPDATE_GPT_SETUP_STATE({ ...this.gptSetupState, dataset: { ...this.gptSetupState.dataset, completed: true, dataset_id: this.gptSetupState.dataset.dataset_id, task_id: null, error: false } })
                                this.UPDATE_TASKS_STATUS({
                                    project_id: this.selectedProject.id,
                                    tasks_status: { ...this.gptSetupState, dataset: { ...this.gptSetupState.dataset, completed: true, dataset_id: this.gptSetupState.dataset.dataset_id, task_id: null, error: false } },
                                }).then(
                                    (response) => {

                                    }
                                ).catch(
                                    (error) => {
                                        console.log(error.response);
                                    })

                            } else if (res.data?.detail?.response?.status !== "pending" && res.data?.detail?.response?.status !== "claimable") {
                                this.stopInterval()
                                this.UPDATE_GPT_SETUP_STATE(
                                    { ...this.gptSetupState, dataset: { ...this.gptSetupState.dataset, status: res.data.detail?.response.status, message: res.data.detail?.response?.message, error: true } }
                                )
                                this.UPDATE_TASKS_STATUS({
                                    project_id: this.selectedProject.id,
                                    tasks_status: { ...this.gptSetupState, dataset: { ...this.gptSetupState.dataset, status: res.data.detail?.response.status, message: res.data.detail?.response?.message, error: true } }
                                }).then(
                                    (response) => {

                                    }
                                ).catch(
                                    (error) => {
                                        console.log(error.response);
                                    })
                            } else {
                                this.UPDATE_GPT_SETUP_STATE(
                                    { ...this.gptSetupState, dataset: { ...this.gptSetupState.dataset, status: res.data.detail.response.status, error: false } }
                                )
                                this.UPDATE_TASKS_STATUS({
                                    project_id: this.selectedProject.id,
                                    tasks_status: { ...this.gptSetupState, dataset: { ...this.gptSetupState.dataset, status: res.data.detail.response.status, error: false } }
                                }).then(
                                    (response) => {

                                    }
                                ).catch(
                                    (error) => {
                                        console.log(error.response);
                                    })
                            }
                        }).catch(err => {
                            console.log("error", err)
                        })
                }
                if (type === "train") {
                    this.GET_TASK_STATUS({ project_id: this.selectedProject.id, task_id: task_id }).
                        then((res) => {
                            if (res.data.detail.response.status === "success") {
                                this.stopInterval()
                                this.trainingInProgress = false
                                this.UPDATE_GPT_SETUP_STATE({ ...this.gptSetupState, completed: true, training: { ...this.gptSetupState.training, completed: true, task_id: null, status: "success", error: false } })
                                this.UPDATE_TASKS_STATUS({
                                    project_id: this.selectedProject.id,
                                    tasks_status: { ...this.gptSetupState, completed: true, training: { ...this.gptSetupState.training, completed: true, task_id: null, status: "success", error: false } },
                                }).then(
                                    (response) => {

                                    }
                                ).catch(
                                    (error) => {
                                        console.log(error.response);
                                    }
                                )

                            } else if (res.data.detail.response.status !== "pending" && res.data?.detail?.response?.status !== "claimable") {
                                this.stopInterval()
                                this.trainingInProgress = false
                                this.UPDATE_GPT_SETUP_STATE(
                                    { ...this.gptSetupState, training: { ...this.gptSetupState.training, status: res.data.detail.response.status, message: res.data?.detail?.response?.message, error: true } }
                                )
                                this.UPDATE_TASKS_STATUS({
                                    project_id: this.selectedProject.id,
                                    tasks_status:
                                        { ...this.gptSetupState, training: { ...this.gptSetupState.training, status: res.data.detail.response.status, message: res.data.detail?.response?.message, error: true } }
                                }).then(
                                    (response) => {

                                    }
                                ).catch(
                                    (error) => {
                                        console.log(error.response);
                                    })
                                // updated training state
                            }
                            else {
                                this.UPDATE_GPT_SETUP_STATE(
                                    { ...this.gptSetupState, training: { ...this.gptSetupState.training, status: res.data.detail.response.status, error: false } }
                                )

                                this.UPDATE_TASKS_STATUS({
                                    project_id: this.selectedProject.id,
                                    tasks_status:
                                        { ...this.gptSetupState, training: { ...this.gptSetupState.training, status: res.data.detail.response.status, error: false } }
                                }).then(
                                    (response) => {

                                    }
                                ).catch(
                                    (error) => {
                                        console.log(error.response);
                                    })
                            }
                        }).catch(err => {
                            console.log("error", err)
                        })
                }

            }, getEnv("VUE_APP_POLLING_INTERVAL"));
        },
        trainingErrorTryAgain() {
            this.UPDATE_GPT_SETUP_STATE(
                { ...this.gptSetupState, training: { ...this.gptSetupState.training, task_id: null, status: null, message: null, error: false } }
            )
            this.UPDATE_TASKS_STATUS({
                project_id: this.selectedProject.id,
                tasks_status: { ...this.gptSetupState, training: { ...this.gptSetupState.training, task_id: null, status: null, message: null, error: false } }

            }).then(
                (response) => {

                }
            ).catch(
                (error) => {
                    console.log(error.response);
                })
        },
        datasetErrorTryAgain() {
            this.step = 1
            this.UPDATE_GPT_SETUP_STATE(
                { ...this.gptSetupState, dataset: { ...this.gptSetupState.dataset, task_id: null, status: null, message: null, error: false } }
            )
            this.UPDATE_TASKS_STATUS({
                project_id: this.selectedProject.id,
                tasks_status: { ...this.gptSetupState, dataset: { ...this.gptSetupState.dataset, task_id: null, status: null, message: null, error: false } }
            }).then(
                (response) => {

                }
            ).catch(
                (error) => {
                    console.log(error.response);
                })
        },

        previousStep(step) {
            this.step = step - 1;
            if (step === 1)
                this.step = 2
        },
        required: (fieldName) => {
            return value => !!value || `${fieldName} is required`
        },
        cancelBtnClick() {
            this.$router.push(`/projects/${this.$route.params.project_id}`)
        },
        skip(step) {
            this.step = step + 1
        },
        nextStep(step) {

            if (step === 1) {
                console.log("datasetExist", this.datasetExist)
                const valid = this.$refs.dataset.validateForm();
                if (!valid)
                    return

                    this.UPDATE_GPT_DATASET_API({ project_id: this.$route.params.project_id, dataset: this.selectedGptDataset }).then(
                        res => {
                            this.UPDATE_GPT_SETUP_STATE({ ...this.gptSetupState, dataset: { ...this.gptSetupState.dataset, task_id: res.data.detail?.task_id } })
                            this.UPDATE_TASKS_STATUS({
                                project_id: this.selectedProject.id,
                                tasks_status: { ...this.gptSetupState, dataset: { ...this.gptSetupState.dataset, task_id: res.data.detail?.task_id } },
                            }).then(
                                (response) => { }
                            ).catch(
                                (error) => {
                                    console.log(error.response);
                                })
                            this.$store.dispatch(
                                `alert/${ADD_ALERT}`,
                                {
                                    message: this.$vuetify.lang.t("$vuetify.gpt_dataset.dataset_update_success"),
                                    color: "success",
                                },
                                { root: true }
                            );
                        },
                        err => {
                            this.$store.dispatch(
                                `alert/${ADD_ALERT}`,
                                {
                                    message: this.$vuetify.lang.t("$vuetify.gpt_dataset.dataset_update_error"),
                                    color: "error",
                                },
                                { root: true }
                            );
                        }
                    ).finally(() => {
                        this.step = step + 1;
                    })

            }
            if (step === 3)
                this.step = 1
        },
        handleTraining() {
            const valid = this.validateForm();
            if (!valid)
                return
            this.TRAIN_GPT_DATASET({ project_id: this.$route.params.project_id, datasetId: this.selectedGptDataset.datasetId, trainType: this.trainType, batchSize: this.batchSize }).
                then(res => {
                    // this.startInterval(res.data?.detail?.response, "train")
                    this.UPDATE_GPT_SETUP_STATE({ ...this.gptSetupState, training: { ...this.gptSetupState.training, task_id: res.data?.detail?.task_id } })
                    this.UPDATE_TASKS_STATUS({
                        project_id: this.selectedProject.id,
                        tasks_status: { ...this.gptSetupState, training: { ...this.gptSetupState.training, task_id: res.data?.detail?.task_id } },
                    }).then(
                        (response) => { }
                    ).catch(
                        (error) => {
                            console.log(error.response);
                        })
                    //res.data.detail.response <-- contains the train task id
                    this.trainingInProgress = true
                }, err => {
                    console.log('train err', err.response)
                    this.$store.dispatch(
                        `alert/${ADD_ALERT}`,
                        {
                            message: err.response?.data?.detail?.response || "Dataset training error",
                            color: "error",
                        },
                        { root: true }
                    );
                })

        },
        validateForm() {
            return this.$refs.train_form.validate();
        }

    }
}
</script>


<style></style>