<template>
  <div>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="pa-0" xs="12" md="12">
          <v-card class="mx-auto px-2" outlined>
            <v-row>
              <v-col md="6">
                <div class="d-flex align-center">
                  <div class="primary--text font-weight-bold"
                 >
                  <v-btn text class="pa-0 ma-0" @click="redirectProjectPage">
                    <v-icon>
                      mdi-arrow-left
                    </v-icon>
                  </v-btn>
                   
                  </div>
                  <div class="primary--text font-weight-bold">
                    {{ $vuetify.lang.t("$vuetify.gpt_dataset.dataset_document_header_txt") }}
                  </div>
                </div>
              </v-col>
              <v-col md="6">
                <div class="d-flex float-right">
                  <v-btn class="text-capitalize" @click="createNewDocumentClick()" color="primary" dark>
                  <span class="hidden-sm-and-down">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    {{ $vuetify.lang.t("$vuetify.gpt_document.create_document") }}
                  </span>
                  <span class="hidden-md-and-up">
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </span>
                </v-btn>
                <v-btn class="text-capitalize ml-4" @click="handleCSVUpload()" color="primary" dark>
                  <span class="hidden-sm-and-down">
                    <v-icon left>mdi-cloud-upload-outline</v-icon>
                    {{ $vuetify.lang.t("$vuetify.gpt_document.upload_csv") }}
                  </span>
                  <span class="hidden-md-and-up">
                    <v-icon>mdi-plus-circle-outline</v-icon>
                  </span>
                </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" xs="12" md="4">
                <!-- <v-text-field v-model="search" append-icon="mdi-magnify"
                  :label="$vuetify.lang.t('$vuetify.search_btn_txt')" outlined dense single-line
                  hide-details></v-text-field> -->
              </v-col>
              <v-col cols="12" xs="12" md="3">
                <!-- <v-select :items="states" item-text="state" item-value="value" persistent-hint hide-details="auto"
                  outlined dense></v-select> -->
              </v-col>
              <v-col cols="12" xs="12" md="5" class="d-flex justify-end">
                <v-btn class="text-capitalize font-weight-bold " color="error"
                  @click="delete_alert_message = selectedDocuments.length ? $vuetify.lang.t('$vuetify.gpt_dataset.confirm_alert_msg_txt_3') : $vuetify.lang.t('$vuetify.gpt_dataset.confirm_alert_msg_txt_2'); deleteDialog = true;">
                  {{ $vuetify.lang.t("$vuetify.tooltip.delete") }} ({{ selectedDocuments.length || "ALL" }})
                  <v-icon right class="ml-2">delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <Loader v-if="isLoading" class="mr-2 mt-6" />
            <v-data-table v-else :headers="headers" :items="gptDocuments" :search="search" :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer show-select v-model="selectedDocuments">
              <template v-slot:[`item.id`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.id.split("-")[0] }}</span>
                  </template>
                  <span>{{ item.id }}</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.data.name`]="{ item }">
                <div>
                  <v-btn text class="primary--text text--darken-3" @click="detailsModal(item)">{{ item.data.name
                    }}</v-btn>
                </div>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ formatDateTime(item.created_at) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip color="primary" text-color="white" small class="text-capitalize">{{ item.status }}</v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <ButtonGroup class="d-flex justify-center" :groupType="ACTION_BUTTON_GROUP" :addButton="false"
                  :startButton="false" :stopButton="false" :disabledDeleteButton="disable_crud_operation"
                  :disabledEditButton="disable_crud_operation" @onDetailsClick="detailsModal(item)"
                  @onEditClick="updateItem(item)" @onDeleteClick="deleteItem(item)" />
              </template>
            </v-data-table>
            <v-row>
              <v-col class="py-0" cols="12" md="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3"></v-col>
              <v-col cols="12" xs="12" sm="6" md="6">
                <v-pagination class="text-center" v-model="page" :length="pageCount"></v-pagination>
              </v-col>
              <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                <v-select v-model="itemsPerPage" :items="rows" hide-details="auto" outlined dense></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <template v-if="detailsDialog">
      <v-dialog v-model="detailsDialog" max-width="800px" persistent scrollable hide-overlay
        transition="dialog-bottom-transition" content-class="rounded-lg" elevation="24" rounded>
        <v-card class="rounded-lg" elevation="24" rounded v-if="!detailsLoader">
          <v-card-title>
            {{ $vuetify.lang.t("$vuetify.tooltip.document") }} {{ $vuetify.lang.t("$vuetify.details_header_txt") }}
          </v-card-title>
          <v-card-text>
            <div>{{ $vuetify.lang.t("$vuetify.id_header_txt") }}: {{ document_details.id }}</div>
            <div>{{ $vuetify.lang.t("$vuetify.dashboard.active") }} {{ $vuetify.lang.t("$vuetify.status_header_txt") }}:
              <span class="text-capitalize">{{ document_details.active_status }}</span>
            </div>
            <div>{{ $vuetify.lang.t("$vuetify.created_at_txt") }}: {{ formatDateTime(document_details.created_at) }}
            </div>
            <div>{{ $vuetify.lang.t("$vuetify.status_header_txt") }}: <span class="text-capitalize">{{
                    document_details.status }}</span></div>
            <v-container>
              <v-row class="pa-0 ma-0">
                <v-col class="py-1 my-0"
                  cols="6"><strong>{{ $vuetify.lang.t("$vuetify.gpt_document.document_key") }}</strong></v-col>
                <v-col class="py-1 my-0"
                  cols="6"><strong>{{ $vuetify.lang.t("$vuetify.gpt_document.document_value") }}</strong></v-col>
              </v-row>
              <v-row class="pa-0 ma-0" v-for="(value, key) in document_details.data" :key="key">
                <v-col class="py-1 my-0" cols="6">{{ key }}</v-col>
                <v-col class="py-1 my-0" cols="6">{{ value }}</v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn text color="error" @click="detailsDialog = false">
              {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <Loader v-else />
      </v-dialog>
    </template>

    <template v-if="createNewDocumentDialog">
      <v-dialog v-model="createNewDocumentDialog" max-width="800px" persistent scrollable hide-overlay
        transition="dialog-bottom-transition" content-class="rounded-lg" elevation="24" rounded>
        <v-card class="rounded-lg" elevation="24" rounded>
          <v-card-title>
            {{
                    toBeUpdatedDocumentId !== '' ?
                      $vuetify.lang.t("$vuetify.gpt_document.update_document") :
                      $vuetify.lang.t("$vuetify.gpt_document.create_document")
                  }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" class="pa-0">
                <p class="text-center font-weight-bold pa-0 ma-0">
                  {{ $vuetify.lang.t("$vuetify.gpt_document.document_key") }}</p>
              </v-col>
              <v-col cols="12" md="8" class="pa-0">
                <p class="text-center font-weight-bold pa-0 ma-0">
                  {{ $vuetify.lang.t("$vuetify.gpt_document.document_value") }}</p>
              </v-col>
            </v-row>
            <hr class="my-2">
            <v-form ref="document_form">
              <v-row class="pa-0 ma-0" v-for="(newDocument, index) in newDocuments" :key="index">
                <v-col class="py-0 my-0" cols="12" md="4">
                  <v-text-field v-model="newDocument.key" outlined dense :rules="[
                    (v) => !newDocuments.some((doc, i) => doc.key === v && i !== index) || $vuetify.lang.t('$vuetify.common.duplicate_error_txt'),
                    (v) => !!v || $vuetify.lang.t('$vuetify.common.field_required_error_txt')
                  ]"></v-text-field>
                </v-col>
                <v-col class="py-0 my-0" cols="12" md="8">
                  <div class="d-flex">
                    <v-textarea v-model="newDocument.value" outlined dense rows="2" :rules="[
                    (v) => !!v || $vuetify.lang.t('$vuetify.common.field_required_error_txt')
                  ]"></v-textarea>
                    <v-btn icon @click="removeDocument(index)" v-if="newDocuments.length > 1">
                      <v-icon color="red">mdi-minus</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="pa-0">
              <v-col cols="12" class="d-flex justify-end">
                <v-btn outlined @click="addMoreData" color="primary">
                  {{ $vuetify.lang.t("$vuetify.project.add_more") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn text color="error" @click="createNewDocumentDialog = false">
              {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}
            </v-btn>

            <v-btn color="primary" @click="updateDocumentBtn" v-if="toBeUpdatedDocumentId !== ''">
              {{ $vuetify.lang.t("$vuetify.project.update") }}
            </v-btn>
            <v-btn color="primary" @click="createNewDocument" v-else>
              {{ $vuetify.lang.t("$vuetify.create_action_txt") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-if="uploadCSVDialog">
      <v-dialog v-model="uploadCSVDialog" max-width="800px" persistent scrollable hide-overlay
        transition="dialog-bottom-transition" content-class="rounded-lg" elevation="24" rounded>
        <v-card class="rounded-lg" elevation="24" rounded>
          <v-card-title class="ml-4">
            {{ $vuetify.lang.t("$vuetify.gpt_dataset.document_upload_header_txt") }}
          </v-card-title>
          <v-card-text>
            <v-form  ref="dataset_form">
              <v-col cols="12" md="12">
                <v-file-input
                  outlined
                  accept=".json, .csv, application/json, text/csv"
                  @change="handleFileChange"
                  :label="$vuetify.lang.t('$vuetify.gpt_dataset.dataset_file')"
                  :rules="fileInputRules"
                >
                </v-file-input>
              </v-col>
            </v-form>
            <v-row class="pa-0">
              <v-col cols="12" class="d-flex justify-end pr-6">
                <v-btn outlined @click="uploadDataset" color="primary">
                  {{ $vuetify.lang.t("$vuetify.upload_btn_txt") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-end pr-6">
            <v-btn text color="error" @click="uploadCSVDialog = false">
              {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-if="is_task_running">
      <v-dialog v-model="is_task_running" max-width="400px" persistent transition="dialog-bottom-transition"
        content-class="rounded-lg" elevation="24" rounded>
        <v-card class="rounded-lg" elevation="24" rounded>
          <v-card-title>
            <div>
              <span class="headline text-capitalize">
                {{ uploadingCSV ? documentUploadHeader:  $vuetify.lang.t("$vuetify.gpt_document.delete_document_progress_header_txt") }}
              </span>
            </div>
          </v-card-title>
          <v-card-text>
            <v-list-item>

              <v-list-item-icon>
                <v-progress-circular indeterminate color="primary">
                </v-progress-circular>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ uploadingCSV ? uploadCSVMessage : $vuetify.lang.t("$vuetify.gpt_document.delete_document_progress_txt") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn text color="error" @click="is_task_running = false">
              {{ $vuetify.lang.t("$vuetify.close_btn_txt") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>


    <DialogModal v-model="deleteDialog" :title="$vuetify.lang.t('$vuetify.confirm_alert_title_txt')"
      :message="delete_alert_message" :confirmButtonText="$vuetify.lang.t('$vuetify.confirm_btn_txt')"
      :cancelButtonText="$vuetify.lang.t('$vuetify.cancel_btn_txt')" @onConfirmation="deleteDocuments()"
      @onCancel="deleteDialog = false" />

    <DialogModal v-model="alertDialog" :title="$vuetify.lang.t('$vuetify.error_title_txt')" :message="alertMessage"
      :confirmButtonText="$vuetify.lang.t('$vuetify.ok_btn_txt')" :cancelButton="false"
      @onConfirmation="alertDialog = false" />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { ACTION_BUTTON_GROUP } from "@/components/common/buttongroup/_buttongrouptypes";
import validations from "@/helpers/validations";

import {
  CREATE_GPT_DOCUMENT,
  UPDATE_GPT_DOCUMENT,
  GET_GPT_DOCUMENTS,
  REFRESHTOKEN,
  TERMINATE_GPT_DOCUMENTS,
  GET_TASK_STATUS,
  UPDATE_GPT_DATASET_API
} from "@/store/_actiontypes";

import { TEXT } from "@/components/common/input/_inputTypes";

import { authorize, timeToNextToken } from "@/helpers/helper";
import { Role } from "@/helpers/role";

import ButtonGroup from "@/components/common/buttongroup/ButtonGroup";
import DialogModal from "@/components/common/DialogModal";
import Loader from "@/components/common/loader/Loader";
import getEnv from "@/config/env";


export default {
  props: {
    solution_type: {
      type: String
    },
  },
  components: {
    ButtonGroup,
    DialogModal,
    Loader,
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
    ...mapGetters("loader", ["loading"]),
    fileInputRules() {
        let rules = [];
          rules.push(
            (value) =>
              !!value ||
              this.$vuetify.lang.t("$vuetify.gpt_dataset.dataset_file") +
                " is required"
          );

        return rules;
    },
  },
  watch: {
    page() {
      this.getAllDocument();
    },
    itemsPerPage() {
      this.getAllDocument();
    },
  },
  mounted() {
    this.disableCrudOperation();
    this.getAllDocument();
  },
  data() {
    return {
      gptDocuments: [],
      selectedDocuments: [],
      toBeUpdatedDocumentId: "",
      document_details: {},
      newDocuments: [{ key: "", value: "" }],
      timeToNextToken,
      disable_crud_operation: false,
      isLoading: false,
      TEXT: TEXT,
      ACTION_BUTTON_GROUP: ACTION_BUTTON_GROUP,
      delete_alert_message: this.$vuetify.lang.t('$vuetify.gpt_dataset.confirm_alert_msg_txt_3'),
      createNewDocumentDialog: false,
      uploadCSVDialog: false,
      detailsDialog: false,
      detailsLoader: false,
      deleteDialog: false,
      alertDialog: false,
      uploadingCSV: false,
      documentUploadHeader: this.$vuetify.lang.t('$vuetify.gpt_dataset.document_upload_header_txt'),
      uploadCSVMessage: this.$vuetify.lang.t('$vuetify.gpt_dataset.dataset_uploading_progress'),
      datasetFile: null,
      alertMessage: this.$vuetify.lang.t('$vuetify.unknown_err_msg_txt'),
      ...validations,
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.id_header_txt"),
          align: "center",
          sortable: true,
          value: "id",
        },
        // {
        //   text: this.$vuetify.lang.t("$vuetify.name_header_txt"),
        //   align: "center",
        //   sortable: false,
        //   value: "data.name",
        // },
        {
          text: this.$vuetify.lang.t("$vuetify.created_at_txt"),
          align: "center",
          sortable: false,
          value: "created_at",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.status_header_txt"),
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.actions_header_txt"),
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      states: [
        // { state: "All", value: "" },
        // { state: "Pending", value: "pending" },
        // { state: "Running", value: "running" },
        // { state: "Rejected", value: "rejected" },
        // { state: "Stopped", value: "stopped" },
      ],
      is_task_running: false,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      rows: [5, 10, 20, 30, 40, 50],
    };
  },

  methods: {
    ...mapActions("document", [
      CREATE_GPT_DOCUMENT,
      UPDATE_GPT_DOCUMENT,
      GET_GPT_DOCUMENTS,
      TERMINATE_GPT_DOCUMENTS,
    ]),
    ...mapActions("project", [
      UPDATE_GPT_DATASET_API
    ]),
    ...mapActions("task", [GET_TASK_STATUS]),
    ...mapActions("account", [REFRESHTOKEN]),
    getAllDocument() {
      this.isLoading = true;
      if (this.timeToNextToken() < 300) {
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.getAllDocumentFunction();
          },
          (error) => error
        );
      } else {
        this.getAllDocumentFunction();
      }
    },
  
    getAllDocumentFunction() {
      this.GET_GPT_DOCUMENTS({
        project_id: this.$route.params.project_id,
        dataset_id: this.$route.params.dataset_id,
        start: (this.page - 1) * this.itemsPerPage,
        size: this.itemsPerPage,
      }).then(
        (response) => {
          this.isLoading = false;
          this.gptDocuments = response.data?.response.documents;
          let total_hits = response.data?.response.total_hits || 0;
          let returned_hits = response.data?.response.returned_hits || 0;
          this.pageCount = Math.ceil(total_hits / this.itemsPerPage) || 1;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    redirectProjectPage() {
      this.$router.push(`/projects/${this.$route.params.project_id}`)
    },
    createNewDocumentClick() {
      this.newDocuments = [{ key: "", value: "" }];
      this.toBeUpdatedDocumentId = "";
      this.createNewDocumentDialog = true;
    },
    handleCSVUpload() {
      this.uploadCSVDialog = true;
    },

    addMoreData() {
      if (this.$refs.document_form.validate() === false) {
        return;
      }
      this.newDocuments.push({ key: "", value: "" });
    },
    removeDocument(index) {
      this.newDocuments.splice(index, 1);
      this.$refs.document_form.validate();
    },
    createNewDocument() {
      if (this.$refs.document_form.validate() === false) {
        return;
      }
      let data = {};
      this.newDocuments.forEach((document) => {
        data[document.key] = document.value;
      });
      this.CREATE_GPT_DOCUMENT({
        project_id: this.$route.params.project_id,
        dataset_id: this.$route.params.dataset_id,
        documents: data,
      }).then(
        (response) => {
          if (response.status === 200) {
            this.createNewDocumentDialog = false;
            this.getAllDocument();
          }
          else {
            this.alertMessage = response.data?.message;
            this.alertDialog = true;
          }
        },
        (error) => {
          this.alertMessage = error.message;
          this.createNewDocumentDialog = false;
          this.alertDialog = true;
        }
      );
    },
    updateDocumentBtn() {
      if (this.$refs.document_form.validate() === false) {
        return;
      }
      let data = {};
      this.newDocuments.forEach((document) => {
        data[document.key] = document.value;
      });
      this.UPDATE_GPT_DOCUMENT({
        project_id: this.$route.params.project_id,
        dataset_id: this.$route.params.dataset_id,
        document_id: this.toBeUpdatedDocumentId,
        data: data,
      }).then(
        (response) => {
          if (response.status === 200) {
            this.createNewDocumentDialog = false;
            this.getAllDocument();
          }
          else {
            this.alertMessage = response.data?.message;
            this.alertDialog = true;
          }
        },
        (error) => {
          this.alertMessage = error.message;
          this.createNewDocumentDialog = false;
          this.alertDialog = true;
        }
      );
    },
    handleFileChange(file) {
      this.datasetFile = file;
    
    },
    startInterval(task_id) {
      this.intervalId = setInterval(() => {
        this.GET_TASK_STATUS({
          project_id: this.$route.params.project_id,
          task_id: task_id,
        }).then(
          (response) => {
            if (response.data.detail.response.status === "success") {
              this.stopInterval();
              clearInterval(this.intervalId);
              this.getAllDocument();
            }
          },
          (error) => {
            this.stopInterval();
            clearInterval(this.intervalId);
            this.alertMessage = error.message;
            this.alertDialog = true;
          }
        );
      }, getEnv("VUE_APP_POLLING_INTERVAL"));
    },
    stopInterval() {
      this.is_task_running = false;
      this.selectedDocuments = [];
      clearInterval(this.intervalId);
    },
    deleteDocuments() {
      let documentIds = this.selectedDocuments.map((document) => document.id);
      this.TERMINATE_GPT_DOCUMENTS({
        project_id: this.$route.params.project_id,
        dataset_id: this.$route.params.dataset_id,
        documentIds
      }).then(
        (response) => {
          if (response.status === 202) {
            this.is_task_running = true;
            this.startInterval(response.data?.detail.task_id);
            this.deleteDialog = false;
          }
          else if (response.status === 200) {
            this.getAllDocument();
            this.deleteDialog = false;
          }
          else {
            this.alertMessage = response.data?.message;
            this.alertDialog = true;
          }

        },
        (error) => {
          this.alertMessage = error.message;
          this.deleteDialog = false;
          this.alertDialog = true;
        }
      );
    },
    uploadDataset(){
      this.UPDATE_GPT_DATASET_API({
                                    project_id: this.$route.params.project_id,
                                    dataset: {
                                      datasetFile: this.datasetFile,
                                      datasetId: this.$route.params.dataset_id,
                                    },
                                  }).then(res => {
                                    this.uploadingCSV = true
                                    this.uploadCSVDialog = false;
                                    this.startInterval(res.data.detail?.task_id)
                                    this.is_task_running = true;
                                  }).catch(err => {
                                    console.log("err", err)
                                  })
    },
    formatDateTime(datetime) {
      return new Date(datetime.toLocaleString().split(",")[0]).toLocaleString(
      );
    },
    detailsModal(item) {
      this.detailsDialog = true;
      this.document_details = item;
      // this.detailsLoader = true;      
    },
    updateItem(item) {
      this.createNewDocumentDialog = true;
      this.newDocuments = Object.keys(item.data).filter(key => key !== 'id').map(key => ({ key, value: item.data[key] }));
      this.toBeUpdatedDocumentId = item.id;

    },
    deleteItem(item) {
      this.selectedDocuments = [item];
      this.deleteDialog = true;
    },
    disableCrudOperation() {
      if (authorize([Role.USER])) {
        this.disable_crud_operation = true;
      }
    }
  },
};
</script>

<style></style>